import React, { useEffect, useState } from 'react'
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'
import { useLocation, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import FilterModal from './FilterModal';
import { useTranslation } from 'react-i18next';

const Accomodations = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [filterModal, setFilterModal] = useState(false);
    const [filtered, setFiltered] = useState({
        range: [1000, 50000],
        amenities: []
    })
    const [details, setDetails] = useState({
        service: '',
        location: '',
        start_date: '',
        end_date: '',
        guests: null
    });
    const location = useLocation();
    const nav = useNavigate();
    const [searched, setSearched] = useState([]);
    const [searched2, setSearched2] = useState([]);

    useEffect(() => {
        // console.log(location?.state);
        // if (Object.values(location?.state)?.every(el => el === null)) {
        //     console.log("first")
        //     getAllRecords();
        // } else {
        setDetails({
            service: location?.state?.service,
            location: location?.state?.location,
            start_date: location?.state?.start_date,
            end_date: location?.state?.end_date,
            guests: location?.state?.guests
        });
        getSearchResult();
        // }

    }, [location?.state]);

    const handleClear = () => {
        setFilterModal(false);
        setFiltered({
            range: [1000, 50000],
            amenities: []
        })
        setSearched(searched2);
    }

    const handleApply = () => {
        // console.log("asdasd");
        // console.log(searched2)
        if (searched2.length > 0) {
            let result = searched2.filter((elem) => {
                return elem?.price_per_hour >= filtered?.range[0] && elem?.price_per_hour <= filtered?.range[1] && filtered?.amenities.every(val => elem?.extra_comforts.includes(val.trim()))
            });
            // console.log(result);
            // console.log('hit')
            setSearched(result);
            setFilterModal(false);
        }
    }

    const getAllRecords = async () => {
        await getDocs(query(collection(db, 'post')
            , where('post_type', '==', 3)
        ))
            .then(async querysnapshot => {
                let arr = [];
                await querysnapshot.docs.map(async (doc, index) => {
                    arr.push({ id: doc.id, ...doc.data() })
                });
                await Promise.all(arr.map((elem, index) => {
                    getDocs(query(collection(db, 'customer_feedback'), where('post_id', '==', elem.id))).then(querysnapshot2 => {
                        // console.log('checks')
                        let total = querysnapshot2.docs.length;
                        let rate = 0;
                        querysnapshot2.docs.map(doc => {
                            rate += doc.data().rating;
                        });
                        arr = arr.map(elems => {
                            if (elems.id === elem.id) {
                                return { ...elems, reviews: total, rating: isNaN((rate / total).toFixed(1)) ? 0.0 : (rate / total).toFixed(1) }
                            } else {
                                return elems
                            }
                        })
                        if (index === arr.length - 1) {
                            // console.log(arr);
                            setSearched(arr);
                            setLoading(false);
                        }
                    });
                }));
            });
    }

    const getSearchResult = async () => {
        // console.log("Asd")
        let result = {
            service: 3,
            location: location?.state?.location,
            guests: location?.state?.guests,
            dates: {
                start_date: location?.state?.start_date,
                end_date: location?.state?.end_date
            }

        };
        // await getDocs(query(collection(db, 'post'), where('post_type', '==', 3))).then(async querysnapshot => {
        //     let arr = [];
        //     console.log(querysnapshot?.docs?.length)
        //     await querysnapshot.docs.map(async (doc, index) => {
        //         let time = new Date().getTime();
        //         await Promise.all(Object.keys(result)?.map((key) => {
        //             let count = 0;
        //             let timepstamp = {
        //                 start: new Date(result?.dates?.start_date).getTime(),
        //                 end: new Date(result?.dates?.end_date).getTime()
        //             }
        //             if (key === "location" && result?.location && doc.data()?.location.toLowerCase().includes(result?.location.toLowerCase())) {
        //                 count += 1;
        //             }
        //             if (key === "dates" && result?.dates?.start_date && result?.dates?.end_date && doc.data().availability_start_date?.seconds <= (timepstamp.start / 1000) &&
        //                 doc.data().availability_end_date?.seconds >= (timepstamp.end / 1000)) {
        //                 count += 1;
        //             }

        //             if (key === "guests" && result?.guests) {
        //                 if (parseInt(result.service) === 1 && doc.data()?.no_of_personas >= result.guests) {
        //                     count += 1;
        //                 }
        //                 if (parseInt(result.service) === 2 && parseInt(doc.data()?.seating_capacity?.split(" ")[0]) >= parseInt(result.guests)) {
        //                     count += 1;
        //                 }
        //                 if (parseInt(result.service) === 3 && parseInt(doc.data()?.capacity) >= parseInt(result.guests)) {
        //                     count += 1;
        //                 }
        //             }
        //             if (key === "service" && result?.service && parseInt(result.service) === doc.data()?.post_type) {
        //                 count += 1;
        //             }
        //             // console.log(count, doc.data().title)
        //             if (count > 0 && !arr.find(e => e.id === doc.id)) {
        //                 arr.push({ id: doc.id, ...doc.data() })
        //             }
        //         }))
        //         // let timepstamp = {
        //         //     start: new Date(result?.start_date).getTime(),
        //         //     end: new Date(result?.end_date).getTime()
        //         // }
        //         // if (doc.data()?.location.toLowerCase().includes(result.location.toLowerCase())
        //         //     && doc.data().availability_start_date?.seconds <= (timepstamp.start / 1000) &&
        //         //     doc.data().availability_end_date?.seconds >= (timepstamp.end / 1000)
        //         // ) {

        //         //     if (parseInt(result.service) === 1 && doc.data()?.no_of_personas >= result.guests) {
        //         //         arr.push({ id: doc.id, ...doc.data() })
        //         //     }
        //         //     if (parseInt(result.service) === 2 && parseInt(doc.data()?.seating_capacity?.split(" ")[0]) >= parseInt(result.guests)) {
        //         //         arr.push({ id: doc.id, ...doc.data() })
        //         //     }
        //         //     if (parseInt(result.service) === 3 && parseInt(doc.data()?.capacity) >= parseInt(result.guests)) {
        //         //         arr.push({ id: doc.id, ...doc.data() })
        //         //     }
        //         // }
        //         if (index === querysnapshot.docs.length - 1 && arr.length == 0) {
        //             setLoading(false)
        //         }
        //     });
        //     await Promise.all(arr.map((elem, index) => {
        //         getDocs(query(collection(db, 'customer_feedback'), where('post_id', '==', elem.id))).then(querysnapshot2 => {
        //             // console.log('checks')
        //             let total = querysnapshot2.docs.length;
        //             let rate = 0;
        //             querysnapshot2.docs.map(doc => {
        //                 rate += doc.data().rating;
        //             });
        //             arr = arr.map(elems => {
        //                 if (elems.id === elem.id) {
        //                     return { ...elems, reviews: total, rating: isNaN((rate / total).toFixed(1)) ? 0.0 : (rate / total).toFixed(1) }
        //                 } else {
        //                     return elems
        //                 }
        //             })
        //             if (index === arr.length - 1) {
        //                 console.log(arr);
        //                 setSearched(arr);
        //                 setLoading(false);
        //             }
        //         });
        //     }));
        // })
        await getDocs(query(collection(db, 'post'), where('post_type', '==', 3))).then(async (querysnapshot) => {
            let arr = [];
            if (querysnapshot.docs.length > 0) {
                await Promise.all(querysnapshot.docs.map(async (doc, index) => {
                    let time = new Date().getTime();
                    await Promise.all(Object.keys(result)?.map((key) => {
                        let count = 0;
                        let timepstamp = {
                            start: new Date(result?.dates?.start_date).getTime(),
                            end: new Date(result?.dates?.end_date).getTime()
                        }
                        if (key === "location") {
                            if(doc.data()?.city)
                            {
                                result?.location && doc.data()?.city.toLowerCase().includes(result?.location.toLowerCase()) ? count += 1 : '';
                            }
                            else{
                                result?.location && doc.data()?.location.toLowerCase().includes(result?.location.toLowerCase()) ? count += 1 : '';
                            }
                        }
                        if (key === "dates" && result?.dates?.start_date && result?.dates?.end_date &&
                            // && doc.data().availability_start_date?.seconds <= (timepstamp.start / 1000) &&
                            //     doc.data().availability_end_date?.seconds >= (timepstamp.end / 1000)
                            areDatesBetween(result?.dates?.start_date, result?.dates?.end_date, moment(doc.data().availability_start_date?.seconds * 1000).format('YYYY-MM-DD'), moment(doc.data().availability_end_date?.seconds * 1000).format('YYYY-MM-DD'))
                        ) {
                            count += 1;
                        }

                        if (key === "guests" && result?.guests) {
                            if (parseInt(result.service) === 1 && doc.data()?.no_of_personas >= result.guests) {
                                count += 1;
                            }
                            if (parseInt(result.service) === 2 && parseInt(doc.data()?.seating_capacity?.split(" ")[0]) >= parseInt(result.guests)) {
                                count += 1;
                            }
                            if (parseInt(result.service) === 3 && parseInt(doc.data()?.capacity) >= parseInt(result.guests)) {
                                count += 1;
                            }
                        }
                        // if (key === "service" && result?.service && parseInt(result.service) === doc.data()?.post_type) {
                        //     count += 1;
                        // }
                        if ((count > 0 && !arr.find(e => e.id === doc.id) && Number(doc.data().availability_end_date?.seconds * 1000) >= time) ||
                            (count === 0 && !arr.find(e => e.id === doc.id) && !result?.location && result?.guests === null && !result?.dates?.start_date && !result?.dates?.end_date && Number(doc.data().availability_end_date?.seconds * 1000) >= time)) {
                            arr.push({ id: doc.id, ...doc.data() })
                        }
                    }))
                    // let timepstamp = {
                    //     start: new Date(result?.start_date).getTime(),
                    //     end: new Date(result?.end_date).getTime()
                    // }
                    // if (doc.data()?.location.toLowerCase().includes(result.location.toLowerCase())
                    //     && doc.data().availability_start_date?.seconds <= (timepstamp.start / 1000) &&
                    //     doc.data().availability_end_date?.seconds >= (timepstamp.end / 1000)
                    // ) {

                    //     if (parseInt(result.service) === 1 && doc.data()?.no_of_personas >= result.guests) {
                    //         arr.push({ id: doc.id, ...doc.data() })
                    //     }
                    //     if (parseInt(result.service) === 2 && parseInt(doc.data()?.seating_capacity?.split(" ")[0]) >= parseInt(result.guests)) {
                    //         arr.push({ id: doc.id, ...doc.data() })
                    //     }
                    //     if (parseInt(result.service) === 3 && parseInt(doc.data()?.capacity) >= parseInt(result.guests)) {
                    //         arr.push({ id: doc.id, ...doc.data() })
                    //     }
                    // }
                    if (index === querysnapshot.docs.length - 1 && arr.length == 0) {
                        setLoading(false)
                        setSearched([])
                    }
                }));
            } else {
                setLoading(false)
                setSearched([])
                setSearched2([])
            }
            await Promise.all(arr.map((elem, index) => {
                getDocs(query(collection(db, 'customer_feedback'), where('post_id', '==', elem.id))).then(querysnapshot2 => {
                    // console.log('checks')
                    let total = querysnapshot2.docs.length;
                    let rate = 0;
                    querysnapshot2.docs.map(doc => {
                        rate += doc.data().rating;
                    });
                    arr = arr.map(elems => {
                        if (elems.id === elem.id) {
                            return { ...elems, reviews: total, rating: isNaN((rate / total).toFixed(1)) ? 0.0 : (rate / total).toFixed(1) }
                        } else {
                            return elems
                        }
                    })
                    if (index === arr.length - 1) {
                        // console.log(arr);
                        setSearched(arr);
                        setSearched2(arr);
                        setLoading(false);
                        // setPosts(arr);
                        // setDisplayPosts(arr);
                    }
                });
            }));
        })
    }

    const handleChange = (e) => {
        setDetails({
            ...details,
            [e.target.name]: e.target.value?.trim()?.length > 0 ? e.target.value : ""
        })
    };

    const validate = () => {
        let err = {};
        // for (let key in details) {
        //     if (!details[key] && key !== 'start_date' && key !== 'end_date') {
        //         err[key + '_err'] = 'Please enter ' + key.replace(/_/g, " ");
        //         toast.error('Please enter ' + key.replace(/_/g, " "), { style: { background: '#333', color: '#fff' } })
        //     }
        // }
        if (Object.values(details).every(val => val === '')) {
            err['details_err'] = 'Please enter atleast one field';
            toast.error('Please enter atleast one field', { style: { background: '#333', color: '#fff' } })
        }
        if (details.start_date) {
            if (!details.end_date) {
                err['end_date_err'] = 'Please enter end date';
                toast.error('Please enter end date', { style: { background: '#333', color: '#fff' } })
            }
        }
        if (details.end_date) {
            if (!details.start_date) {
                err['start_date_err'] = 'Please enter start date';
                toast.error('Please enter start date', { style: { background: '#333', color: '#fff' } })
            }
        }
        // console.log(err);
        return (Object.keys(err).length > 0) ? false : true;
    }

    function areDatesBetween(date1, date2, targetStart, targetEnd) {
        // Convert all dates to milliseconds since epoch for comparison
        // console.log(date1, date2, targetStart, targetEnd)
        const date1Ms = new Date(date1).getTime();
        const date2Ms = new Date(date2).getTime();
        const targetStartMs = new Date(targetStart).getTime();
        const targetEndMs = new Date(targetEnd).getTime();

        // Check if both dates are between startDate (inclusive) and endDate (exclusive)
        return (date1Ms >= targetStartMs && date1Ms <= targetEndMs) &&
            (date2Ms >= targetStartMs && date2Ms <= targetEndMs);
    }
    function areTimestampsBetweenDates(timestampStart, timestampEnd, dateFrom, dateTo) {
        // Convert timestamps and dates to Date objects
        timestampStart = new Date(timestampStart).toLocaleDateString();
        timestampEnd = new Date(timestampEnd).toLocaleDateString();
        dateFrom = new Date(dateFrom).toLocaleDateString();
        dateTo = new Date(dateTo).toLocaleDateString();
        // console.log("timestampStart "+ timestampStart);
        // console.log("timestampEnd "+ timestampEnd);
        // console.log("dateFrom "+ dateFrom);
        // console.log("dateTo "+ dateTo);
        // console.log("===============================")
        // console.log(timestampStart <= dateFrom  && timestampEnd <= dateTo)

        // Check if both timestamps are between dateFrom and dateTo
        return (timestampStart <= dateFrom && timestampEnd >= dateTo);
        //return (timestampStart <= dateFrom && timestampStart <= dateTo) && (timestampEnd >= dateFrom && timestampEnd <= dateTo);
    }

    const handleSearch = async (e) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true);
            let result = {
                service: details?.service,
                location: details?.location,
                guests: details?.guests,
                dates: {
                    start_date: details?.start_date,
                    end_date: details?.end_date
                }

            };
            await getDocs(query(collection(db, 'post'), where('post_type', '==', 3))).then(async (querysnapshot) => {
                let arr = [];
                await Promise.all(querysnapshot.docs.map(async (doc, index) => {
                    let time = new Date().getTime();
                    await Promise.all(Object.keys(result)?.map((key) => {
                        let count = 0;
                        let timepstamp = {
                            start: new Date(`${result?.dates?.start_date}T00:00:00`).getTime(),
                            end: new Date(`${result?.dates?.end_date}T00:00:00`).getTime()
                        }
                        if (key === "location" && result?.location && doc.data()?.location.toLowerCase().includes(result?.location.toLowerCase())) {
                            count += 1;
                        }
                        if (key === "dates" && result?.dates?.start_date && result?.dates?.end_date &&
                            // doc.data().availability_start_date?.seconds <= (timepstamp.start / 1000) &&
                            //     doc.data().availability_end_date?.seconds >= (timepstamp.end / 1000)
                            // areTimestampsBetweenDates(doc.data().availability_start_date?.seconds * 1000, doc.data().availability_end_date?.seconds * 1000, result?.dates?.start_date, result?.dates?.end_date)
                            areDatesBetween(result?.dates?.start_date, result?.dates?.end_date, moment(doc.data().availability_start_date?.seconds * 1000).format('YYYY-MM-DD'), moment(doc.data().availability_end_date?.seconds * 1000).format('YYYY-MM-DD'))
                        ) {
                            count += 1;
                        }

                        if (key === "guests" && result?.guests) {
                            if (parseInt(result.service) === 1 && doc.data()?.no_of_personas >= result.guests) {
                                count += 1;
                            }
                            if (parseInt(result.service) === 2 && parseInt(doc.data()?.seating_capacity?.split(" ")[0]) >= parseInt(result.guests)) {
                                count += 1;
                            }
                            if (parseInt(result.service) === 3 && parseInt(doc.data()?.capacity) >= parseInt(result.guests)) {
                                count += 1;
                            }
                        }
                        // if (key === "service" && result?.service && parseInt(result.service) === doc.data()?.post_type) {
                        //     count += 1;
                        // }
                        // console.log(count)
                        if ((count > 0 && !arr.find(e => e.id === doc.id) && Number(doc.data().availability_end_date?.seconds * 1000) >= time) ||
                            (count === 0 && !arr.find(e => e.id === doc.id) && !result?.location && result?.guests === null && !result?.dates?.start_date && !result?.dates?.end_date && Number(doc.data().availability_end_date?.seconds * 1000) >= time)) {
                            arr.push({ id: doc.id, ...doc.data() })
                        }
                    }))
                    // let timepstamp = {
                    //     start: new Date(result?.start_date).getTime(),
                    //     end: new Date(result?.end_date).getTime()
                    // }
                    // if (doc.data()?.location.toLowerCase().includes(result.location.toLowerCase())
                    //     && doc.data().availability_start_date?.seconds <= (timepstamp.start / 1000) &&
                    //     doc.data().availability_end_date?.seconds >= (timepstamp.end / 1000)
                    // ) {

                    //     if (parseInt(result.service) === 1 && doc.data()?.no_of_personas >= result.guests) {
                    //         arr.push({ id: doc.id, ...doc.data() })
                    //     }
                    //     if (parseInt(result.service) === 2 && parseInt(doc.data()?.seating_capacity?.split(" ")[0]) >= parseInt(result.guests)) {
                    //         arr.push({ id: doc.id, ...doc.data() })
                    //     }
                    //     if (parseInt(result.service) === 3 && parseInt(doc.data()?.capacity) >= parseInt(result.guests)) {
                    //         arr.push({ id: doc.id, ...doc.data() })
                    //     }
                    // }
                    if (index === querysnapshot.docs.length - 1 && arr.length == 0) {
                        setLoading(false)
                        setSearched([])
                        setSearched2([])
                        setFiltered({
                            range: [1000, 50000],
                            amenities: []
                        })
                    }
                }));
                await Promise.all(arr.map((elem, index) => {
                    getDocs(query(collection(db, 'customer_feedback'), where('post_id', '==', elem.id))).then(querysnapshot2 => {
                        // console.log('checks')
                        let total = querysnapshot2.docs.length;
                        let rate = 0;
                        querysnapshot2.docs.map(doc => {
                            rate += doc.data().rating;
                        });
                        arr = arr.map(elems => {
                            if (elems.id === elem.id) {
                                return { ...elems, reviews: total, rating: isNaN((rate / total).toFixed(1)) ? 0.0 : (rate / total).toFixed(1) }
                            } else {
                                return elems
                            }
                        })
                        if (index === arr.length - 1) {
                            // console.log(arr);
                            setSearched(arr);
                            setSearched2(arr)
                            setFiltered({
                                range: [1000, 50000],
                                amenities: []
                            })
                            setLoading(false);
                            // setPosts(arr);
                            // setDisplayPosts(arr);
                        }
                    });
                }));
            })
        }
    };

    const handleSave = async (elem) => {
        // console.log(elem?.id);
        let arr = elem?.saved_by ?? [];
        if (arr.includes(localStorage.getItem("WEE_USER_ID"))) {
            arr = arr.filter((a) => a !== localStorage.getItem("WEE_USER_ID"))
        } else {
            arr.push(localStorage.getItem("WEE_USER_ID"))
        };
        let result = await Promise.all(searched?.map((el) => {
            if (el?.id === elem?.id) {
                return {
                    ...el,
                    saved_by: arr
                }
            } else {
                return el;
            }
        }))
        setSearched(result);
        await updateDoc(doc(db, 'post', elem?.id), {
            saved_by: arr,
        }).then(() => {
            toast.success(!arr?.includes(localStorage.getItem("WEE_USER_ID")) ? `Removed from Saved` : `${elem?.title} Saved Successfully`, { style: { background: '#333', color: '#fff' } });
        });
    }

    const selectPost = (elem) => {
        let result = {
            ...elem,
            // ...(({ start_date, end_date, ...obj }) => obj)(details),
            start_date: moment(new Date(elem?.availability_start_date?.seconds * 1000)).format('YYYY-MM-DD'),
            end_date: moment(new Date(elem?.availability_end_date?.seconds * 1000)).format('YYYY-MM-DD'),
            // start_date: details?.start_date ? details?.start_date : moment(new Date(elem?.availability_start_date?.seconds * 1000)).format('YYYY-MM-DD'),
            // end_date: details?.end_date ? details?.end_date : moment(new Date(elem?.availability_end_date?.seconds * 1000)).format('YYYY-MM-DD'),
        };
        nav('/accomodation-detail', { state: result });
        window.scrollTo(0, 0);
    }
    return (
        <div className='wt-site'>
            <FilterModal
                activeModal={filterModal}
                setActiveModal={() => setFilterModal(false)}
                filtered={filtered}
                handleApply={handleApply}
                setFiltered={(val) => setFiltered(val)}
                handleClear={handleClear}
            />
            <Header />
            <Toaster />
            <main className="wt-site-content">
                { /* Landing Section Start */}
                <section className="wt-landing-section wt-accommodation-section mt-0">
                    <div className="container">
                        <div className="wt-landing-search-box">
                            <form >
                                <div className="wt-landing-search-box__inner">
                                    <div className="wt-landing-search-item">
                                        <label htmlFor="inputBuscando" className="form-label">{t('search')}</label>
                                        <select id="inputBuscando" className="form-select" name='service' onChange={handleChange} value={details.service}>
                                            <option value="" disabled>-select a service-</option>
                                            {/* <option value="1">Apartment</option>
                                            <option value="2">Car</option> */}
                                            <option value="3">Yacht</option>
                                        </select>
                                    </div>
                                    <div className="wt-landing-search-item">
                                        <label htmlFor="inputUbicaci\xF3n" className="form-label">{t('location')}</label>
                                        <div className="wt-input-icon-group">
                                            <input type="text" className="form-control" id="inputUbicaci\xF3n" name='location' placeholder="Choose a location" onChange={handleChange} value={details.location} />
                                            <span className="wt-input-icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 2.5C7.313 2.5 3.5 6.313 3.5 11C3.5 15.983 8.129 19.041 11.192 21.064L11.722 21.416C11.806 21.472 11.903 21.5 11.999 21.5C12.095 21.5 12.192 21.472 12.276 21.416L12.806 21.064C15.869 19.041 20.498 15.983 20.498 11C20.5 6.313 16.687 2.5 12 2.5ZM12 13.5C10.619 13.5 9.5 12.381 9.5 11C9.5 9.619 10.619 8.5 12 8.5C13.381 8.5 14.5 9.619 14.5 11C14.5 12.381 13.381 13.5 12 13.5Z" fill="#1AAA69" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="wt-landing-search-item">
                                        <label htmlFor="inputDe" className="form-label">{t('of')}</label>
                                        <div className="wt-input-icon-group">
                                            <input className="form-control" id="inputDe" placeholder="09th March,2023" name='start_date' type="date" min={new Date().toISOString().split('T')[0]} max={details?.end_date} onChange={handleChange} value={details.start_date} />
                                            <span className="wt-input-icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21 10.05V18C21 20 20 21 18 21H6C4 21 3 20 3 18V10.05C3 9.88405 3.13405 9.75 3.30005 9.75H20.7C20.866 9.75 21 9.88405 21 10.05ZM18 4.5H16.75V3C16.75 2.59 16.41 2.25 16 2.25C15.59 2.25 15.25 2.59 15.25 3V4.5H8.75V3C8.75 2.59 8.41 2.25 8 2.25C7.59 2.25 7.25 2.59 7.25 3V4.5H6C4 4.5 3 5.5 3 7.5V7.94995C3 8.11595 3.13405 8.25 3.30005 8.25H20.7C20.866 8.25 21 8.11595 21 7.94995V7.5C21 5.5 20 4.5 18 4.5Z" fill="#1AAA69" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="wt-landing-search-item">
                                        <label htmlFor="inputDonde" className="form-label">{t('where')}</label>
                                        <div className="wt-input-icon-group">
                                            <input className="form-control" id="inputDonde" placeholder="09th March,2023" name='end_date' min={details?.start_date ? details?.start_date : new Date().toISOString().split('T')[0]} type="date" onChange={handleChange} value={details.end_date} />
                                            <span className="wt-input-icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21 10.05V18C21 20 20 21 18 21H6C4 21 3 20 3 18V10.05C3 9.88405 3.13405 9.75 3.30005 9.75H20.7C20.866 9.75 21 9.88405 21 10.05ZM18 4.5H16.75V3C16.75 2.59 16.41 2.25 16 2.25C15.59 2.25 15.25 2.59 15.25 3V4.5H8.75V3C8.75 2.59 8.41 2.25 8 2.25C7.59 2.25 7.25 2.59 7.25 3V4.5H6C4 4.5 3 5.5 3 7.5V7.94995C3 8.11595 3.13405 8.25 3.30005 8.25H20.7C20.866 8.25 21 8.11595 21 7.94995V7.5C21 5.5 20 4.5 18 4.5Z" fill="#1AAA69" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="wt-landing-search-item">
                                        <label htmlFor="inputCuantos" className="form-label">{t('people')}</label>
                                        <div className="wt-input-icon-group">
                                            <input type="text" className="form-control" id="inputCuantos" placeholder="Enter a number" name='guests' onChange={handleChange} value={details.guests} />
                                            <span className="wt-input-icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18.75 18.35C18.75 20.382 17.619 21.5 15.567 21.5H8.43298C6.38098 21.5 5.25 20.381 5.25 18.35C5.25 16.062 6.54997 13.39 10.215 13.39H13.7841C17.4501 13.389 18.75 16.062 18.75 18.35ZM12.0081 11.512C13.9501 11.512 15.5291 9.93898 15.5291 8.00598C15.5291 6.07198 13.9491 4.5 12.0081 4.5C10.0671 4.5 8.48804 6.07298 8.48804 8.00598C8.48804 9.93898 10.0661 11.512 12.0081 11.512ZM18.79 8.93005H17.1801C17.0401 8.93005 16.92 9.03003 16.89 9.16003C16.67 10.11 16.17 10.96 15.48 11.61C15.31 11.77 15.41 12.05 15.64 12.11C17.41 12.53 18.64 13.57 19.37 14.89C19.42 15 19.52 15.0699 19.65 15.0699H20.0699C21.6399 15.0699 22.5 14.21 22.5 12.65C22.5 10.94 21.53 8.93005 18.79 8.93005ZM17.51 2.5C16.512 2.5 15.641 3.04696 15.184 3.85596C15.147 3.92196 15.149 3.99498 15.163 4.05798C15.178 4.12398 15.207 4.15906 15.269 4.21106C16.248 5.03606 16.9011 6.23103 17.0081 7.58203C17.0141 7.65803 17.0401 7.70503 17.0861 7.75403C17.1321 7.80303 17.201 7.83294 17.292 7.84094C17.363 7.84694 17.436 7.85095 17.51 7.85095C18.98 7.85095 20.1801 6.65102 20.1801 5.17102C20.1801 3.70002 18.98 2.5 17.51 2.5ZM1.5 12.65C1.5 14.21 2.36005 15.0699 3.93005 15.0699H4.34998C4.47998 15.0699 4.58 15 4.63 14.89C5.36 13.57 6.58999 12.53 8.35999 12.11C8.58999 12.05 8.69002 11.77 8.52002 11.61C7.83002 10.96 7.32999 10.11 7.10999 9.16003C7.07999 9.03003 6.95995 8.93005 6.81995 8.93005H5.20996C2.46996 8.93005 1.5 10.94 1.5 12.65ZM3.81995 5.17004C3.81995 6.65004 5.01999 7.84998 6.48999 7.84998C6.56399 7.84998 6.63701 7.84597 6.70801 7.83997C6.80001 7.83197 6.86794 7.80205 6.91394 7.75305C6.95994 7.70405 6.98594 7.65705 6.99194 7.58105C7.09894 6.23005 7.75196 5.03496 8.73096 4.20996C8.79196 4.15796 8.82204 4.12301 8.83704 4.05701C8.85104 3.99401 8.85304 3.92198 8.81604 3.85498C8.35804 3.04598 7.48799 2.49902 6.48999 2.49902C5.01999 2.50002 3.81995 3.70004 3.81995 5.17004Z" fill="#1AAA69" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="wt-landing-search-item d-block d-lg-none">
                                        <label className="form-label d-none d-md-block"> </label>
                                        <button type='button' className="btn btn-success w-100" onClick={handleSearch}>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M27.9335 0.0644836H0.0625V27.9355H27.9335V0.0644836Z" fill="#1AAA69" />
                                                <path d="M12.692 21.8391C17.7428 21.8391 21.8372 17.7447 21.8372 12.694C21.8372 7.64323 17.7428 3.5488 12.692 3.5488C7.6413 3.5488 3.54688 7.64323 3.54688 12.694C3.54688 17.7447 7.6413 21.8391 12.692 21.8391Z" fill="#1AAA69" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M19.1602 19.1609L24.4513 24.452" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <span>{t('search_text')}</span>
                                        </button>
                                    </div>
                                    <div className="wt-landing-search-btn d-none d-lg-block">
                                        <label className="form-label mb-0 d-block"> </label>
                                        <button type="button" className="btn btn-success" onClick={handleSearch}>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M27.9335 0.0644836H0.0625V27.9355H27.9335V0.0644836Z" fill="#1AAA69" />
                                                <path d="M12.692 21.8391C17.7428 21.8391 21.8372 17.7447 21.8372 12.694C21.8372 7.64323 17.7428 3.5488 12.692 3.5488C7.6413 3.5488 3.54688 7.64323 3.54688 12.694C3.54688 17.7447 7.6413 21.8391 12.692 21.8391Z" fill="#1AAA69" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M19.1602 19.1609L24.4513 24.452" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                { /* Landing Section End */}

                { /* Principales Section Start */}
                <section className="wt-accommodation-result-section">
                    <div className="container">
                        <div className="wt-accommodation-result-header">
                            {!loading &&
                                <div className="wt-filter-btn">
                                    <button className="btn btn-outline-dark" onClick={() => setFilterModal(true)}>
                                        <span className="btn-icon">
                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.375 16.4062C9.375 16.199 9.45731 16.0003 9.60382 15.8538C9.75034 15.7073 9.94905 15.625 10.1562 15.625H14.8438C15.051 15.625 15.2497 15.7073 15.3962 15.8538C15.5427 16.0003 15.625 16.199 15.625 16.4062C15.625 16.6135 15.5427 16.8122 15.3962 16.9587C15.2497 17.1052 15.051 17.1875 14.8438 17.1875H10.1562C9.94905 17.1875 9.75034 17.1052 9.60382 16.9587C9.45731 16.8122 9.375 16.6135 9.375 16.4062ZM6.25 11.7188C6.25 11.5115 6.33231 11.3128 6.47882 11.1663C6.62534 11.0198 6.82405 10.9375 7.03125 10.9375H17.9688C18.176 10.9375 18.3747 11.0198 18.5212 11.1663C18.6677 11.3128 18.75 11.5115 18.75 11.7188C18.75 11.926 18.6677 12.1247 18.5212 12.2712C18.3747 12.4177 18.176 12.5 17.9688 12.5H7.03125C6.82405 12.5 6.62534 12.4177 6.47882 12.2712C6.33231 12.1247 6.25 11.926 6.25 11.7188ZM3.125 7.03125C3.125 6.82405 3.20731 6.62534 3.35382 6.47882C3.50034 6.33231 3.69905 6.25 3.90625 6.25H21.0938C21.301 6.25 21.4997 6.33231 21.6462 6.47882C21.7927 6.62534 21.875 6.82405 21.875 7.03125C21.875 7.23845 21.7927 7.43716 21.6462 7.58368C21.4997 7.73019 21.301 7.8125 21.0938 7.8125H3.90625C3.69905 7.8125 3.50034 7.73019 3.35382 7.58368C3.20731 7.43716 3.125 7.23845 3.125 7.03125Z" fill="#0E1826" />
                                            </svg>
                                        </span>
                                        <span>{t('filter')}</span>
                                    </button>
                                </div>}
                        </div>
                        {!loading ?
                            <div className="wt-accommodation-card-content">
                                <div className="row">
                                    {searched.length > 0 ? searched.map(elem =>
                                        <div className="col col-12 col-md-4 col-lg-3">
                                            <div className="wt-accommodation-card">
                                                <div className="wt-accommodation-card-header">
                                                    <img src={elem?.images} alt="accommodation-slide-img" className="img-fluid" style={{ height: '300px', width: '275px', objectFit: 'cover', borderRadius: '8px', }} />
                                                    {localStorage.getItem("WEE_USER_ID") && <span className={`wt-accommodation-like-btn ${localStorage.getItem("WEE_USER_ID") && elem?.saved_by?.includes(localStorage.getItem("WEE_USER_ID")) ? '' : 'active'}`} onClick={() => handleSave(elem)}>
                                                        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.9921 3.64436C9.05937 1.48008 5.83645 0.897898 3.4149 2.87972C0.993354 4.86155 0.652433 8.17507 2.55409 10.519L10.9921 18.3334L19.4301 10.519C21.3317 8.17507 21.0324 4.8407 18.5693 2.87972C16.1061 0.918745 12.9248 1.48008 10.9921 3.64436Z" fill="#FDA4AF" stroke="#F43F5E" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </span>}
                                                </div>
                                                <div className="wt-accommodation-card-body">
                                                    <label className="wt-accommodation-card-label">{elem?.description?.length > 30 ? elem?.description?.substring(0, 30) + '...' : elem?.description}</label>
                                                    <a style={{ cursor: 'pointer' }} onClick={() => selectPost(elem)} className="wt-accommodation-card-link">{elem?.title}</a>
                                                    <p className="wt-accommodation-card-rating">
                                                        <span>{elem?.rating}</span>
                                                        <span>
                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.04167 0.958313L8.5 5.54165H13.0833L9.33333 8.45831L10.5833 13.0416L7.04167 10.125L3.5 13.0416L4.75 8.45831L1 5.54165H5.58333L7.04167 0.958313Z" fill="#FCD34D" stroke="#F59E0B" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                        <span>({elem?.reviews} {t('reviews')})</span>
                                                    </p>
                                                    <p className="wt-accommodation-card-price">MXN ${elem?.post_type === 1 ? elem?.price_per_room : elem?.post_type === 2 ? elem?.price_for_day : elem?.price_per_hour} <span>/{t('per_night')}</span></p>
                                                    {/* <a onClick={() => selectPost(elem)} className="btn btn-outline-success">{t('reserve2')}</a> */}
                                                </div>
                                            </div>
                                        </div>) : <span>{t('data_not_found')}</span>}
                                </div>
                            </div> :
                            <div className="wt-accommodation-card-content">
                                <div className="row">
                                    <div className="col col-12 col-md-4 col-lg-3">
                                        <div className="wt-accommodation-card">
                                            <div className="wt-accommodation-card-header">
                                                <Skeleton className='sk-image' count={1} />
                                            </div>
                                            <div className="wt-accommodation-card-body">
                                                <Skeleton count={1} className='sk-desc' />
                                                <Skeleton count={2} className='sk-title' />
                                                <Skeleton count={1} className='sk-price' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-4 col-lg-3">
                                        <div className="wt-accommodation-card">
                                            <div className="wt-accommodation-card-header">
                                                <Skeleton className='sk-image' count={1} />
                                            </div>
                                            <div className="wt-accommodation-card-body">
                                                <Skeleton count={1} className='sk-desc' />
                                                <Skeleton count={2} className='sk-title' />
                                                <Skeleton count={1} className='sk-price' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-4 col-lg-3">
                                        <div className="wt-accommodation-card">
                                            <div className="wt-accommodation-card-header">
                                                <Skeleton className='sk-image' count={1} />
                                            </div>
                                            <div className="wt-accommodation-card-body">
                                                <Skeleton count={1} className='sk-desc' />
                                                <Skeleton count={2} className='sk-title' />
                                                <Skeleton count={1} className='sk-price' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-4 col-lg-3">
                                        <div className="wt-accommodation-card">
                                            <div className="wt-accommodation-card-header">
                                                <Skeleton className='sk-image' count={1} />
                                            </div>
                                            <div className="wt-accommodation-card-body">
                                                <Skeleton count={1} className='sk-desc' />
                                                <Skeleton count={2} className='sk-title' />
                                                <Skeleton count={1} className='sk-price' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                        {/* {searched.length > 0 ? <div className="wt-site-pagination">
                            <nav aria-label="Page navigation">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link disabled" href="#" aria-label="Previous">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.625 4.375L4.875 6L6.625 7.625" stroke="#0E1826" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li className="page-item"><a className="page-link active" href="#">1</a></li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.375 4.375L7.125 6L5.375 7.625" stroke="#0E1826" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div> : ''} */}
                    </div>
                </section>
                { /* Principales Section End */}

            </main>
            <Footer />
        </div>
    )
}

export default Accomodations