import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import Checkout from './Checkout';
import { STRIPE_KEY } from '../BaseURL';

const ConfirmPayment = () => {
    const stripe = loadStripe(STRIPE_KEY);
    return (
        <Elements stripe={stripe}>
            <Checkout />
        </Elements>
    )
}

export default ConfirmPayment
