import React from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const DeleteModal = ({ activeModal, setActiveModal, handleDelete }) => {
    return (
        <Modal
            className="modal fade wt-confirm-modal"
            id="confirmGuestModal"
            show={activeModal}
            onHide={setActiveModal}
            backdrop="static"
            keyboard={false}
            size='sm'
        >
            {/* <Modal.Header>
            </Modal.Header> */}
            <Modal.Body>
                <div class="wt-confirm-pay-modal-content">
                    <div class="wt-confirm-pay-guest-area">
                        <div className='d-flex justify-content-between w-100'>
                            <img src='images/wearyFace.svg' alt='emoji' />
                            <button type="button" class="btn-close" onClick={setActiveModal}>
                                <img src='images/Button-close-X.svg' alt='close' />
                            </button>

                        </div>
                        <h3 class="wt-confirm-pay-modal mt-4">Delete?</h3>
                        <p>Are you sure you want to Delete?</p>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-between">
                    <button type="button" class="btn btn-light mr-2" onClick={setActiveModal}>No</button>
                    <button type="button" class="btn btn-success" onClick={handleDelete} >Yes</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteModal
