import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const LogoutModal = ({ activeModal, setActiveModal, handleLogOut }) => {
    const { t } = useTranslation();
    return (
        <Modal
            className="modal fade wt-confirm-modal"
            id="confirmGuestModal"
            show={activeModal}
            onHide={setActiveModal}
            backdrop="static"
            keyboard={false}
            size='md'
        >
            {/* <Modal.Header>
            </Modal.Header> */}
            <Modal.Body>
                <div class="wt-confirm-pay-modal-content">
                    <div class="wt-confirm-pay-guest-area">
                        <div className='d-flex justify-content-between w-100'>
                            <img src='images/wearyFace.svg' alt='emoji' />
                            <button type="button" class="btn-close" onClick={setActiveModal}>
                                <img src='images/Button-close-X.svg' alt='close' />
                            </button>

                        </div>
                        <h3 class="wt-confirm-pay-modal mt-4">{t('logout')}</h3>
                        <p>{t('logout_text')}</p>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-between">
                    <button type="button" class="btn btn-light mr-2" onClick={setActiveModal}>{t('cancel')}</button>
                    <button type="button" class="btn btn-success" onClick={handleLogOut} >{t('confirm')}</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LogoutModal
