import React, { useEffect, useState } from 'react'
import { auth, db, signInWithGooglePopup } from '../FirebaseConfig';
import { Timestamp, collection, getDocs, query, where } from "firebase/firestore";
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import ImageSlider from './ImageSlider';

//changes too make build

const Login = () => {
    const { t } = useTranslation();
    const [details, setDetails] = useState({
        password: '',
        email: '',
        remember: false
    });
    const navigate = useNavigate();
    const location = useLocation();
    const [disable, setDisable] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const handleChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value?.trim()?.length > 0 ? e.target.value : "" });
    }

    const validation = () => {
        let err = {};
        for (let key in details) {
            if (!details[key] && key !== 'remember') {
                err[key + '_err'] = t('please_enter') + t(`${key}`).replace(/_/g, " ");
                toast.error(t('please_enter') + t(`${key}`).replace(/_/g, " "), { style: { background: '#333', color: '#fff' } })
            } else if (key === "email") {
                if (typeof details[key] === "undefined" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(details[key])) {
                    err['email_err'] = t('valid_email');
                    toast.error(t('valid_email'), { style: { background: '#333', color: '#fff' } })
                }
            }
        }
        return (Object.keys(err).length > 0) ? false : true;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validation()) {
            setDisable(true);
            signInWithEmailAndPassword(auth, details.email, details.password).then(async () => {
                await getDocs(query(collection(db, 'user'), where('email', '==', details.email))).then((querysnapshot) => {
                    // console.log(querysnapshot.docs.length);
                    querysnapshot.docs.forEach((doc) => {
                        if (doc.data().user_type === 0 && location?.state?.state) {
                            toast.error(t('host_login_error'), { style: { background: '#333', color: '#fff' } });
                            setDisable(false);
                        } else {
                            localStorage.setItem('WEE_USER_ID', doc.id);
                            localStorage.setItem('WEE_USER_TYPE', doc.data().user_type === 0 ? "HOST" : "CUSTOMER");
                            localStorage.setItem('WEE_USER_LOGIN_TYPE', "CLASSIC");
                            setDisable(false);
                            toast.success(t('login_success'), { style: { background: '#333', color: '#fff' } });
                            setTimeout(() => {
                                if (location?.state?.state) {
                                    navigate('/accomodation-detail', { state: location?.state?.state, replace: true });
                                } else {
                                    navigate('/')
                                }
                            }, [1000])
                        }
                    })
                })
                if (details.remember) {
                    localStorage.setItem('WEE_USER_EMAIL', details.email);
                    localStorage.setItem('WEE_USER_PASSWORD', details.password);
                    localStorage.setItem('WEE_USER_REMEMBER_ME', details.remember);
                } else {
                    localStorage.removeItem('WEE_USER_EMAIL');
                    localStorage.removeItem('WEE_USER_PASSWORD');
                    localStorage.removeItem('WEE_USER_REMEMBER_ME');
                }
            }).catch((err) => {
                setDisable(false);
                toast.error(t('incorrect_credentials'), { style: { background: '#333', color: '#fff' } })
            })
        }
        // await addDoc(collection(db, 'sample'), { name: 'Sample' })
        // (await getDocs(collection(db, 'sample'), where('name', '==', 'Sample'))).docs.map((doc) => console.log(doc.id, doc.data()));
    }

    useEffect(() => {
        if (localStorage.getItem('WEE_USER_REMEMBER_ME') && localStorage.getItem('WEE_USER_REMEMBER_ME') === 'true') {
            setDetails({
                email: localStorage.getItem('WEE_USER_EMAIL'),
                password: localStorage.getItem('WEE_USER_PASSWORD'),
                remember: true
            })
        }
    }, [])

    const logGoogleUser = async () => {
        try {
            const response = await signInWithGooglePopup();
            setDisable(true);
            if (response) {
                let user = response.user;
                await getDocs(query(collection(db, 'user'), where('email', '==', user.email))).then((querysnapshot) => {
                    if (querysnapshot.docs.length > 0) {
                        querysnapshot.docs.forEach((doc) => {
                            if (doc.data().login_type === 2 && doc.data().password === null) {
                                if (doc.data().user_type === 0 && location?.state?.state) {
                                    toast.error(t('host_login_error'), { style: { background: '#333', color: '#fff' } });
                                    setDisable(false);
                                } else {
                                    localStorage.setItem('WEE_USER_ID', doc.id);
                                    localStorage.setItem('WEE_USER_LOGIN_TYPE', "GOOGLE");
                                    localStorage.setItem('WEE_USER_TYPE', doc.data().user_type === 0 ? "HOST" : "CUSTOMER");
                                    setDisable(false);
                                    toast.success(t('login_success'), { style: { background: '#333', color: '#fff' } });
                                    setTimeout(() => {
                                        if (location?.state?.state && doc.data().user_type !== 0) {
                                            navigate('/accomodation-detail', { state: location?.state?.state, replace: true });
                                        } else {
                                            navigate('/')
                                        }
                                    }, [1000])
                                }
                            } else {
                                toast.error(t('account_not_found'), { style: { background: '#333', color: '#fff' } });
                                setDisable(false);
                            }
                        })
                    } else {
                        toast.error(t('account_not_found2'), { style: { background: '#333', color: '#fff' } });
                        setDisable(false);
                    }
                })
            } else {
                toast.error(t('something_went_wrong'), { style: { background: '#333', color: '#fff' } });
                setDisable(false);
            }
        } catch (err) {
            // console.log(t('something_went_wrong'), err);
            setDisable(false);
        }
    }
    return (
        <>
            <Toaster />
            <div className='wt-site'>
                <section className="wt-login-section">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-6">
                            <div className="wt-login-section-area">
                                <div className="wt-login-logo">
                                    <a href="/" className="wt-login-logo-link">
                                        <img src="images/logo-dark-new.png" alt="Logo" className="img-fluid" />
                                    </a>
                                </div>
                                <div className="wt-login-content">
                                    <div className="wt-login-heading">
                                        <h1 className="wt-section-title">{t('sign_in_text')}</h1>
                                        <p className="wt-section-description">{t('sign_in_desc')}</p>
                                    </div>
                                    <div className="wt-login-form-area">
                                        <form className="row" onSubmit={handleSubmit}>
                                            <div className="col col-12 col-md-12">
                                                <label htmlFor="inputEmail" className="form-label">{t('email')}</label>
                                                <input type="email" className="form-control" id="inputEmail" placeholder={t("enter_email")} name="email" value={details.email} onChange={handleChange} />
                                            </div>
                                            <div className="col col-12 col-md-12 password">
                                                <label htmlFor="inputPassword" className="form-label">{t('password')}</label>
                                                <input type={passwordType} className="form-control" id="inputPassword" placeholder={t("enter_password")} name="password" value={details.password} onChange={handleChange} />
                                                <img className="eye-icon"
                                                    onClick={() => {
                                                        setPasswordType(passwordType === "password" ? "text" : "password");
                                                    }} src={passwordType === 'password' ? "/images/eye.svg" : "/images/eye-slash.svg"} />
                                            </div>
                                            <div className="col col-12">
                                                <div className="remeber-forgot-content">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="gridCheck" checked={details.remember} onChange={() => setDetails({ ...details, remember: !details.remember })} />
                                                        <label className="form-check-label" htmlFor="gridCheck">
                                                            {t('remember_me')}
                                                        </label>
                                                    </div>
                                                    <a href="/forgot-password" className="wt-forgot-password-link">{t('forgot_password')}</a>
                                                </div>
                                            </div>
                                            <div className="col col-12">
                                                <button type="submit" className="btn btn-success w-100" disabled={disable}>{disable ? `${t('processing')}...` : t('signin')}</button>
                                            </div>
                                            <div className="col col-12">
                                                <div className="wt-social-login-content">
                                                    <p className="wt-form-notes">{t('dont_have_account')} <a href="/signup">{t('signup')}</a></p>
                                                    <ul className="list-unstyled wt-social-login-list">
                                                        <li className="wt-social-login-item">
                                                            <a onClick={logGoogleUser} className="wt-social-login-link" style={{ cursor: "pointer" }}>
                                                                <span className="wt-social-login-icon">
                                                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M3.76756 10.2733L3.17581 12.4824L1.01299 12.5282C0.366629 11.3293 0 9.95763 0 8.50001C0 7.09051 0.342789 5.76132 0.950406 4.59094H0.950871L2.87639 4.94396L3.71988 6.85792C3.54334 7.3726 3.44712 7.9251 3.44712 8.50001C3.44718 9.12397 3.56021 9.72179 3.76756 10.2733Z" fill="#FBBB00" />
                                                                        <path d="M16.8516 6.91211C16.9492 7.42629 17.0001 7.95731 17.0001 8.50002C17.0001 9.10856 16.9362 9.70217 16.8143 10.2748C16.4005 12.2232 15.3193 13.9246 13.8215 15.1286L13.8211 15.1282L11.3958 15.0044L11.0525 12.8616C12.0464 12.2788 12.8231 11.3667 13.2322 10.2748H8.68701V6.91211H13.2985H16.8516Z" fill="#518EF8" />
                                                                        <path d="M13.8208 15.1281L13.8213 15.1286C12.3647 16.2994 10.5142 17 8.49994 17C5.26294 17 2.44861 15.1907 1.01294 12.5281L3.7675 10.2733C4.48532 12.1891 6.33337 13.5528 8.49994 13.5528C9.43119 13.5528 10.3036 13.3011 11.0523 12.8616L13.8208 15.1281Z" fill="#28B446" />
                                                                        <path d="M13.9256 1.95686L11.1719 4.21122C10.3971 3.72692 9.48125 3.44715 8.50003 3.44715C6.28442 3.44715 4.40181 4.87346 3.71995 6.85791L0.950904 4.59093H0.950439C2.36509 1.86346 5.21492 0 8.50003 0C10.5624 0 12.4535 0.734653 13.9256 1.95686Z" fill="#F14336" />
                                                                    </svg>
                                                                </span>
                                                                <span>{t('signin_google')}</span>
                                                            </a>
                                                        </li>
                                                        {/* <li className="wt-social-login-item">
                                                            <a href="#" className="wt-social-login-link wt-social-login-facebook">
                                                                <span className="wt-social-login-icon">
                                                                    <svg width="9" height="19" viewBox="0 0 9 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M7.35689 3.48875H9V0.62675C8.71652 0.58775 7.74161 0.5 6.6062 0.5C4.23715 0.5 2.61428 1.99025 2.61428 4.72925V7.25H0V10.4495H2.61428V18.5H5.81952V10.4503H8.32806L8.72627 7.25075H5.81877V5.0465C5.81952 4.12175 6.06849 3.48875 7.35689 3.48875Z" fill="white" />
                                                                    </svg>
                                                                </span>
                                                                <span>Sign in with Facebook</span>
                                                            </a>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-6">
                            <ImageSlider />
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Login