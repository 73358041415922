import React, { useEffect, useState } from 'react'
import Header from '../layouts/Header';
import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where, onSnapshot } from 'firebase/firestore';
import { database, db } from '../FirebaseConfig';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { child, get, getDatabase, onValue, push, ref, set, update } from 'firebase/database';
import { useTranslation } from 'react-i18next';

let unsubscribe;
const Chat2 = () => {
    const [chatList, setChatList] = useState([]);
    const [chatDetails, setChatDetails] = useState({});
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(true);
    const loadingCards = [1, 2, 3, 4, 5, 6, 7]
    const location = useLocation();
    const [selectedId, setSelectedId] = useState('');
    const { t } = useTranslation();
    // console.log(location.state);

    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        if (selectedId || location?.state) {
            getMessages();
        }
    }, [selectedId, loading]);

    const getCount = async (chatid) => {
        try {
            let unreadCount = 0;
            const starCountRef = ref(database, `messages/${chatid}/chats`);
            onValue(starCountRef, async (snapshot) => {
                const datas = snapshot.val();
                // console.log(datas)
                if (snapshot.exists()) {
                    unreadCount = datas.filter(data => data.idFrom !== localStorage.getItem("WEE_USER_ID") && data.read === false).length;
                }
            });
            return unreadCount;
        } catch (error) {
            console.error('Error fetching chat stream:', error);
            return 0;
        }
    }

    const fetchData = async () => {
        const starCountRef = ref(database, 'messages');
        onValue(starCountRef, async (snapshot) => {
            let arr = [];
            if (snapshot.exists()) {
                let value = snapshot.val();
                await Promise.all(Object.keys(value).map(async key => {
                    if (key?.split('-')?.includes(localStorage.getItem("WEE_USER_ID"))) {
                        let index = value[key].ids.indexOf(localStorage.getItem("WEE_USER_ID"));
                        let receiver_details = {};
                        let unreadCount = await getCount(key);
                        await getDoc(doc(db, 'user', value[key].ids[index === 0 ? 1 : 0])).then(async docs => {
                            receiver_details = { id: docs, ...docs.data() }
                        });
                        arr.push({ ...value[key], receiver: receiver_details, unreadCount })
                    }
                }));
            }
            let filter_arr = arr.sort((a, b) => new Date(b.updated_date) - new Date(a.updated_date))
            setChatList(filter_arr);
            // setTimeout(() => {
            // if (!selectedId) {
            setLoading(false);
            // }
            // }, 1500)
        })
    };

    const getMessages = async () => {
        if (!loading) {
            let id = selectedId ? selectedId : location?.state;
            // console.log(id)

            const starCountRef = ref(database, 'messages/' + id);
            unsubscribe = onValue(starCountRef, async (snapshot) => {
                const data = snapshot.val();
                let details = chatList?.find(e => e.chat_id === id);
                // console.log(details)
                // setTimeout(async () => {
                // setChatDetails({ ...details, msgs: data?.chats?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) ??[] });
                // }, 1500);
                // console.log(data.chats)
                // setTimeout(
                // async () => {
                // if (details?.unreadCount > 0) {
                //     console.log("yes")
                let msg_db = `messages/${id}`;

                //     // let arr = chats?.map((e) => {
                //     //     if (e.idFrom !== localStorage.getItem("WEE_USER_ID") && e.read === false) {
                //     //         // Mark message as read and return
                //     //         return { ...e, read: true };
                //     //     } else {
                //     //         // Return message as is
                //     //         return e;
                //     //     }
                //     // });

                //     // let updatedChats = arr.filter(e => e.read === true);
                //     // if (updatedChats.length > 0) {
                //     //     updates[msg_db] = updatedChats;
                //     //     await update(ref(database), updates);
                //     // }

                //     // await Promise.all(chats.map(async (e, index) => {
                let updates = {};
                //     //     if (e.idFrom !== localStorage.getItem("WEE_USER_ID") && e.read === false) {
                //     //         console.log("yse00")
                //     //         // let msgRef = ref(database, `messages/${selectedId}/chats/${index}`);
                //     //         // await update(msgRef, { read: true });
                //     //         updates[`messages/${selectedId}/chats/${index}`] = { ...e, read: true };
                //     //         await update(ref(database), updates);
                //     //     } else {
                //     //         console.log("no00")
                //     //     }
                //     // }));

                for (let key in data?.chats) {
                    let e = data?.chats[key];
                    if (e.idFrom !== localStorage.getItem("WEE_USER_ID") && e.read === false) {
                        let msgRef = ref(database, `messages/${selectedId || location.state}/chats/${key}`);
                        await update(msgRef, { read: true });
                    }
                }
                setChatDetails({ ...details, msgs: data?.chats?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) ?? [] });
                // let arr = data?.chats ? data?.chats?.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)) : [];
                // await Promise.all(arr.map(async (e, index) => {
                //     console.log("test",e.idFrom !== localStorage.getItem("WEE_USER_ID") ,e.read,e.content, e.read === false);
                //     if (e.idFrom !== localStorage.getItem("WEE_USER_ID") && e.read === false) {
                //         console.log("yes")
                //         console.log(e)
                //         console.log(index,e.content)
                //         let msgRef = ref(database, `messages/${selectedId}/chats/${index}`);
                //         await update(msgRef, { read: true });
                //     } else {
                //         console.log("no")
                //     }
                // }));
                // updates[`${msg_db}/chats`] = arr.reverse();
                // await update(ref(database), updates).catch((error) => {
                //     console.error('Error updating document:', error);
                // });
                // } else {
                //     console.log("no")
                // }
                // }
                // , 2000);
                // console.log(details?.unreadCount,"bolo")
            });
        }
    }


    const handleSend = async (e) => {
        e.preventDefault();
        if (input?.trim()?.length > 0) {
            let arr = chatDetails?.msgs?.reverse();
            let text = input;
            arr.push({
                content: text,
                idFrom: localStorage.getItem("WEE_USER_ID"),
                idTo: chatDetails?.receiver?.id,
                timestamp: Date.now(),
                type: 0,
                read: false
            });
            let newMessage = {
                content: text,
                idFrom: localStorage.getItem("WEE_USER_ID"),
                idTo: chatDetails?.receiver?.id,
                timestamp: Date.now(),
                type: 0,
                read: false
            };
            const chatsRef = ref(database, `messages/${selectedId || location.state}/chats`);
            const chatsSnapshot = await get(chatsRef);
            const chatsLength = chatsSnapshot.val() === null ? 0 : chatsSnapshot.val().length;
            let msgRef = ref(database, `messages/${selectedId || location.state}/chats/${chatsLength}`);
            setInput('');
            await set(msgRef, newMessage);
            setChatDetails({ ...chatDetails, last_msg: text, msgs: arr?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) });
            let updates = {};
            let msg_db = `messages/${selectedId || location.state}`;
            // updates[`${msg_db}/chats`] = arr;
            updates[`${msg_db}/updated_date`] = Date.now();
            updates[`${msg_db}/last_msg`] = text;
            await update(ref(database), updates);
        }
    }

    const handleTime = (date) => {
        if (date) {
            if (moment(new Date(Number(date)))?.format("MM/DD/YY") === moment().format("MM/DD/YY")) {
                return moment(new Date(Number(date)))?.format("h:mm a")
            } else {
                return moment(new Date(Number(date)))?.format("MM/DD/YY")
            }
        }
    }

    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    }

    const handleTime2 = (date) => {
        if (date) {
            if (moment(new Date(Number(date)))?.format("MM/DD/YY") === moment().format("MM/DD/YY")) {
                return moment(new Date(Number(date)))?.format("h:mm a")
            } else {
                return moment(new Date(Number(date)))?.format("MM/DD/YY h:mm a")
            }
        }
    }

    const handleSelect = (id) => {
        setSelectedId(id);
        if (unsubscribe) {
            unsubscribe();
            unsubscribe = null; // Clear the reference
        }
    }
    // console.log("unsubscribe", unsubscribe)
    return (
        <div className='wt-site'>
            <Header />
            <main className="wt-site-content">
                { /* Landing Section Start */}
                <section className="wt-message-section">
                    <div className="wt-message-sidebar">
                        <div className="wt-notification-header wt-message-header">
                            <h1 className="wt-section-title">{t('messages')}</h1>
                        </div>
                        <div className="wt-message-chat-list-content">
                            <div className="wt-message-chat-list">
                                {!loading ?
                                    chatList?.map((el, index) =>
                                        <div key={index} className={`wt-message-chat-item ${el?.chat_id === (selectedId || location.state) ? "active" : ""}`} style={{ cursor: 'pointer' }} onClick={() => { handleSelect(el?.chat_id); }}>
                                            <div className="wt-message-chat-profile">
                                                {el?.receiver?.profile_image ? <img src={el?.receiver?.profile_image} alt="chat-profile" className="img-fluid" /> :
                                                    <span style={{ display: 'block', backgroundColor: '#1AAA69', width: '40px', height: '40px', textAlign: 'center', borderRadius: '50%', fontSize: '25px', fontWeight: '600', paddingTop: '2px', color: 'white' }} >{el?.receiver?.first_name?.charAt(0)}</span>}
                                            </div>
                                            <div className="wt-message-chat-detail">
                                                <h4 className="wt-message-chat-title">{truncate(`${el?.receiver?.first_name} ${el?.receiver?.last_name}`, 20)}</h4>
                                                <p className="wt-message-chat-description">{truncate(el?.last_msg, 20)}</p>
                                            </div>
                                            <div className="wt-message-chat-info">
                                                <p className="wt-message-chat-time">{handleTime(el?.updated_date)}</p>
                                                {el?.unreadCount > 0 && <span className="wt-message-chat-new-count">{el?.unreadCount}</span>}
                                            </div>
                                        </div>) :
                                    loadingCards?.map((_, index) =>
                                        <div className="wt-message-chat-item" key={index} >
                                            <div className="wt-message-chat-profile">
                                                <Skeleton circle={true} height={40} width={40} />
                                            </div>
                                            <div className="wt-message-chat-detail">
                                                <Skeleton width={100} height={20} />
                                                <Skeleton width={100} height={20} />
                                            </div>
                                            {/* <div className="wt-message-chat-info">
                                                <p className="wt-message-chat-time">{handleTime(el?.updated_date)}</p>
                                                {el?.unreadCount > 0 && <span className="wt-message-chat-new-count">{el?.unreadCount}</span>}
                                            </div> */}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="wt-message-chat-area">
                        <div className="wt-notification-header wt-message-header">
                            <h3 className="wt-section-title"> </h3>
                        </div>
                        {chatList?.length === 0 && !loading &&
                            <div className="wt-message-chat-card d-flex flex-row justify-content-center" style={{marginTop:"200px"}}>
                                <p>No Messages Found..!</p>
                            </div>}
                        {(selectedId || location.state) && !loading && <div className="wt-message-chat-card">
                            <div className="wt-message-chat-card-body">
                                {chatDetails?.msgs?.map((elem, index) =>
                                    elem?.idFrom !== localStorage.getItem("WEE_USER_ID") ?
                                        <div className="wt-message-item">
                                            <div className="wt-message-item__inner">
                                                <p className="wt-message-description">{elem?.content}</p>
                                            </div>
                                            <span className="wt-message-item-time">{handleTime2(elem?.timestamp)}</span>
                                        </div> :
                                        <div className="wt-message-item wt-current-message-item">
                                            <div className="wt-message-item__inner">
                                                <p className="wt-message-description">{elem?.content}</p>
                                            </div>
                                            <span className="wt-message-item-time">{handleTime2(elem?.timestamp)}</span>
                                        </div>)}
                            </div>
                            <div className="wt-message-chat-card-footer">
                                <div className="wt-message-chat-input">
                                    <form onSubmit={handleSend} >
                                        <input type="text" className="form-control" placeholder={t('type_msg')} onChange={(e) => setInput(e.target.value)} value={input} />
                                        <button type="submit" className="btn btn-success">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22 2L11 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="wt-message-profile-details">
                        <div className="wt-notification-header wt-message-header">
                            <h1 className="wt-section-title">{t('details')}</h1>
                            <span className="wt-message-profile-close">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.5297 17.4698C18.8227 17.7628 18.8227 18.2378 18.5297 18.5308C18.3837 18.6768 18.1917 18.7508 17.9997 18.7508C17.8077 18.7508 17.6158 18.6778 17.4698 18.5308L11.9997 13.0608L6.52975 18.5308C6.38375 18.6768 6.19175 18.7508 5.99975 18.7508C5.80775 18.7508 5.61575 18.6778 5.46975 18.5308C5.17675 18.2378 5.17675 17.7628 5.46975 17.4698L10.9398 11.9998L5.46975 6.52981C5.17675 6.23681 5.17675 5.76177 5.46975 5.46877C5.76275 5.17577 6.23775 5.17577 6.53075 5.46877L12.0008 10.9388L17.4707 5.46877C17.7637 5.17577 18.2387 5.17577 18.5317 5.46877C18.8247 5.76177 18.8247 6.23681 18.5317 6.52981L13.0617 11.9998L18.5297 17.4698Z" fill="#25314C" />
                                </svg>
                            </span>
                        </div>
                        {Object.keys(chatDetails).length > 0 && <div className="wt-message-profile-content">
                            <div className="wt-message-profile-img">
                                {chatDetails?.receiver?.profile_image ? <img src={chatDetails?.receiver?.profile_image} alt="Profile" className="img-fluid" /> :
                                    <span >{chatDetails?.receiver?.first_name?.charAt(0)}</span>}
                            </div>
                            <div className="wt-message-profile__inner">
                                <h3 className="wt-message-profile-title">{chatDetails?.receiver?.first_name} {chatDetails?.receiver?.last_name}</h3>
                                <div className="wt-message-profile-info">
                                    <div className="wt-message-profile-info-item">
                                        <span className="wt-message-profile-info-icon">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" stroke="#535379" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                        <span>{chatDetails?.receiver?.rating ?? '0.0'}/5</span>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </section>

            </main>
        </div>
    )
}

export default Chat2