// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAu4y8GkZtwZWpgtalSJzXPlKZleHqhO2Q",
    authDomain: "weetravelsafe-3a36d.firebaseapp.com",
    projectId: "weetravelsafe-3a36d",
    storageBucket: "weetravelsafe-3a36d.appspot.com",
    messagingSenderId: "623269727301",
    appId: "1:623269727301:web:a556c770c2972cc87f672c",
    measurementId: "G-K6L6RRCH7Q",
    databaseURL: "https://weetravelsafe-3a36d-default-rtdb.firebaseio.com/"
};
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
    prompt: "select_account "
});
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const database = getDatabase(app);
export const auth = getAuth(app);
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);