import React from 'react'
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'
import { useTranslation } from 'react-i18next'
import NotAMember from './NotAMember'

const AboutUs = () => {
    const { t } = useTranslation();
    return (
        <div className='wt-site'>
            <Header />
            <main className="wt-site-content">
                <section className="wt-inner-page-landing-section">
                    <div className="container">
                        <div className="row">
                            <div className="col col-12 col-md-6 col-lg-6">
                                <div className="wt-inner-page-img">
                                    <img src="images/about-img.png" alt="about-img" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col col-12 col-md-6 col-lg-6">
                                <div className="wt-inner-page-landing-content">
                                    <h1 className="wt-section-title">{t('about_us')}</h1>
                                    <p className="wt-section-description">{t('about_us_text')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wt-about-section">
                    <div className="wt-about-item">
                        <div className="container">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="wt-about-content">
                                        <h3 className="wt-about-title">{t('our_story')}</h3>
                                        <p className="wt-about-decription">{t('our_story_text')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wt-about-img">
                            <img src="images/story-img.png" alt="story-img" className="img-fluid" />
                        </div>
                    </div>
                    <div className="wt-about-item">
                        <div className="container">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="wt-about-content">
                                        <h3 className="wt-about-title">{t('vision')}</h3>
                                        <p className="wt-about-decription">{t('vision_text')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wt-about-img">
                            <img src="images/vision-img.png" alt="vision-img" className="img-fluid" />
                        </div>
                    </div>
                    <div className="wt-about-item">
                        <div className="container">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="wt-about-content">
                                        <h3 className="wt-about-title">{t('mission')}</h3>
                                        <p className="wt-about-decription">{t('mission_text')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wt-about-img">
                            <img src="images/mission-img.png" alt="mission-img" className="img-fluid" />
                        </div>
                    </div>
                </section>
                <section className="wt-partner-section">
                    <div className="container">
                        <div className="row">
                            <div className="col col-12 col-md-6 col-lg-6">
                                <div className="wt-partners-list">
                                    <div className="wt-partners-item">
                                        <img src="images/partners1.png" alt="partners" className="img-fluid" />
                                    </div>
                                    <div className="wt-partners-item">
                                        <img src="images/partners2.png" alt="partners" className="img-fluid" />
                                    </div>
                                    <div className="wt-partners-item">
                                        <img src="images/partners3.png" alt="partners" className="img-fluid" />
                                    </div>
                                    <div className="wt-partners-item">
                                        <img src="images/partners1.png" alt="partners" className="img-fluid" />
                                    </div>
                                    <div className="wt-partners-item">
                                        <img src="images/partners2.png" alt="partners" className="img-fluid" />
                                    </div>
                                    <div className="wt-partners-item">
                                        <img src="images/partners3.png" alt="partners" className="img-fluid" />
                                    </div>
                                    <div className="wt-partners-item">
                                        <img src="images/partners1.png" alt="partners" className="img-fluid" />
                                    </div>
                                    <div className="wt-partners-item">
                                        <img src="images/partners2.png" alt="partners" className="img-fluid" />
                                    </div>
                                    <div className="wt-partners-item">
                                        <img src="images/partners3.png" alt="partners" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="col col-12 col-md-6 col-lg-6">
                                <div className="wt-about-content">
                                    <h3 className="wt-about-title">{t('partner')}</h3>
                                    <p className="wt-about-decription">{t('partner_text')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <NotAMember />
            </main>
            <Footer />
        </div>
    )
}

export default AboutUs