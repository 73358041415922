import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, updateDoc, addDoc, collection, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import moment from 'moment';
import { API_URL } from '../BaseURL';

const Payment = () => {
    const { t } = useTranslation();
    const location = useLocation();
    // const { details } = location?.state; // Access passed data
    const nav = useNavigate();
    console.log(location?.state);
    let details = location?.state?.details ? location?.state?.details : JSON.parse(localStorage.getItem('paymentDetails'));
    let temp = JSON.parse(localStorage.getItem('temp'));
    
    const getAmt = () => {
        let amt = details?.post_type === 1 ? details?.price_per_room : details?.post_type === 2 ? details?.price_for_day : details?.price_per_hour;
        // let days = getDaysDifference(new Date(details?.start_date), new Date(details?.end_date)) + 1;
        let days = calculateTotalDays(details?.start_date, details?.check_in_time, details?.end_date, details?.check_out_time);
        return amt * days;

    };

    function tConvert(time) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }

    function calculateTotalDays(startDateStr, startTime, endDateStr, endTime) {
        const startDateTimeStr = `${startDateStr}T${startTime}`;
        const endDateTimeStr = `${endDateStr}T${endTime}`;

        // Convert the date-time strings to Date objects
        const startDateTime = new Date(startDateTimeStr);
        const endDateTime = new Date(endDateTimeStr);

        // Calculate the time difference in milliseconds
        const timeDifference = endDateTime - startDateTime;

        // Convert the time difference to hours
        const totalHours = timeDifference / (1000 * 3600);

        return totalHours.toFixed(2);
    }

    const getFinalAmt = () => {
        // let days = calculateTotalDays(details?.start_date, details?.end_date);
        let amt = getAmt();
        let commission = Number((amt * 10) / 100);
        let service = Number((amt * 16) / 100);
        return amt + commission + service;
    };

    useEffect(() => {
        doAction();
    }, [])
    const doAction = async () => {
        try {
            await addDoc(collection(db, 'my_booking'), {
                capacity: details?.capacity ?? "null",
                card_id: details?.card ?? "null",
                longitude: details?.longitude ?? "null",
                price_per_hour: details?.price_per_hour ?? "null",
                number_of_berths: details?.number_of_berths ?? "null",
                outdoor_equipment: details?.outdoor_equipment ?? "null",
                brand: details?.brand ?? "null",
                images: details?.images ?? "null",
                price_per_room: details?.price_per_room ?? "1",
                no_of_room_available: details?.no_of_room_available ?? null,
                fuel_type: details?.fuel_type ?? "null",
                fuel_litre: details?.fuel_litre ?? "null",
                segment: details?.segment ?? "null",
                navigation_equipment: details?.navigation_equipment ?? "null",
                transmission_type: details?.transmission_type ?? "null",
                fuel_included_in_price: details?.fuel_included_in_price ?? "null",
                description: details?.description ?? "null",
                no_of_children: details?.child,
                seating_capacity: details?.seating_capacity ?? "null",
                model: details?.model ?? "null",
                engine: details?.engine ?? "null",
                latitude: details?.latitude ?? "null",
                number_of_bathrooms: details?.number_of_bathrooms ?? "null",
                price_for_day: details?.price_for_day ?? "null",
                security_deposit: details?.security_deposit ?? "null",
                location: details?.location ?? "",
                availability_start_date: Timestamp.fromDate(new Date(new Date(temp?.start_date).getTime())),
                availability_end_date: Timestamp.fromDate(new Date(new Date(temp?.end_date).getTime())),
                leisure_activities: details?.leisure_activities ?? "null",
                title: details?.title,
                manufacturer: details?.manufacturer ?? "null",
                book_user_id: localStorage.getItem('WEE_USER_ID'),
                post_type: details?.post_type,
                post_id: details?.id,
                boat_license_required: details?.boat_licence_required ?? "null",
                check_out_time: details?.check_out_time ?? "null",
                extra_comforts: details?.extra_comforts ?? "null",
                number_of_cabins: details?.number_of_cabins ?? "null",
                length: details?.length ?? "null",
                created_date: Timestamp.fromDate(new Date(Date.now())),
                check_in_time: details?.check_in_time ?? "null",
                check_in_date: Timestamp.fromDate(new Date(new Date(details?.start_date).getTime())),
                check_out_date: Timestamp.fromDate(new Date(new Date(details?.end_date).getTime())),
                user_id: details?.user_id,
                year: details?.year ?? "null",
                price_per_guest: details?.price_per_guest ?? "null",
                customer_payment_id: "",
                no_of_personas: details?.no_of_personas,
                update_data: Timestamp.fromDate(new Date(Date.now())),
                commissionFee: Number((getAmt() * 10) / 100),
                serviceFee: Number((getAmt() * 16) / 100),
                total: Number(getFinalAmt())
            }).then(async docRef => await updateDoc(doc(db, 'my_booking', docRef.id), { id: docRef.id }));
            const user = await getDoc(doc(db, "user", localStorage.getItem('WEE_USER_ID')));
            const host = await getDoc(doc(db, "user", details?.user_id));

            await addDoc(collection(db, 'notification'), {
                availability_start_date: `${details?.start_date}T00:00:00.000`,
                availability_end_date: `${details?.end_date}T00:00:00.000`,
                comstomer_id: localStorage.getItem('WEE_USER_ID'),
                host_id: details?.user_id,
                created_date: new Date().toISOString(),
                post_name: details?.title,
                user_name: user?.data()?.first_name + " " + user?.data()?.last_name,
                user_profile: user?.data()?.profile_image ?? "",
                host_name: host?.data()?.first_name + " " + host?.data()?.last_name,
                host_profile: host?.data()?.profile_image ?? "",
            }).then(async docRef => {
                await updateDoc(doc(db, 'notification', docRef.id), { id: docRef.id });
                let earned = host?.data()?.my_earnings ?? 0;
                earned += getAmt();
                await updateDoc(doc(db, "user", details?.user_id), { my_earnings: earned });

                try {
                    var bodyParameter = new URLSearchParams();
                    bodyParameter.append('name', user?.data()?.first_name + " " + user?.data()?.last_name);
                    bodyParameter.append('email', user?.data()?.email);
                    bodyParameter.append('startDate', moment(details?.start_date).format('DD-MM-YYYY'));
                    bodyParameter.append('endDate', moment(details?.end_date).format('DD-MM-YYYY'));
                    bodyParameter.append('startTime', tConvert(details?.check_in_time));
                    bodyParameter.append('endTime', tConvert(details?.check_out_time));
                    bodyParameter.append('yachtName', details?.title);
                    bodyParameter.append('yachtDescription', details?.description);

                    await axios({
                        method: "post",
                        url: `${API_URL}booking-confirmed`,
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                        data: bodyParameter
                    }).then(async (response) => {
                        if (!response.data.success) {
                            console.log("Something went wrong while sending email");
                        }
                        else {
                            localStorage.removeItem('paymentDetails');
                            localStorage.removeItem('temp');
                            nav('/');
                        }
                    });
                } catch (error) {
                    console.log("error", error);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className='stripe-container'>
            <img src="/images/logo-dark-new2.png" />
            <div className='stripe-label'>
                <img src="/images/check-circle-icon.svg" />
                <span >{t('reserved')}</span>
            </div>
        </div>
    )
}

export default Payment