import React, { useEffect, useState } from 'react'
import Header from '../layouts/Header'
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { auth, db, storage } from '../FirebaseConfig';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import toast, { Toaster } from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';
import WithdrawModal from './WithdrawModal';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import AddCard from './AddCard';
import { Elements, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { API_URL, STRIPE_KEY } from '../BaseURL';
import axios from 'axios';
import PhoneNumber from 'google-libphonenumber';
import { useTranslation } from 'react-i18next';

const Setting = () => {
    const stripe = loadStripe(STRIPE_KEY);

    const avatars = [
        'https://firebasestorage.googleapis.com/v0/b/weetravelsafe-3a36d.appspot.com/o/profile_pic%2Favatar1.png?alt=media&token=c1c9aa60-68b4-4eb4-aed4-c4f76bc309ca',
        'https://firebasestorage.googleapis.com/v0/b/weetravelsafe-3a36d.appspot.com/o/profile_pic%2Favatar2.png?alt=media&token=5311adab-fdac-49fa-9685-d0faab9a44fc',
        'https://firebasestorage.googleapis.com/v0/b/weetravelsafe-3a36d.appspot.com/o/profile_pic%2Favatar3.png?alt=media&token=3cb25dea-fa44-4d9c-8845-ca42a1349510',
        'https://firebasestorage.googleapis.com/v0/b/weetravelsafe-3a36d.appspot.com/o/profile_pic%2Favatar4.png?alt=media&token=daf18c89-d778-4edc-b7af-65cfea39caaa',
        'https://firebasestorage.googleapis.com/v0/b/weetravelsafe-3a36d.appspot.com/o/profile_pic%2Favatar5.png?alt=media&token=c7df43e6-ff47-40a1-8d1d-ccdc63225e34',
        'https://firebasestorage.googleapis.com/v0/b/weetravelsafe-3a36d.appspot.com/o/profile_pic%2Favatar6.png?alt=media&token=702191a3-6b2c-413b-9a11-15561dd155b2',
        'https://firebasestorage.googleapis.com/v0/b/weetravelsafe-3a36d.appspot.com/o/profile_pic%2Favatar7.png?alt=media&token=1a8cb1d5-e75b-42f4-a672-be1edd6a3fed'
    ];

    useEffect(() => {
        getProfile();
        if (localStorage.getItem('WEE_USER_TYPE') === 'CUSTOMER') {
            getCards();
        } else {
            getEarnings();
        }
    }, []);
    const [customerId, setCustomerId] = useState('');
    const [cards, setCards] = useState([]);
    const [profile, setProfile] = useState({});
    const [earnings, setEarnings] = useState([]);
    const [password, setPassword] = useState({
        current: '',
        new: '',
        confirm: ''
    });
    const [passwordType, setPasswordType] = useState({
        current: 'password',
        new: 'password',
        confirm: 'password'
    });
    const [disable, setDisable] = useState(false);
    const [addCard, setAddCard] = useState(false);
    const [withdrawModal, setWithdrawModal] = useState(false);
    const { t, i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
        localStorage.setItem('WEE_USER_LAN', lng.toUpperCase())
    };

    const tabs = [
        { id: 1, name: t('profile') },
        localStorage.getItem("WEE_USER_LOGIN_TYPE") === "CLASSIC" && { id: 2, name: t('password') },
        { id: 3, name: localStorage.getItem("WEE_USER_TYPE") === "CUSTOMER" ? t('billing') : t('get_paid') },
        { id: 4, name: t('languages') }
    ];
    const [currentState, setCurrentState] = useState(1);
    const phoneUtil = PhoneNumber.PhoneNumberUtil.getInstance();

    const getProfile = async () => {
        const docRef = await getDoc(doc(db, "user", localStorage.getItem('WEE_USER_ID')));
        if (docRef?.exists()) {
            setProfile({ ...docRef?.data(), profile_pic: docRef.data().profile_image })
        } else {
            localStorage.removeItem('WEE_USER_ID')
        }
    }

    function getDaysDifference(date1, date2) {
        // Convert the dates to milliseconds since epoch (Jan 1, 1970)
        const milliseconds1 = date1.getTime();
        const milliseconds2 = date2.getTime();

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = Math.abs(milliseconds1 - milliseconds2);

        // Convert the difference to days (1 day = 1000 * 60 * 60 * 24 milliseconds)
        const daysDifference = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

        return daysDifference;
    }
    const getEarnings = async () => {
        // let earned = 0;
        // let paid = 0;
        // const docRef = await getDocs(query(collection(db, "my_booking"), where('user_id', '==', localStorage.getItem('WEE_USER_ID'))));
        // if (docRef?.docs?.length > 0) {
        //     const newData = [];
        //     await Promise.all(docRef.docs.map((doc) => {
        //         if (parseInt(doc.data().availability_end_date.seconds * 1000) < Date.now()) {
        //             let details = doc.data();
        //             let amt = details?.post_type === 1 ? details?.price_per_room : details?.post_type === 2 ? details?.price_for_day : details?.price_per_hour;
        //             let days = getDaysDifference(new Date(moment(details?.availability_start_date?.seconds * 1000).format('YYYY-MM-DD')), new Date(moment(details?.availability_end_date?.seconds * 1000).format('YYYY-MM-DD'))) + 1;
        //             newData.push({
        //                 id: doc.id,
        //                 ...doc.data(),
        //                 total: amt * days,
        //             });
        //             earned = (Number(earned) + Number(amt * days)).toFixed(2)
        //         }
        //     }));
        //     setEarnings(newData.sort((a, b) => b?.created_date?.seconds - a?.created_date?.seconds));
        // }
        const docRef2 = await getDocs(query(collection(db, "payouts"), where('user_id', '==', localStorage.getItem('WEE_USER_ID'))));
        const newData = [];
        await Promise.all(docRef2?.docs?.map(doc => {
            // paid = (Number(paid) + Number(doc.data().withdraw_amount)).toFixed(2);
            newData.push({ ...doc.data() });
        }));
        // setProfile({ ...data, profile_pic: data.profile_image })
        setEarnings(newData.sort((a, b) => new Date(b?.created_date) - new Date(a?.created_date)));
    }

    const handleChange = (e) => {
        if (e.target.name === 'first_name' || e.target.name === 'last_name') {
            let trimmedValue = e.target.value.trim();
            trimmedValue = trimmedValue.replace(/\s/g, '');
            setProfile({ ...profile, [e.target.name]: trimmedValue });
        }
        else {
            setProfile({ ...profile, [e.target.name]: e.target.value?.trim()?.length > 0 ? e.target.value : "" });
        }
    };

    const handleChangePassword = (e) => {
        setPassword({ ...password, [e.target.name]: e.target.value?.trim() ?? "" });
    }

    function validatePhoneNumber(phoneNumber) {
        // console.log(phoneNumber)
        try {
            const parsedNumber = phoneUtil.parse(`+${phoneNumber}`);
            const isValid = phoneUtil.isValidNumber(parsedNumber);
            return isValid;
        } catch (error) {
            console.error('Error parsing phone number:', error);
            return null;
        }
    }

    const handleImage = (e) => {
        let img = e.target.files[0];
        let types = ['image/png', 'image/jpeg', 'image/jpg']
        if (types.includes(img.type)) {
            // console.log(Date.now())
            let imgRef = ref(storage, `profile_pic/${Date.now()}`);
            const uploadTask = uploadBytesResumable(imgRef, img);
            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            // console.log('Upload is paused');
                            break;
                        case 'running':
                            // console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    toast.error(error, { style: { background: '#333', color: '#fff' } })
                    // Handle unsuccessful uploads
                },
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // console.log('File available at', downloadURL);
                        setProfile({ ...profile, profile_pic: downloadURL })
                    });
                });
        } else {
            toast.error(t('img_err'), { style: { background: '#333', color: '#fff' } })
        }
    };

    const validation = () => {
        let err = {};
        const details = {
            first_name: profile?.first_name,
            last_name: profile?.last_name,
            email: profile?.email,
            phone: profile?.phone_no,
        }
        for (let key in details) {
            if (!details[key]) {
                err[key + '_err'] = t('please_enter') + t(`${key}`).replace(/_/g, " ");
                toast.error(t('please_enter') + t(`${key}`).replace(/_/g, " "), { style: { background: '#333', color: '#fff' } })
            } else if (key === "email") {
                if (typeof details[key] === "undefined" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(details[key])) {
                    err['email_err'] = t('valid_email');
                    toast.error(t('valid_email'), { style: { background: '#333', color: '#fff' } })
                }
            } else if (key === 'first_name' || key === 'last_name') {
                if (!/^[a-zA-Z ]+$/.test(details[key])) {
                    err[key + '_err'] = t('please_enter_valid') + t(`${key}`).replace(/_/g, " ");
                    toast.error(t('please_enter_valid') + t(`${key}`).replace(/_/g, " "), { style: { background: '#333', color: '#fff' } })
                }
            }
            else if (key === 'phone') {
                if (!validatePhoneNumber(details[key].replaceAll('+', '').replaceAll(' ', ''))) {
                    err['phone_err'] = t('invalid_phone');
                    toast.error(t('invalid_phone'), { style: { background: '#333', color: '#fff' } })
                }
            }
        }
        // console.log(err);
        return (Object.keys(err).length > 0) ? false : true;
    }

    const validation2 = () => {
        let err = {};
        let details = {
            current_password: password.current,
            new_password: password.new,
            confirm_password: password.confirm
        }
        for (let key in details) {
            if (!details[key]) {
                err[key + '_err'] = t('please_enter') + t(`${key}`).replace(/_/g, " ");
                toast.error(t('please_enter') + t(`${key}`).replace(/_/g, " "), { style: { background: '#333', color: '#fff' } })
            } else if (key === "confirm_password") {
                var passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                if (!passwordRegExp.test(details[key])) {
                    err['password_err'] = t('strong_password');
                    toast.error(t('strong_password'), { style: { background: '#333', color: '#fff' } })
                } else if (password.new !== details[key]) {
                    err['confirm_err'] = t('password_not_match');
                    toast.error(t('password_not_match'), { style: { background: '#333', color: '#fff' } })
                }
            } else if (key === "current_password") {
                // if (details[key] !== profile.password) {
                //     err['current_err'] = "Current password is incorrect";
                //     toast.error('Current password is incorrect', { style: { background: '#333', color: '#fff' } })
                // } else
                if (password.new === details[key]) {
                    err['new_err'] = t('new_password_different');
                    toast.error(t('new_password_different'), { style: { background: '#333', color: '#fff' } })
                }
            }
        }
        // console.log(err);
        return (Object.keys(err).length > 0) ? false : true;
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (validation()) {
            setDisable(true);
            await updateDoc(doc(db, 'user', localStorage.getItem('WEE_USER_ID')), {
                first_name: profile?.first_name,
                last_name: profile?.last_name,
                email: profile?.email,
                phone_no: profile?.phone_no,
                profile_image: profile?.profile_pic ?? null
            }).then(() => {
                toast.success(t('profile_update'), { style: { background: '#333', color: '#fff' } });
                // window.location.reload();
                getProfile();
                setDisable(false);
            }).catch((error) => {
                setDisable(false);
                toast.error(error, { style: { background: '#333', color: '#fff' } })
            })
        }
    }
    // console.log(auth.currentUser)
    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        if (validation2()) {
            setDisable(true);
            const currentUser = auth.currentUser;
            // const currentPassword = promptUserForCurrentPassword();
            // const credential = EmailAuthProvider.credential(currentUser.email, currentUser.password);
            // await reauthenticateWithCredential(currentUser, credential);
            // await updatePassword(auth.currentUser, password.new);
            const credential = EmailAuthProvider.credential(currentUser.email, password?.current);
            await reauthenticateWithCredential(currentUser, credential).then(async () => {
                await updatePassword(currentUser, password.new);
                await updateDoc(doc(db, 'user', localStorage.getItem('WEE_USER_ID')), {
                    password: password.new
                }).then(() => {
                    toast.success(t('password_update'), { style: { background: '#333', color: '#fff' } });
                    // window.location.reload();
                    setPassword({
                        current: '',
                        new: '',
                        confirm: ''
                    });
                    setDisable(false);
                }).catch((error) => {
                    setDisable(false);
                    toast.error(error, { style: { background: '#333', color: '#fff' } })
                })
            }).catch((err) => {
                setDisable(false)
                if (err.code === 'auth/wrong-password') {
                    toast.error(t('current_password_incorrect'), { style: { background: '#333', color: '#fff' } });
                } else {
                    toast.error(t('something_went_wrong'), { style: { background: '#333', color: '#fff' } });
                }
            });

        }
    }

    const handleAdded = () => {
        setAddCard(false);
        toast.success(t('card_added'), { style: { background: '#333', color: '#fff' } });
        getCards();
    }
    const handleDeleteCard = async (id) => {
        await axios({
            method: "post",
            url: `${API_URL}delete-card`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                customerId,
                card_id: id
            }
        }).then(async (response) => {
            if (!response.data.success) {
                toast.error(error, { style: { background: '#333', color: '#fff' } })
            } else {
                toast.success(t('card_removed'), { style: { background: '#333', color: '#fff' } });
                getCards();
            }
        }).catch(error => {
            toast.error(error, { style: { background: '#333', color: '#fff' } })
        });
    }

    const getCards = async () => {
        let customer_id = null;
        let user = await getDoc(doc(db, "user", localStorage.getItem('WEE_USER_ID')));
        if (user?.data()?.stripe_customer_id) {
            customer_id = user?.data()?.stripe_customer_id;
        } else {
            var bodyParameter = new URLSearchParams();
            bodyParameter.append('name', user?.data()?.first_name + " " + user?.data()?.last_name);
            bodyParameter.append('email', user?.data()?.email);
            await axios({
                method: "post",
                url: `${API_URL}create-stripe-customer`,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                data: bodyParameter
            }).then(async (response) => {
                if (response.data.success) {
                    customer_id = response.data.data.customer_id;
                    await updateDoc(doc(db, "user", localStorage.getItem('WEE_USER_ID')), { stripe_customer_id: customer_id })
                }
            });
        };
        setCustomerId(customer_id);
        await axios({
            method: "post",
            url: `${API_URL}get-cards`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                customerId: customer_id
            }
        }).then(async (response) => {
            if (response.data.success) {
                setCards(response.data.data);
            }
        }).catch((err) => {
            // console.log(err)
        })
    }
    return (
        <>
            <Toaster />
            <WithdrawModal
                activeModal={withdrawModal}
                setActiveModal={() => { setWithdrawModal(false); getEarnings(); }}
                profile={profile}
            />
            <Elements stripe={stripe}>
                <AddCard
                    activeModal={addCard}
                    setActiveModal={() => setAddCard(false)}
                    customerId={customerId}
                    handleAdded={handleAdded}
                />
            </Elements>
            <div className='wt-site'>
                <Header />
                <div class="wt-site-content">
                    <section class="wt-settings-section">
                        <div class="wt-profile-heading">
                            <div class="container">
                                <h1 class="wt-profile-title">{t('settings')}</h1>
                                <p class="wt-profile-description">{t('setting_desc')}</p>
                                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    {tabs.map(el =>
                                        <li class="nav-item" role="presentation" onClick={() => !disable && setCurrentState(el.id)}>
                                            <button class={`nav-link ${el.id === currentState ? 'active' : ''}`} type="button" role="tab">{el.name}</button>
                                        </li>
                                    )}
                                    {/* <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-password-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-password" type="button" role="tab" aria-controls="pills-password"
                                        aria-selected="false">Password</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-billing-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-billing" type="button" role="tab" aria-controls="pills-billing"
                                        aria-selected="false">Billing</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-notifications-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-notifications" type="button" role="tab"
                                        aria-controls="pills-notifications" aria-selected="false">Notifications</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-paid-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-paid" type="button" role="tab" aria-controls="pills-paid"
                                        aria-selected="false">Get Paid</button>
                                </li> */}
                                </ul>
                            </div>
                        </div>
                        <div class="wt-settings-content">
                            <div class="container">
                                <div class="tab-content" id="pills-tabContent">
                                    <div class={`tab-pane fade ${currentState === 1 ? 'show active' : ''}`} id="pills-profile" role="tabpanel"
                                        aria-labelledby="pills-profile-tab">
                                        <div class="wt-settings-content__inner">
                                            <div class="row align-items-center">
                                                <div class="col col-12 col-md-6 col-lg-7">
                                                    <div class="wt-settings-content-heading">
                                                        <h3 class="wt-settings-content-title">{t('avatar')}</h3>
                                                        <p class="wt-settings-content-description">{t('avatar_desc')}</p>
                                                    </div>
                                                    <div class="wt-upload-avatar-content">
                                                        <h5 class="form-label">{t('upload_avatar')}</h5>
                                                        <div class="wt-upload-avatar__inner">
                                                            <div class="wt-upload-avatar">
                                                                {profile?.profile_pic ?
                                                                    <img src={profile?.profile_pic} alt="avatar" class="img-fluid" /> :
                                                                    <span style={{ display: 'block', backgroundColor: '#1AAA69', width: '72px', height: '72px', textAlign: 'center', borderRadius: '50%', fontSize: '35px', fontWeight: '600', paddingTop: '10px', color: 'white', marginRight: '15px' }} >{profile?.first_name?.charAt(0)}</span>}
                                                                <div class="wt-upload-avatar-btn">
                                                                    <div class="wt-upload-avatar-input">
                                                                        <input type="file" class="form-control" onChange={handleImage} accept='image/*' />
                                                                        <span>{t('upload_text')}</span>
                                                                    </div>
                                                                    <span class="wt-remove-avatar-btn" onClick={() => setProfile({ ...profile, profile_pic: null })} >{t('remove_text')}</span>
                                                                </div>
                                                            </div>
                                                            <div class="wt-choose-avata-content">
                                                                <p class="wt-choose-avata-label">{t('choose_one')}</p>
                                                                <div class="wt-choose-avata-content__inner">
                                                                    {avatars.map(el =>
                                                                        <div class="form-check" onClick={() => setProfile({ ...profile, profile_pic: el })}>
                                                                            {/* <input class="form-check-input" type="radio" name="avatar"
                                                                        id="avatar1" />
                                                                    <label class="form-check-label" for="avatar1"> */}
                                                                            <img src={el} alt="Avatar"
                                                                                class="img-fluid" />
                                                                            {/* </label> */}
                                                                        </div>)}
                                                                    {/* <div class="form-check">
                                                                    <input class="form-check-input" type="radio" name="avatar"
                                                                        id="avatar2" />
                                                                    <label class="form-check-label" for="avatar2">
                                                                        <img src="assets/images/avatar2.png" alt="Avatar"
                                                                            class="img-fluid" />
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" name="avatar"
                                                                        id="avatar3" />
                                                                    <label class="form-check-label" for="avatar3">
                                                                        <img src="assets/images/avatar3.png" alt="Avatar"
                                                                            class="img-fluid" />
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" name="avatar"
                                                                        id="avatar4" />
                                                                    <label class="form-check-label" for="avatar4">
                                                                        <img src="assets/images/avatar4.png" alt="Avatar"
                                                                            class="img-fluid" />
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" name="avatar"
                                                                        id="avatar5" />
                                                                    <label class="form-check-label" for="avatar5">
                                                                        <img src="assets/images/avatar5.png" alt="Avatar"
                                                                            class="img-fluid" />
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" name="avatar"
                                                                        id="avatar6" />
                                                                    <label class="form-check-label" for="avatar6">
                                                                        <img src="assets/images/avatar6.png" alt="Avatar"
                                                                            class="img-fluid" />
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" name="avatar"
                                                                        id="avatar7" />
                                                                    <label class="form-check-label" for="avatar7">
                                                                        <img src="assets/images/avatar7.png" alt="Avatar"
                                                                            class="img-fluid" />
                                                                    </label>
                                                                </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <p class="wt-upload-avatar-notes">Images should be at least 200px * 200px</p> */}
                                                    </div>

                                                    <div class="wt-settings-content-heading">
                                                        <h3 class="wt-settings-content-title">{t('profile')}</h3>
                                                        <p class="wt-settings-content-description">{t('profile_desc')}</p>
                                                    </div>
                                                    <div class="wt-profile-content">
                                                        <form class="row g-3">
                                                            <div class="col col-12 col-md-6">
                                                                <label for="inputFirstname" class="form-label">{t('first_name')}</label>
                                                                <input type="text" class="form-control" id="inputFirstname"
                                                                    name='first_name' onChange={handleChange} value={profile?.first_name} />
                                                            </div>
                                                            <div class="col col-12 col-md-6">
                                                                <label for="inputLastname" class="form-label">{t('last_name')}</label>
                                                                <input type="text" class="form-control" id="inputlASTname"
                                                                    name='last_name' onChange={handleChange} value={profile?.last_name} />
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label for="inputEmail" class="form-label">{t('email')}</label>
                                                                <div class="wt-input-icon-group">
                                                                    <input type="email" class="form-control disabled" id="inputEmail"
                                                                        name='email' onChange={handleChange} value={profile?.email} disabled />
                                                                    <span class="wt-input-icon">
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M4.58203 5.41797L9.9987 10.2096L15.4154 5.41797M5.6237 15.2096H14.3737C15.2942 15.2096 16.0404 14.4634 16.0404 13.543V6.45964C16.0404 5.53916 15.2942 4.79297 14.3737 4.79297H5.6237C4.70322 4.79297 3.95703 5.53916 3.95703 6.45964V13.543C3.95703 14.4634 4.70322 15.2096 5.6237 15.2096Z"
                                                                                stroke="#94A3B8" stroke-width="1.5"
                                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="col col-12 col-md-6">
                                                                <label for="inputPhoneNumber" class="form-label">{t('phone')}</label>
                                                                <div class="wt-input-icon-group">
                                                                    <PhoneInput
                                                                        country={'us'}
                                                                        value={profile.phone_no}
                                                                        onChange={phone => setProfile({ ...profile, phone_no: phone })}
                                                                    />
                                                                    {/* <span class="wt-input-icon">
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M9.79167 13.957H10.2083M7.29167 16.0404H12.7085C13.629 16.0404 14.3752 15.2942 14.3752 14.3737V5.6237C14.3752 4.70322 13.629 3.95703 12.7085 3.95703H7.29167C6.37119 3.95703 5.625 4.70322 5.625 5.6237V14.3737C5.625 15.2942 6.37119 16.0404 7.29167 16.0404Z"
                                                                                stroke="#94A3B8" stroke-width="1.5"
                                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>
                                                                    </span> */}
                                                                </div>
                                                            </div>
                                                            {/* <div class="col col-12 col-md-6">
                                                                <label for="inputState" class="form-label">Country</label>
                                                                <select id="countries" class="form-select">
                                                                    <option selected value="Albania">Albania</option>
                                                                    <option value="Afghanistan">Afghanistan</option>
                                                                </select>
                                                            </div>
                                                            <div class="col col-12 col-md-6">
                                                                <label for="inputState" class="form-label">Timezone</label>
                                                                <div class="wt-input-icon-group">
                                                                    <select id="inputState" class="form-select">
                                                                        <option selected>Eastern Standard Time (EST)</option>
                                                                        <option>Eastern Standard Time (EST)</option>
                                                                    </select>
                                                                    <span class="wt-input-icon">
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M9.9987 6.66536V9.9987L11.6654 11.6654M16.0404 9.9987C16.0404 13.3354 13.3354 16.0404 9.9987 16.0404C6.66198 16.0404 3.95703 13.3354 3.95703 9.9987C3.95703 6.66198 6.66198 3.95703 9.9987 3.95703C13.3354 3.95703 16.0404 6.66198 16.0404 9.9987Z"
                                                                                stroke="#94A3B8" stroke-width="1.5"
                                                                                stroke-linecap="round" />
                                                                        </svg>
                                                                    </span>

                                                                </div>
                                                            </div> */}
                                                            <div class="col-12">
                                                                <div class="wt-profile-form-btn">
                                                                    <button type="button"
                                                                        class="btn btn-outline-info" onClick={getProfile}>{t('cancel')}</button>
                                                                    <button type="button" class="btn btn-success" disabled={disable} onClick={handleUpdate}>{disable ? `${t('processing')}...` : t('save')}</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div class="col col-12 col-md-6 col-lg-5">
                                                    <div class="wt-avatar-img">
                                                        <img src="images/avatar-img.svg" alt="avatar-img" class="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={`tab-pane fade ${currentState === 2 ? 'active show' : ''}`} >
                                        <div class="wt-settings-content__inner">
                                            <div class="row align-items-center">
                                                <div class="col col-12 col-md-6 col-lg-7">
                                                    <div class="wt-settings-content-heading">
                                                        <h3 class="wt-settings-content-title">{t('password')}</h3>
                                                        <p class="wt-settings-content-description">{t('password_text')}</p>
                                                    </div>
                                                    <div class="wt-password-content">
                                                        <form class="row g-3">
                                                            <div class="col col-8 password">
                                                                <label for="inputPassword" class="form-label">{t('current_password')}</label>
                                                                <input type={passwordType.current} class="form-control" id="inputPassword" name="current" onChange={handleChangePassword} value={password?.current} />
                                                                <img className="eye-icon"
                                                                    onClick={() => {
                                                                        setPasswordType({ ...passwordType, current: passwordType.current === "password" ? "text" : "password" });
                                                                    }} src={passwordType.current === 'password' ? "/images/eye.svg" : "/images/eye-slash.svg"} />
                                                            </div>
                                                            <div class="col col-8 password">
                                                                <label for="inputNewPassword" class="form-label">{t('new_password')}</label>
                                                                <input type={passwordType.new} class="form-control" id="inputNewPassword" name="new" onChange={handleChangePassword} value={password?.new} />
                                                                <img className="eye-icon"
                                                                    onClick={() => {
                                                                        setPasswordType({ ...passwordType, new: passwordType.new === "password" ? "text" : "password" });
                                                                    }} src={passwordType.new === 'password' ? "/images/eye.svg" : "/images/eye-slash.svg"} />
                                                            </div>
                                                            <div class="col col-8 password">
                                                                <label for="inputNewPassword" class="form-label">{t('confirm_password')}</label>
                                                                <input type={passwordType.confirm} class="form-control" id="inputNewPassword" name="confirm" onChange={handleChangePassword} value={password?.confirm} />
                                                                <img className="eye-icon"
                                                                    onClick={() => {
                                                                        setPasswordType({ ...passwordType, confirm: passwordType.confirm === "password" ? "text" : "password" });
                                                                    }} src={passwordType.confirm === 'password' ? "/images/eye.svg" : "/images/eye-slash.svg"} />
                                                            </div>
                                                            <label class="form-note-lable">{t('atleast_char')}</label>

                                                            <div class="col-12">
                                                                <div class="wt-profile-form-btn">
                                                                    <button type="button" class="btn btn-outline-info" onClick={() => setPassword({
                                                                        current: '',
                                                                        new: '',
                                                                        confirm: ''
                                                                    })}>{t('cancel')}</button>
                                                                    <button type="button" class="btn btn-success" disabled={disable} onClick={handleUpdatePassword}>{disable ? `${t('processing')}...` : t('update')}</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div class="col col-12 col-md-6 col-lg-5">
                                                    <div class="wt-avatar-img">
                                                        <img src="images/password-image.svg" alt="avatar-img" class="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={`tab-pane fade ${currentState === 3 && localStorage.getItem("WEE_USER_TYPE") === "CUSTOMER" ? 'active show' : ''}`}>
                                        <div class="wt-settings-content__inner">
                                            <div class="row">
                                                <div class="col col-12 col-md-6 col-lg-6">
                                                    <div class="wt-profile-payment-content">
                                                        <button type="button" class="btn btn-success" onClick={() => setAddCard(true)}>
                                                            <span class="btn-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M19.75 12C19.75 12.414 19.414 12.75 19 12.75H12.75V19C12.75 19.414 12.414 19.75 12 19.75C11.586 19.75 11.25 19.414 11.25 19V12.75H5C4.586 12.75 4.25 12.414 4.25 12C4.25 11.586 4.586 11.25 5 11.25H11.25V5C11.25 4.586 11.586 4.25 12 4.25C12.414 4.25 12.75 4.586 12.75 5V11.25H19C19.414 11.25 19.75 11.586 19.75 12Z" fill="#F5F5F5" />
                                                                </svg>
                                                            </span>
                                                            <span>{t('add_new_payment_method')}</span>
                                                        </button>
                                                        <div class="wt-profile-payment-method-box">
                                                            <h6 class="wt-payment-title">{t('payment_method')}</h6>
                                                            {cards?.length > 0 ? cards?.map((el) =>
                                                                <div class="wt-profile-payment-details">
                                                                    {/* <div class="wt-payment-card-logo">
                                                                        <img class="img-fluid" src="images/Credit-Cards-img.svg" alt="credit card image" />
                                                                    </div> */}
                                                                    <div class="wt-payment-card-detail">
                                                                        <p class="wt-payment-card-detail-text">{el?.brand} in {el?.last4}, exp {String(el?.exp_month).padStart(2, '0')}/{el?.exp_year}</p>
                                                                    </div>
                                                                    <div class="wt-payment-card-action">
                                                                        {/* <a href="#" class="btn btn-outline-secondary">Edit</a> */}
                                                                        <a onClick={() => handleDeleteCard(el?.id)} class="btn btn-danger">{t('remove')}</a>
                                                                    </div>
                                                                </div>
                                                            ) : <p>{t('no_cards')}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={`tab-pane fade ${currentState === 3 && localStorage.getItem("WEE_USER_TYPE") === "HOST" ? 'active show' : ''}`} >
                                        <div class="wt-settings-content__inner">
                                            <div class="row">
                                                <div class="col col-12 col-md-12">
                                                    <div class="wt-paid-content">
                                                        <div class="wt-paid-box">
                                                            <div class="wt-paid-balance-box">
                                                                <div class="wt-balance-content">
                                                                    <h1 class="wt-paid-balance-text">MXN ${Number(profile?.my_earnings).toFixed(2)}</h1>
                                                                    <p class="wt-paid-account-type-name">{t('my_earning')}</p>
                                                                </div>
                                                                {Number(profile?.my_earnings) > 0 && <div class="wt-paid-withdraw-content">
                                                                    <button type="button" class="btn btn-success" onClick={() => setWithdrawModal(true)}>
                                                                        <span class="btn-icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M21.75 14.269C21.749 12.279 19.217 10.746 15.75 10.552V5.98999C15.749 3.40699 12.36 2.25 9 2.25C5.64 2.25 2.251 3.40599 2.25 5.98999V17.01C2.25 19.593 5.64 20.75 9 20.75C9.347 20.75 9.70301 20.718 10.057 20.683C11.351 21.406 13.178 21.75 15 21.75C18.359 21.75 21.75 20.593 21.75 18.01V14.269C21.75 14.27 21.75 14.27 21.75 14.269ZM20.25 14.271C20.25 15.329 18.005 16.512 15 16.512C11.995 16.512 9.75 15.329 9.75 14.271C9.75 13.883 10.057 13.479 10.588 13.122C10.606 13.112 10.62 13.098 10.637 13.087C11.561 12.487 13.133 12.03 15 12.03C18.005 12.03 20.25 13.213 20.25 14.271ZM14.25 9.53003C14.25 9.98103 13.854 10.358 13.477 10.621C12.086 10.786 10.885 11.175 9.98401 11.723C9.66401 11.75 9.34198 11.77 9.00098 11.77C6.17298 11.77 3.75098 10.538 3.75098 9.53003V8.47803C5.05198 9.33003 7.02898 9.73199 9.00098 9.73199C10.973 9.73199 12.95 9.33003 14.251 8.47803V9.53003H14.25ZM3.75 11.849C4.953 12.663 6.72401 13.172 8.48901 13.256C8.33701 13.577 8.25 13.915 8.25 14.27C8.25 14.27 8.25 14.27 8.25 14.271V15.479C5.758 15.288 3.75 14.189 3.75 13.271V11.849ZM9 3.75C11.581 3.75 14.25 4.58803 14.25 5.99103C14.25 7.44703 11.545 8.23199 9 8.23199C6.455 8.23199 3.75 7.44703 3.75 5.99103C3.75 4.58803 6.419 3.75 9 3.75ZM3.75 17.01V15.589C4.899 16.366 6.564 16.865 8.25 16.981V18.01C8.25 18.465 8.36 18.873 8.552 19.241C6.119 19.15 3.75 18.331 3.75 17.01ZM15 20.25C13.381 20.25 11.728 19.92 10.721 19.328C10.122 18.976 9.75098 18.532 9.75098 18.01V16.676C10.969 17.502 12.835 18.012 15.001 18.012C17.167 18.012 19.033 17.502 20.251 16.676V18.01C20.25 19.412 17.581 20.25 15 20.25Z" fill="#F5F5F5" />
                                                                            </svg>
                                                                        </span>
                                                                        <span>{t('withdraw_now')}</span>
                                                                    </button>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                        <div class="wt-withdraw-history-section">
                                                            <div class="wt-withdraw-history-header">
                                                                <h3 class="wt-withdraw-history-title">{t('recent_withdrawal')}</h3>
                                                                {/* <button type="button" class="btn btn-outline-info">
                                                                    <span>Jul 21 - Aug 21 2023</span>
                                                                    <span class="btn-icon">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M18 3.75H16.75V3C16.75 2.586 16.414 2.25 16 2.25C15.586 2.25 15.25 2.586 15.25 3V3.75H8.75V3C8.75 2.586 8.414 2.25 8 2.25C7.586 2.25 7.25 2.586 7.25 3V3.75H6C3.582 3.75 2.25 5.082 2.25 7.5V18C2.25 20.418 3.582 21.75 6 21.75H18C20.418 21.75 21.75 20.418 21.75 18V7.5C21.75 5.082 20.418 3.75 18 3.75ZM6 5.25H7.25V6C7.25 6.414 7.586 6.75 8 6.75C8.414 6.75 8.75 6.414 8.75 6V5.25H15.25V6C15.25 6.414 15.586 6.75 16 6.75C16.414 6.75 16.75 6.414 16.75 6V5.25H18C19.577 5.25 20.25 5.923 20.25 7.5V8.25H3.75V7.5C3.75 5.923 4.423 5.25 6 5.25ZM18 20.25H6C4.423 20.25 3.75 19.577 3.75 18V9.75H20.25V18C20.25 19.577 19.577 20.25 18 20.25ZM9.02002 13C9.02002 13.552 8.57302 14 8.02002 14C7.46802 14 7.01489 13.552 7.01489 13C7.01489 12.448 7.45801 12 8.01001 12H8.02002C8.57202 12 9.02002 12.448 9.02002 13ZM13.02 13C13.02 13.552 12.573 14 12.02 14C11.468 14 11.0149 13.552 11.0149 13C11.0149 12.448 11.458 12 12.01 12H12.02C12.572 12 13.02 12.448 13.02 13ZM17.02 13C17.02 13.552 16.573 14 16.02 14C15.468 14 15.0149 13.552 15.0149 13C15.0149 12.448 15.458 12 16.01 12H16.02C16.572 12 17.02 12.448 17.02 13ZM9.02002 17C9.02002 17.552 8.57302 18 8.02002 18C7.46802 18 7.01489 17.552 7.01489 17C7.01489 16.448 7.45801 16 8.01001 16H8.02002C8.57202 16 9.02002 16.448 9.02002 17ZM13.02 17C13.02 17.552 12.573 18 12.02 18C11.468 18 11.0149 17.552 11.0149 17C11.0149 16.448 11.458 16 12.01 16H12.02C12.572 16 13.02 16.448 13.02 17ZM17.02 17C17.02 17.552 16.573 18 16.02 18C15.468 18 15.0149 17.552 15.0149 17C15.0149 16.448 15.458 16 16.01 16H16.02C16.572 16 17.02 16.448 17.02 17Z" fill="#F5F5F5" />
                                                                        </svg>
                                                                    </span>
                                                                </button> */}
                                                            </div>
                                                            <div class="wt-withdraw-history-body">
                                                                <ul class="wt-withdraw-history-list">
                                                                    {earnings?.map((el) =>
                                                                        <li class="wt-withdraw-history-item">
                                                                            <div class="wt-withdraw-history-user-info">
                                                                                {/* <div>
                                                                                    <div class="wt-withdraw-history-user-img">
                                                                                        <img src={el?.images} class="img-fluid" alt="" />
                                                                                    </div>
                                                                                </div> */}
                                                                                <div class="wt-withdraw-history-user-text">
                                                                                    <h6 class="wt-withdraw-history-user-name">{t('requested_amount')}</h6>
                                                                                    {/* <p class="wt-withdraw-user-id">{el?.id}</p> */}
                                                                                    <div class="wt-withdraw-history-datentime">
                                                                                        <span class="wt-withdraw-history-date">{moment(new Date(el?.created_date).getTime()).format("Do MMM YYYY")}</span>
                                                                                        {/* <span class="wt-withdraw-history-time">{moment(new Date(el?.created_date?.seconds * 1000)).format("h:mm a")}</span> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="wt-withdraw-history-price-info">
                                                                                <h5 class="wt-withdraw-history-price">MXN ${Number(el?.withdraw_amount).toFixed(2)}</h5>
                                                                                <label class={`wt-withdraw-history-process-label ${el?.status === true ? "text-success" : el?.status === false ? "text-danger" : "text-warning"}`}>{el?.status === true ? t('accepted') : el?.status === false ? t('rejected') : t('pending')}</label>
                                                                            </div>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={`tab-pane fade ${currentState === 4 ? 'active show' : ''}`} >
                                        <div class="wt-settings-content__inner">
                                            <div class="row align-items-center">
                                                <div class="col col-12 col-md-6 col-lg-7">
                                                    <div class="wt-settings-content-heading">
                                                        <h3 class="wt-settings-content-title">{t('languages')}</h3>
                                                        <p class="wt-settings-content-description">{t('language_desc')}</p>
                                                    </div>
                                                    <div class="wt-password-content">
                                                        <input id='english' type='radio' name='lang' onChange={() => changeLanguage('en')} checked={localStorage.getItem('WEE_USER_LAN') === 'EN'} style={{ margin: '10px' }} />
                                                        <label htmlFor='english' >English</label> <br />
                                                        <input id='spanish' type='radio' name='lang' onChange={() => changeLanguage('es')} checked={!localStorage.getItem('WEE_USER_LAN') || localStorage.getItem('WEE_USER_LAN') === 'ES'} style={{ margin: '10px' }} />
                                                        <label htmlFor='spanish' >Spanish</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Setting