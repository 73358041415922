import React, { useEffect, useState } from 'react'
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../FirebaseConfig'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from 'react-router-dom'
import FeedbackModal from './FeedbackModal'
import { useTranslation } from 'react-i18next'

const MyOrders = () => {
    const { t } = useTranslation();
    const [orders, setOrders] = useState({
        past: [],
        upcoming: [],
    });
    function convertTimeToDate(date, timeString) {
        // console.log(date, timeString)
        const [hours, minutes] = timeString.split(':').map(Number);
        const time = new Date(date);
        time.setHours(hours, minutes, 0, 0); // Set hours and minutes, seconds and milliseconds to 0
        return time;
    }
    const [selected, setSelected] = useState({});
    const [FeedBackModal, setFeedBackModal] = useState(false);
    const [Feedback, setFeedback] = useState({
        rating: 0,
        text: '',
        about: ''
    })
    const nav = useNavigate();
    const loadingArr = { up: [1, 2, 3, 4], past: [1, 2, 3, 4, 5, 6] }
    const [loading, setLoading] = useState(true);
    var monthName = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
    const getOrders = async () => {
        await getDocs(query(collection(db, 'my_booking'), where(localStorage.getItem('WEE_USER_TYPE') === "CUSTOMER" ? 'book_user_id' : 'user_id', '==', localStorage.getItem('WEE_USER_ID'), where('post_type', '==', 3))))
            .then(async querysnapshot => {
                if (querysnapshot.docs.length > 0) {
                    let ord = [];
                    let past = [];
                    let upcoming = [];
                    await querysnapshot.docs.map(async (docs, index) => {
                        ord.push({ ...docs.data(), id: docs.id });
                        if (index === querysnapshot.docs.length - 1) {
                            await Promise.all(ord.map(async elem => {
                                let docRef = await getDoc(doc(db, "user", elem.user_id));
                                let feedback = await getDocs(query(collection(db, 'customer_feedback'), where('my_booking_id', '==', elem.id)));

                                // console.log(parseInt(elem.availability_end_date.seconds * 1000) >= Date.now(), parseInt(elem.availability_start_date.seconds * 1000) <= Date.now(), Date.now());
                                // console.log("------------>", new Date(elem.check_out_date));
                                // console.log("============>", elem?.check_out_date?.toDate());
                                // console.log("++++++++++++++>", new Date());
                                if ((convertTimeToDate(elem?.check_out_date?.toDate(), elem?.check_out_time) >= new Date())) {
                                    // console.log("asda");
                                    upcoming.push({
                                        ...elem, host: docRef.data(),
                                        feedback: feedback?.docs?.length > 0 ? {
                                            rating: feedback?.docs[0]?.data().rating,
                                            text: feedback?.docs[0]?.data().description,
                                            about: feedback?.docs[0]?.data().feedback_about
                                        } : {
                                            rating: 0,
                                            text: ""
                                        }
                                    })
                                } else {
                                    // console.log("Asdas");
                                    past.push({
                                        ...elem, host: docRef.data(),
                                        feedback: feedback?.docs?.length > 0 ? {
                                            rating: feedback?.docs[0]?.data().rating,
                                            text: feedback?.docs[0]?.data().description,
                                            about: feedback?.docs[0]?.data().feedback_about
                                        } : {
                                            rating: 0,
                                            text: "",
                                            about: ""
                                        }
                                    })
                                }
                            }));
                            // console.log("up", upcoming, past);
                            setOrders({
                                past, upcoming
                            });
                            setLoading(false)
                        }
                    });
                } else {
                    setLoading(false)
                }
            });
    };

    useEffect(() => {
        getOrders();
    }, []);

    const validate = () => {
        let err = {};
        if (!Feedback.about) {
            err['about_err'] = 'Please select your experience';
            toast.error('Please select your experience', { style: { background: '#333', color: '#fff' } })
        }

        if (Feedback.rating === 0) {
            err['rating_err'] = 'Please select rating';
            toast.error('Please select rating', { style: { background: '#333', color: '#fff' } })
        }

        if (!Feedback.text.trim()) {
            err['text_err'] = 'Please enter feedback';
            toast.error('Please enter feedback', { style: { background: '#333', color: '#fff' } })
        }
        return Object.keys(err).length === 0
    }

    function tConvert(time) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }

    const handleSave = async () => {
        if (validate()) {
            try {
                const user = await getDoc(doc(db, "user", localStorage.getItem('WEE_USER_ID')));
                const feedbackData = {
                    created_date: new Date().toISOString(),
                    update_date: new Date().toISOString(),
                    post_id: selected.post_id,
                    feedback_about: Feedback.about,
                    user_id: selected.book_user_id,
                    host_id: selected.user_id,
                    user_profile: user?.data()?.profile_image ?? "",
                    post_image: selected.images,
                    user_name: user.data().first_name + " " + user.data().last_name,//
                    post_name: selected.title,
                    rating: Feedback.rating,
                    description: Feedback.text,
                    my_booking_id: selected.id,
                    timestamp: Date.now()
                };
                await addDoc(collection(db, 'customer_feedback'), feedbackData).then(async (docRef) => {
                    await updateDoc(doc(db, 'customer_feedback', docRef.id), { id: docRef.id });
                });
                //Reset feedback state
                setFeedback({
                    rating: 0,
                    feedback: '',
                    about: ''
                });
                // Close feedback modal
                setFeedBackModal(false);
                setSelected({});
                setLoading(true);
                getOrders();
                // Show success message or perform any other actions
            } catch (error) {
                console.error('Error saving feedback:', error);
                // Show error message or perform any other error handling
            }
        }
    };


    return (
        <>
            <FeedbackModal
                activeModal={FeedBackModal}
                setActiveModal={() => setFeedBackModal(false)}
                Feedback={Feedback}
                setFeedback={(val) => { setFeedback({ ...Feedback, ...val }) }}
                handleSave={handleSave}
                selected={selected}
            />
            <div className='wt-site'>
                <Header />
                <main class="wt-site-content">
                    <section class="wt-my-trip-section">
                        <div class="wt-section-full-header">
                            <div class="container">
                                <h2 class="wt-section-title">{t('my_trips')}</h2>
                            </div>
                        </div>
                        <div class="wt-upcoming-order-content">
                            <div class="container">
                                <div class="wt-my-trip-head">
                                    <h3 class="wt-my-trip-head-title">{t('upcoming_trip')}</h3>
                                    {/* {!loading && <a href="#" class="wt-my-trip-head-link">View all</a>} */}
                                </div>
                                {!loading ?
                                    <div class="wt-upcoming-order-content__inner">
                                        {orders.upcoming.length > 0 ?
                                            <div class="row">
                                                {orders.upcoming.map(elem =>
                                                    <div class="col col-12 col-md-6 col-lg-6">
                                                        <div class="wt-upcoming-order-card">
                                                            <div class="wt-upcoming-order-card-img">
                                                                <img src={elem?.images} alt="upcoming-order-img"
                                                                    class="img-fluid" />
                                                            </div>
                                                            <div class="wt-upcoming-order-card-body">
                                                                <div class="wt-order-card-head">
                                                                    <h6 class="wt-upcoming-order-card-subtitle">{elem?.description?.length > 30 ? elem?.description?.substring(0, 30) + '...' : elem?.description}</h6>
                                                                    <a onClick={() => nav('/trip-details', { state: elem })} style={{ cursor: "pointer" }} class="wt-upcoming-order-card-title">{elem?.title}</a>
                                                                </div>
                                                                {/* <div class="wt-order-card-content"> */}
                                                                {elem?.check_out_date ?
                                                                    (<>
                                                                    {/* {console.log("------------------>",elem?.check_out_date.toDate())} */}
                                                                        {/* <p class="wt-upcoming-order-date">{(monthName[elem?.check_out_date.toDate().getMonth()]).slice(0,3)} {elem?.check_out_date.toDate().getDate()} <span>{elem?.check_out_date.toDate().getFullYear()}</span></p> */}
                                                                        <p class="wt-upcoming-order-date"><small>{(monthName[elem?.check_in_date.toDate().getMonth()]).slice(0, 3)} {elem?.check_in_date.toDate().getDate()} {elem?.check_out_date.toDate().getFullYear()} ({tConvert(elem?.check_in_time)}) -<br /> {(monthName[elem?.check_out_date.toDate().getMonth()]).slice(0, 3)} {elem?.check_out_date.toDate().getDate()} {elem?.check_out_date.toDate().getFullYear()} ({tConvert(elem?.check_out_time)})</small></p>
                                                                    </>)
                                                                    :
                                                                    (<>
                                                                        <p class="wt-upcoming-order-date">{(monthName[new Date(elem?.book_date).getMonth()]).slice(0, 3)} {new Date(elem?.book_date).getDate()} <span>{new Date(elem?.book_date).getFullYear()}</span></p>
                                                                    </>)}

                                                                {/* <p class="wt-upcoming-order-date">{(monthName[new Date(new Date(elem?.availability_start_date?.seconds) * 1000).getMonth()]).slice(0, 3)} {new Date(new Date(elem?.availability_start_date?.seconds) * 1000).getDate()} <span>{new Date(new Date(elem?.availability_start_date?.seconds) * 1000).getFullYear()}</span>- {(monthName[new Date(new Date(elem?.availability_end_date?.seconds) * 1000).getMonth()]).slice(0, 3)} {new Date(new Date(elem?.availability_end_date?.seconds) * 1000).getDate()} <span>{new Date(new Date(elem?.availability_end_date?.seconds) * 1000).getFullYear()}</span></p> */}
                                                                {/* </div> */}
                                                                <div class="wt-order-card-footer">
                                                                    <p class="wt-upcoming-order-user">{t('hosted_by')} {elem?.host?.first_name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div> : <p>{t('upcoming_trip_text')}</p>}
                                    </div> :
                                    <div class="wt-upcoming-order-content__inner">
                                        <div class="row">
                                            {loadingArr.up.map((_, index) =>
                                                <div class="col col-12 col-md-6 col-lg-6">
                                                    <div class="wt-upcoming-order-card">
                                                        <div class="wt-upcoming-order-card-img">
                                                            <Skeleton count={1} className='sk-image-2' />
                                                        </div>
                                                        <div class="wt-upcoming-order-card-body">
                                                            <div class="wt-order-card-head">
                                                                <Skeleton count={1} className='sk-desc-2' />
                                                                <Skeleton count={1} className='sk-label' />
                                                            </div>
                                                            <div class="wt-order-card-content">
                                                                <Skeleton count={2} className='sk-date' />
                                                            </div>
                                                            <div class="wt-order-card-footer">
                                                                <Skeleton count={1} className='sk-label' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)}
                                        </div>
                                    </div>}
                            </div>
                        </div>

                        <div class="wt-cancel-order-content">
                            <div class="container">
                                <div class="wt-my-trip-head">
                                    <h3 class="wt-my-trip-head-title">{t('past_trip')}</h3>
                                    {/* {!loading && <a href="#" class="wt-my-trip-head-link">View all</a>} */}
                                </div>
                                {!loading ?
                                    <div class="wt-cancel-order-content__inner">
                                        {orders.past.length > 0 ?
                                            <div class="row">
                                                {orders.past.map(elem =>
                                                    <div class="col col-12 col-md-6 col-lg-4">
                                                        <div class="wt-cancel-order-card">
                                                            <div class="wt-cancel-order-card-img">
                                                                <img src={elem.images} alt="cancelled-order-img"
                                                                    class="img-fluid" />
                                                            </div>
                                                            <div class="wt-cancel-order-card-body">
                                                                <div class="wt-order-card-head">
                                                                    <h6 class="wt-upcoming-order-card-subtitle">{elem?.description?.length > 30 ? elem?.description?.substring(0, 30) + '...' : elem?.description}</h6>
                                                                    <a onClick={() => nav('/trip-details', { state: elem })} style={{ cursor: "pointer" }} class="wt-upcoming-order-card-title">{elem.title}</a>
                                                                    <p class="wt-upcoming-order-user">{t('hosted_by')} {elem.host.first_name}</p>
                                                                    {localStorage.getItem('WEE_USER_TYPE') === 'HOST' ? (<></>) : (<button type="button" onClick={() => { setSelected(elem); setFeedBackModal(true); }} className="w-100 btn btn-success mt-2"><p className='m-auto'>Give Feedback</p></button>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div> : <p style={{ marginBottom: '100px' }}>{t('past_trip_text')}</p>}
                                    </div> :
                                    <div class="wt-cancel-order-content__inner">
                                        <div className='row'>
                                            {loadingArr.past.map((_, index) =>
                                                <div class="col col-12 col-md-6 col-lg-4">
                                                    <div class="wt-cancel-order-card">
                                                        <div class="wt-cancel-order-card-img">
                                                            <Skeleton count={1} className='sk-image-3' />
                                                        </div>
                                                        <div class="wt-cancel-order-card-body">
                                                            <div class="wt-order-card-head">
                                                                <Skeleton count={1} className='sk-desc-2' />
                                                                <Skeleton count={1} className='sk-label' />
                                                                <Skeleton count={1} className='sk-label' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        </>
    )
}

export default MyOrders