import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

const FilterModal = ({ activeModal, setActiveModal, filtered, setFiltered, handleApply, handleClear }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState([1000, 50000]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const optionsArray3 = [
        { label: "Hot water", value: "Hot water" },
        { label: "Watermark", value: "Watermark" },
        { label: "Air Conditioning", value: "Air Conditioning" },
        { label: "Fans", value: "Fans" },
        { label: "Heating", value: "Heating" },
        { label: "Wi-Fi", value: "Wi-Fi" },
        { label: "Offered", value: "Offered" },
        { label: "USB Socket", value: "USB Socket" },
        { label: "TV", value: "TV" }
    ];

    const handleRangeChange = (newValue) => {
        setFiltered({
            ...filtered,
            range: newValue
        });
    };
    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            // setSelectedOptions(prevOptions => [...prevOptions, event.target.value]);
            let arr = filtered?.amenities;
            arr.push(event.target.value);
            setFiltered({
                ...filtered
                , amenities: arr
            })
        } else {
            // setSelectedOptions(prevOptions => prevOptions.filter(option => option !== event.target.value));
            let arr = filtered?.amenities;
            arr = arr.filter((option => option !== event.target.value));
            setFiltered({
                ...filtered
                , amenities: arr
            })
        }
    }

    return (
        <Modal
            className="modal fade wt-confirm-modal"
            id="confirmGuestModal"
            show={activeModal}
            onHide={setActiveModal}
            backdrop="static"
            keyboard={false}
            size='lr'
        >
            {/* <Modal.Header>
                <div class="wt-confirm-pay-guest-area ">
                    <button type="button" class="btn-close" onClick={setActiveModal}>
                        <img src='images/Button-close-X.svg' alt='close' />
                    </button>
                    <h3 className='wt-confirm-pay-modal-title'>Filters</h3>
                </div>
            </Modal.Header> */}
            <Modal.Body className='position-relative'>
                <h3 style={{ textAlign: 'center' }}>{t('filter')}</h3>
                <div class="wt-confirm-pay-guest-area " className='position-absolute' style={{ left: 10, top: 10 }}>
                    <button type="button" class="btn-close" onClick={setActiveModal}>
                        <img src='images/Button-close-X.svg' alt='close' />
                    </button>
                </div>
                {/* <div class="wt-confirm-pay-guest-area d-flex w-100  ">
                </div> */}
                <div className="body-container d-flex flex-column">
                    <h3 className='wt-confirm-pay-modal-title text-start'>{t('price_range')}</h3>
                    <RangeSlider className='mb-3' min={1000} max={50000} value={filtered?.range} onInput={handleRangeChange} />
                    <div className="d-flex justify-content-between">
                        <input style={{ width: '180px', height: '40px', borderRadius: '10px', marginLeft: '4px' }} type='text' value={filtered?.range[0]} readOnly />
                        <span><strong>to</strong></span>
                        <input style={{ width: '180px', height: '40px', borderRadius: '10px', marginRight: '4px' }} type='text' value={filtered?.range[1]} readOnly />
                    </div>
                    <hr />
                    <div className="amenities d-flex flex-column">
                        <h3 className='wt-confirm-pay-modal-title text-start'>{t('amenities')}</h3>
                        <div className="checkboxmain" style={{ width: "100%", display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', gap: '10px' }}>
                            {optionsArray3.map((option, index) => (
                                <div class="form-check" key={index}>
                                    <input class="form-check-input" type="checkbox" checked={filtered?.amenities?.includes(option.value)} value={option.value} onChange={handleCheckboxChange} id={`checkbox-${index}`} />
                                    <label class="form-check-label" for={`checkbox-${index}`}>
                                        {option.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div class="d-flex flex-row justify-content-between w-100 align-items-center">
                    <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleClear}>{t('clear_all')}</a>
                    <button type="button" class="btn btn-success" onClick={handleApply} >{t('apply')}</button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default FilterModal
