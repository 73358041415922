import React, { useEffect, useState } from 'react'
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'
import { useLocation, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Saved = () => {
    const [loading, setLoading] = useState(true);
    const nav = useNavigate();
    const [items, setItems] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        getSavedItems();
    }, []);

    const getSavedItems = async () => {
        await getDocs(query(collection(db, 'post')
            , where('saved_by', 'array-contains', localStorage.getItem("WEE_USER_ID"))
        ))
            .then(async querysnapshot => {
                let arr = [];
                if (querysnapshot.docs.length > 0) {
                    await querysnapshot.docs.map(async (doc, index) => {
                        arr.push({ id: doc.id, ...doc.data() })
                        if (index === querysnapshot.docs.length - 1 && arr.length == 0) {
                            setLoading(false)
                        }
                    });
                    await Promise.all(arr.map((elem, index) => {
                        getDocs(query(collection(db, 'customer_feedback'), where('post_id', '==', elem.id))).then(querysnapshot2 => {
                            // console.log('checks')
                            let total = querysnapshot2.docs.length;
                            let rate = 0;
                            querysnapshot2.docs.map(doc => {
                                rate += doc.data().rating;
                            });
                            arr = arr.map(elems => {
                                if (elems.id === elem.id) {
                                    return { ...elems, reviews: total, rating: isNaN((rate / total).toFixed(1)) ? 0.0 : (rate / total).toFixed(1) }
                                } else {
                                    return elems
                                }
                            })
                            if (index === arr.length - 1) {
                                // console.log(arr);
                                setItems(arr);
                                setLoading(false);
                            }
                        });
                    }));
                } else {
                    setItems([]);
                    setLoading(false);
                }
            })
    }

    const handleSave = async (elem) => {
        // console.log(elem?.id);
        let arr = elem?.saved_by ?? [];
        if (arr.includes(localStorage.getItem("WEE_USER_ID"))) {
            arr = arr.filter((a) => a !== localStorage.getItem("WEE_USER_ID"))
        } else {
            arr.push(localStorage.getItem("WEE_USER_ID"))
        };
        let result = items?.filter((el) => el?.id !== elem?.id)
        setItems(result);
        await updateDoc(doc(db, 'post', elem?.id), {
            saved_by: arr,
        }).then(() => {
            toast.success(!arr?.includes(localStorage.getItem("WEE_USER_ID")) ? `Removed from Saved` : `${elem?.title} Saved Successfully`, { style: { background: '#333', color: '#fff' } });
        });
    }

    const selectedPost = (elem) => {
        let result = {
            ...elem,
            // ...(({ start_date, end_date, ...obj }) => obj)(details),
            start_date:  moment(new Date(elem?.availability_start_date?.seconds * 1000)).format('YYYY-MM-DD'),
            end_date:  moment(new Date(elem?.availability_end_date?.seconds * 1000)).format('YYYY-MM-DD'),
        };
        nav('/accomodation-detail', { state: result });
        window.scrollTo(0, 0);
    }
    return (
        <div className='wt-site'>
            <Header />
            <Toaster />
            <main className="wt-site-content">
                { /* Landing Section Start */}
                <section className="wt-landing-section wt-accommodation-section mt-0">
                    <div className="container">
                        <div className="wt-landing-search-box wt-saved">
                            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.3346 3.5H10.668C7.44397 3.5 5.66797 5.276 5.66797 8.5V28.5C5.66797 28.8573 5.85867 29.1866 6.16667 29.3639C6.32133 29.4546 6.49464 29.5 6.66797 29.5C6.83864 29.5 7.01073 29.456 7.16406 29.368L16.0013 24.3174L24.8385 29.3667C25.1492 29.544 25.5306 29.5426 25.8359 29.3626C26.1439 29.184 26.3346 28.8547 26.3346 28.4987V8.4987C26.3346 5.27603 24.5586 3.5 21.3346 3.5ZM24.3346 26.776L16.4974 22.2987C16.1907 22.1227 15.8119 22.1227 15.5052 22.2987L7.66797 26.7773V8.5C7.66797 6.39733 8.5653 5.5 10.668 5.5H21.3346C23.4373 5.5 24.3346 6.39733 24.3346 8.5V26.776ZM18.0693 8.83333C18.0627 8.83333 18.0574 8.83333 18.0521 8.83333C17.1708 8.83333 16.4933 9.17468 16.0013 9.61735C15.5066 9.17202 14.8693 8.848 13.9333 8.83333C12.8133 8.83867 11.8732 9.26537 11.2158 10.068C10.4398 11.0174 10.1561 12.3893 10.4414 13.8333C11.1414 17.384 15.4121 19.3321 15.5921 19.4134C15.7228 19.4707 15.8613 19.5 16.0013 19.5C16.1413 19.5 16.2798 19.4707 16.4105 19.4134C16.5918 19.3321 20.8612 17.384 21.5599 13.8333C21.8452 12.3893 21.5641 11.0187 20.7881 10.0693C20.1321 9.26534 19.192 8.83867 18.0693 8.83333ZM19.5986 13.4453C19.2026 15.4506 16.9413 16.8681 16.0013 17.3787C15.0613 16.8681 12.8 15.4506 12.404 13.4453C12.2373 12.6026 12.3733 11.8133 12.7653 11.3346C13.04 11 13.4253 10.836 13.9453 10.8333C13.9466 10.8333 13.9479 10.8333 13.9505 10.8333C14.7225 10.8333 15.028 11.6014 15.056 11.6774C15.192 12.0787 15.5679 12.344 15.9919 12.3493C15.9959 12.3493 15.9999 12.3493 16.0052 12.3493C16.4225 12.3493 16.8013 12.0813 16.9453 11.688C16.9773 11.6 17.2827 10.832 18.0547 10.832C18.0574 10.832 18.0586 10.832 18.0599 10.832C18.5812 10.8347 18.9679 10.9987 19.2412 11.3333C19.6305 11.8133 19.764 12.6026 19.5986 13.4453Z" fill="#F5F5F5" />
                            </svg>
                            <span>{t('saved_items')} </span>
                        </div>
                    </div>
                </section>
                { /* Landing Section End */}

                { /* Principales Section Start */}
                <section className="wt-accommodation-result-section">
                    <div className="container">
                        {!loading ?
                            <div className="wt-accommodation-card-content">
                                <div className="row">
                                    {items.length > 0 ? items.map(elem =>
                                        <div className="col col-12 col-md-4 col-lg-3">
                                            <div className="wt-accommodation-card">
                                                <div className="wt-accommodation-card-header">
                                                    <img src={elem?.images} alt="accommodation-slide-img" className="img-fluid" style={{ height: '300px', width: '275px', objectFit: 'cover', borderRadius: '8px', }} />
                                                    <span className={`wt-accommodation-like-btn ${!elem?.saved_by?.includes(localStorage.getItem("WEE_USER_ID")) ? 'active' : ''}`} onClick={() => handleSave(elem)}>
                                                        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.9921 3.64436C9.05937 1.48008 5.83645 0.897898 3.4149 2.87972C0.993354 4.86155 0.652433 8.17507 2.55409 10.519L10.9921 18.3334L19.4301 10.519C21.3317 8.17507 21.0324 4.8407 18.5693 2.87972C16.1061 0.918745 12.9248 1.48008 10.9921 3.64436Z" fill="#FDA4AF" stroke="#F43F5E" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div className="wt-accommodation-card-body">
                                                    <label className="wt-accommodation-card-label">{elem?.description?.length > 30 ? elem?.description?.substring(0, 30) + '...' : elem?.description}</label>
                                                    <a style={{ cursor: 'pointer' }} onClick={() => selectedPost(elem)} className="wt-accommodation-card-link">{elem?.title}</a>
                                                    <p className="wt-accommodation-card-rating">
                                                        <span>{elem?.rating}</span>
                                                        <span>
                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.04167 0.958313L8.5 5.54165H13.0833L9.33333 8.45831L10.5833 13.0416L7.04167 10.125L3.5 13.0416L4.75 8.45831L1 5.54165H5.58333L7.04167 0.958313Z" fill="#FCD34D" stroke="#F59E0B" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                        <span>({elem?.reviews} {t('reviews')})</span>
                                                    </p>
                                                    <p className="wt-accommodation-card-price">MXN ${elem?.post_type === 1 ? elem?.price_per_room : elem?.post_type === 2 ? elem?.price_for_day : elem?.price_per_hour} <span>/noche</span></p>
                                                </div>
                                            </div>
                                        </div>) : <span>{t('no_saved')}</span>}
                                </div>
                            </div> :
                            <div className="wt-accommodation-card-content">
                                <div className="row">
                                    <div className="col col-12 col-md-4 col-lg-3">
                                        <div className="wt-accommodation-card">
                                            <div className="wt-accommodation-card-header">
                                                <Skeleton className='sk-image' count={1} />
                                            </div>
                                            <div className="wt-accommodation-card-body">
                                                <Skeleton count={1} className='sk-desc' />
                                                <Skeleton count={2} className='sk-title' />
                                                <Skeleton count={1} className='sk-price' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-4 col-lg-3">
                                        <div className="wt-accommodation-card">
                                            <div className="wt-accommodation-card-header">
                                                <Skeleton className='sk-image' count={1} />
                                            </div>
                                            <div className="wt-accommodation-card-body">
                                                <Skeleton count={1} className='sk-desc' />
                                                <Skeleton count={2} className='sk-title' />
                                                <Skeleton count={1} className='sk-price' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-4 col-lg-3">
                                        <div className="wt-accommodation-card">
                                            <div className="wt-accommodation-card-header">
                                                <Skeleton className='sk-image' count={1} />
                                            </div>
                                            <div className="wt-accommodation-card-body">
                                                <Skeleton count={1} className='sk-desc' />
                                                <Skeleton count={2} className='sk-title' />
                                                <Skeleton count={1} className='sk-price' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-4 col-lg-3">
                                        <div className="wt-accommodation-card">
                                            <div className="wt-accommodation-card-header">
                                                <Skeleton className='sk-image' count={1} />
                                            </div>
                                            <div className="wt-accommodation-card-body">
                                                <Skeleton count={1} className='sk-desc' />
                                                <Skeleton count={2} className='sk-title' />
                                                <Skeleton count={1} className='sk-price' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                        {/* {searched.length > 0 ? <div className="wt-site-pagination">
                            <nav aria-label="Page navigation">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link disabled" href="#" aria-label="Previous">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.625 4.375L4.875 6L6.625 7.625" stroke="#0E1826" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li className="page-item"><a className="page-link active" href="#">1</a></li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.375 4.375L7.125 6L5.375 7.625" stroke="#0E1826" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div> : ''} */}
                    </div>
                </section>
                { /* Principales Section End */}

            </main>
            <Footer />
        </div>
    )
}

export default Saved