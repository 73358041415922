import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Accomodations from './pages/Accomodations';
import AccomodationDetail from './pages/AccomodationDetail';
import HostListing from './pages/HostListing';
import AddVilla from './pages/AddVilla';
import ConfirmPayment from './pages/ConfirmPayment';
import ForgotPassword from './pages/ForgotPassword';
import Chat from './pages/Chat';
import MyAccount from './pages/MyAccount';
import Notifications from './pages/Notifications';
import MyTrips from './pages/MyTrips';
import Setting from './pages/Setting';
import AddCar from './pages/AddCar';
import AddYacht from './pages/AddYacht';
import TripDetails from './pages/TripDetails';
import Saved from './pages/Saved';
import StripeSetup from './pages/StripeSetup';
import Chat2 from './pages/Chat2';
import Payment from './pages/Payment';

const Routing = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/accomodations' element={<Accomodations />} />
            <Route path='/accomodation-detail' element={<AccomodationDetail />} />
            <Route path='/properties-list' element={<HostListing />} />
            <Route path='/add-villa' element={<AddVilla />} />
            <Route path='/add-car' element={<AddCar />} />
            <Route path='/add-yacht' element={<AddYacht />} />
            <Route path='/confirm-payment' element={<ConfirmPayment />} />
            <Route path='/messages' element={<Chat2 />} />
            <Route path='/my-account' element={<MyAccount />} />
            <Route path='/setting' element={<Setting />} />
            <Route path='/my-trips' element={<MyTrips />} />
            <Route path='/trip-details' element={<TripDetails />} />
            <Route path='/notifications' element={<Notifications />} />
            <Route path='/saved-items' element={<Saved />} />
            <Route path='/stripe-setup/:id' element={<StripeSetup />} />
            <Route path='/confirm' element={<Payment />} />
        </Routes>
    )
}

export default Routing