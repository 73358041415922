import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import toast, { Toaster } from 'react-hot-toast';
import { auth, db, signInWithGooglePopup } from '../FirebaseConfig';
import { addDoc, collection, getDocs, where, query, updateDoc, doc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import moment from 'moment/moment';
import axios from 'axios';
import { API_URL } from '../BaseURL';
import PhoneNumber from 'google-libphonenumber';
import ImageSlider from './ImageSlider';
import { useTranslation } from 'react-i18next';
const Signup = () => {
    const [details, setDetails] = useState({
        first_name: '',
        last_name: '',
        password: '',
        email: '',
        phonenumber: '',
        terms: false
    });
    const phoneUtil = PhoneNumber.PhoneNumberUtil.getInstance();
    const [disable, setDisable] = useState(false);
    const [disable2, setDisable2] = useState(false);
    const nav = useNavigate();
    const [passwordType, setPasswordType] = useState("password");
    const { t } = useTranslation();

    const handleChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value?.trim()?.length > 0 ? e.target.value : "" });
    }

    function validatePhoneNumber(phoneNumber) {
        // console.log(phoneNumber)
        try {
            const parsedNumber = phoneUtil.parse(`+${phoneNumber}`);
            const isValid = phoneUtil.isValidNumber(parsedNumber);
            return isValid;
        } catch (error) {
            console.error('Error parsing phone number:', error);
            return null;
        }
    }

    const handlePhoneNumberChange = (val, countryData) => {
        const numericValue = val.replace(/[^0-9]/g, ""); // Extract numeric value only
        // const countryCodePrefix = "+" + countryData.dialCode; // Get country code prefix from countryData
        let formattedValue = numericValue;
        if (numericValue.startsWith(countryData.dialCode)) {
            formattedValue = `+${countryData.dialCode} ${numericValue.substring(countryData.dialCode.length)}`;
        }
        setDetails({ ...details, phonenumber: formattedValue });
    };

    const validation = () => {
        let err = {};
        for (let key in details) {
            // console.log(key, key === "phonenumber");
            if (!details[key] && key !== 'terms') {
                err[key + '_err'] = t('please_enter') + t(`${key}`).replace(/_/g, " ");
                toast.error(t('please_enter') + t(`${key}`).replace(/_/g, " "), { style: { background: '#333', color: '#fff' } })
            } else if (key === "email") {
                if (typeof details[key] === "undefined" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(details[key])) {
                    err['email_err'] = t('valid_email');
                    toast.error(t('valid_email'), { style: { background: '#333', color: '#fff' } })
                }
            } else if (key === 'terms') {
                if (details[key] === false) {
                    err['terms_err'] = t('please_select');
                    toast.error(t('please_select') + t("terms_condition"), { style: { background: '#333', color: '#fff' } })
                }
            } else if (key === 'password') {
                var passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                if (!passwordRegExp.test(details[key])) {
                    err['password_err'] = t('strong_password');
                    toast.error(t('strong_password'), { style: { background: '#333', color: '#fff' } })
                }
            } else if (key === 'first_name' || key === 'last_name') {
                if (!/^[a-zA-Z ]+$/.test(details[key])) {
                    err[key + '_err'] = t('please_enter_valid') + t(`${key}`).replace(/_/g, " ");
                    toast.error(t('please_enter_valid') + t(`${key}`).replace(/_/g, " "), { style: { background: '#333', color: '#fff' } })
                }
            }
            else if (key === 'phonenumber') {
                if (!validatePhoneNumber(details[key].replaceAll('+', '').replaceAll(' ', ''))) {
                    err['phone_err'] = t('invalid_phone');
                    toast.error(t('invalid_phone'), { style: { background: '#333', color: '#fff' } })
                }
            }
        }
        // console.log(err);
        return (Object.keys(err).length > 0) ? false : true;
    }

    const handleSubmit = async (e, type) => {
        e.preventDefault();
        if (validation()) {
            if (type === "customer") {
                setDisable(true);
            } else {
                setDisable2(true);
            }
            // console.log('valid');
            let user = await getDocs(query(collection(db, 'user'), where('email', '==', details.email)));
            // user.docs.map(doc => {
            //     console.log(doc.data());
            // })
            if (user.docs.length === 0) {
                await createUserWithEmailAndPassword(auth, details.email, details.password);
                await addDoc(collection(db, 'user'), {
                    first_name: details.first_name,
                    last_name: details.last_name,
                    email: details.email,
                    // password: details.password,
                    phone_no: details.phonenumber,
                    profile_image: null,
                    my_earnings: null,
                    login_type: 1,
                    user_type: type === "host" ? 0 : 1,
                    created_date: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
                    updated_date: moment().format('YYYY-MM-DD HH:mm:ss.SSS')
                }).then(async docRef => {
                    // console.log(docRef.id);
                    if (type === "host") {
                        var bodyParameter = new URLSearchParams();
                        bodyParameter.append('id', docRef.id);
                        bodyParameter.append('first_name', details.first_name);
                        bodyParameter.append('last_name', details.last_name);
                        bodyParameter.append('email', details.email);
                        axios({
                            method: "post",
                            url: `${API_URL}create-stripe-account`,
                            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                            data: bodyParameter
                        }).then(async (response) => {
                            if (!response.data.success) {
                                setDisable2(false);
                                toast.error(response?.data?.message, { style: { background: '#333', color: '#fff' } });
                            } else {
                                setDisable2(false);
                                await updateDoc(doc(db, 'user', docRef.id), { id: docRef.id, stripe_id: response?.data?.data, stripe_account_completed: false })
                                localStorage.setItem('WEE_USER_ID', docRef.id);
                                localStorage.setItem('WEE_USER_TYPE', "HOST");
                                localStorage.setItem('WEE_USER_LOGIN_TYPE', "CLASSIC");
                                toast.success('Registered Successfully', { style: { background: '#333', color: '#fff' } });
                                setDetails({
                                    name: '',
                                    password: '',
                                    email: '',

                                    phonenumber: '',
                                    terms: false
                                });
                                setTimeout(() => {
                                    nav('/properties-list');
                                    window.scrollTo(0, 0);
                                }, 1500)
                            }
                        }).catch((error) => {
                            setDisable2(false);
                            toast.error(error?.response?.message, { style: { background: '#333', color: '#fff' } });
                        });
                    } else {
                        setDisable(false)
                        await updateDoc(doc(db, 'user', docRef.id), { id: docRef.id })
                        localStorage.setItem('WEE_USER_ID', docRef.id);
                        localStorage.setItem('WEE_USER_TYPE', type === "host" ? "HOST" : "CUSTOMER");
                        localStorage.setItem('WEE_USER_LOGIN_TYPE', "CLASSIC");
                        toast.success(t('registered'), { style: { background: '#333', color: '#fff' } });
                        setDetails({
                            name: '',
                            password: '',
                            email: '',
                            phonenumber: '',
                            terms: false
                        });
                        setTimeout(() => {
                            nav('/');
                            window.scrollTo(0, 0);
                        }, 1500)
                    }
                })
            } else {
                if (type === "customer") {
                    setDisable(false);
                } else {
                    setDisable2(false);
                }
                toast.error(t('already_email'), { style: { background: '#333', color: '#fff' } })
            }
        } else {
            // console.log('not valid');

        }
    }

    const logGoogleUser = async () => {
        try {
            const response = await signInWithGooglePopup();
            setDisable(true);
            if (response) {
                let user = response.user;
                await getDocs(query(collection(db, 'user'), where('email', '==', user.email))).then(async (querysnapshot) => {
                    if (querysnapshot.docs.length === 0) {
                        await addDoc(collection(db, 'user'), {
                            first_name: user?.displayName?.split(' ')[0],
                            last_name: user?.displayName?.split(' ')[1] ?? "",
                            email: user.email,
                            // password: null,
                            phone_no: null,
                            profile_image: null,
                            my_earnings: null,
                            login_type: 2,
                            user_type: 1,
                            created_date: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
                            updated_date: moment().format('YYYY-MM-DD HH:mm:ss.SSS')
                        }).then(async (docRef) => {
                            setDisable(false)
                            await updateDoc(doc(db, 'user', docRef.id), { id: docRef.id })
                            localStorage.setItem('WEE_USER_ID', docRef.id);
                            localStorage.setItem('WEE_USER_TYPE', "CUSTOMER");
                            localStorage.setItem('WEE_USER_LOGIN_TYPE', "GOOGLE");
                            toast.success(t('registered'), { style: { background: '#333', color: '#fff' } });
                            setDetails({
                                name: '',
                                password: '',
                                email: '',
                                phonenumber: '',
                                terms: false
                            });
                            setTimeout(() => {
                                nav('/');
                                window.scrollTo(0, 0);
                            }, 1500)
                        });
                    } else {
                        toast.error(t('already_account_found'), { style: { background: '#333', color: '#fff' } });
                        setDisable(false);
                    }
                })
            } else {
                toast.error(t('something_went_wrong'), { style: { background: '#333', color: '#fff' } });
                setDisable(false);
            }
        } catch (err) {
            // console.log(t('something_went_wrong'), err);
            setDisable(false);
        }
    }
    const logGoogleUser2 = async () => {
        try {
            const response = await signInWithGooglePopup();
            setDisable2(true);
            setDisable(true);
            if (response) {
                let user = response.user;
                await getDocs(query(collection(db, 'user'), where('email', '==', user.email))).then(async (querysnapshot) => {
                    if (querysnapshot.docs.length === 0) {
                        await addDoc(collection(db, 'user'), {
                            first_name: user?.displayName?.split(' ')[0],
                            last_name: user?.displayName?.split(' ')[1] ?? "",
                            email: user.email,
                            // password: null,
                            phone_no: null,
                            profile_image: user?.photoURL ?? null,
                            my_earnings: null,
                            login_type: 2,
                            user_type: 1,
                            created_date: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
                            updated_date: moment().format('YYYY-MM-DD HH:mm:ss.SSS')
                        }).then(async (docRef) => {
                            var bodyParameter = new URLSearchParams();
                            bodyParameter.append('id', docRef.id);
                            bodyParameter.append('first_name', user?.displayName?.split(' ')[0]);
                            bodyParameter.append('last_name', user?.displayName?.split(' ')[1] ?? "");
                            bodyParameter.append('email', user.email);
                            axios({
                                method: "post",
                                url: `${API_URL}create-stripe-account`,
                                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                                data: bodyParameter
                            }).then(async (response) => {
                                if (!response.data.success) {
                                    setDisable2(false);
                                    setDisable(false);
                                    toast.error(response?.data?.message, { style: { background: '#333', color: '#fff' } });
                                } else {
                                    setDisable2(false);
                                    setDisable(false);
                                    await updateDoc(doc(db, 'user', docRef.id), {
                                        id: docRef.id,
                                        stripe_id: response?.data?.data, stripe_account_completed: false
                                    })
                                    localStorage.setItem('WEE_USER_ID', docRef.id);
                                    localStorage.setItem('WEE_USER_TYPE', "HOST");
                                    localStorage.setItem('WEE_USER_LOGIN_TYPE', "GOOGLE");
                                    toast.success(t('registered'), { style: { background: '#333', color: '#fff' } });
                                    setDetails({
                                        name: '',
                                        password: '',
                                        email: '',

                                        phonenumber: '',
                                        terms: false
                                    });
                                    setTimeout(() => {
                                        nav('/properties-list');
                                        window.scrollTo(0, 0);
                                    }, 1500)
                                }
                            }).catch((error) => {
                                setDisable2(false);
                                setDisable(false);
                                toast.error(error?.response?.message, { style: { background: '#333', color: '#fff' } });
                            });
                        });
                    } else {
                        toast.error(t('already_account_found'), { style: { background: '#333', color: '#fff' } });
                        setDisable(false);
                        setDisable2(false);
                    }
                })
            } else {
                toast.error(t('something_went_wrong'), { style: { background: '#333', color: '#fff' } });
                setDisable(false);
                setDisable2(false);
            }
        } catch (err) {
            // console.log(t('something_went_wrong'), err);
            setDisable(false);
            setDisable2(false);
        }
    }
    return (
        <>
            <Toaster />
            <div className='wt-site'>
                <section className="wt-login-section">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-6">
                            <div className="wt-login-section-area">
                                <div className="wt-login-logo">
                                    <a href="/" className="wt-login-logo-link">
                                        <img src="images/logo-dark-new.png" alt="Logo" className="img-fluid" />
                                    </a>
                                </div>
                                <div className="wt-register-content">
                                    <div className="wt-login-heading">
                                        <h1 className="wt-section-title">{t('join_community')}</h1>
                                        <p className="wt-section-description">{t('join_community_text')}</p>
                                    </div>
                                    <div className="wt-register-form-area">
                                        <form className="row justify-content-center">
                                            <div className="col col-12 col-md-7">
                                                <label htmlFor="inputName" className="form-label">{t('first_name')}*</label>
                                                <input type="text" className="form-control" id="inputName" placeholder={t('first_name')} name="first_name" value={details.first_name} onChange={handleChange} />
                                            </div>
                                            <div className="col col-12 col-md-7">
                                                <label htmlFor="inputName" className="form-label">{t('last_name')}*</label>
                                                <input type="text" className="form-control" id="inputName" placeholder={t('last_name')} name="last_name" value={details.last_name} onChange={handleChange} />
                                            </div>
                                            <div className="col col-12 col-md-7 password">
                                                <label htmlFor="inputPassword" className="form-label">{t('password')}</label>
                                                <input type={passwordType} className="form-control" id="inputPassword" placeholder={t('password')} name="password" value={details.password} onChange={handleChange} />
                                                <img className="eye-icon"
                                                    onClick={() => {
                                                        setPasswordType(passwordType === "password" ? "text" : "password");
                                                    }} src={passwordType === 'password' ? "/images/eye.svg" : "/images/eye-slash.svg"} />
                                            </div>
                                            <div className="col col-12 col-md-7">
                                                <label htmlFor="inputEmail" className="form-label">{t('email')}*</label>
                                                <input type="email" className="form-control" id="inputEmail" name="email" value={details.email} onChange={handleChange} placeholder={t('email')} />
                                            </div>
                                            {/* <div className="col col-12 col-md-7">
                                                <label htmlFor="inputPassword" className="form-label">Date of Birth</label>
                                                <div className="wt-input-icon-group">
                                                    <input type="date" className="form-control" id="inputPassword" placeholder="DD/MM/YYYY" name="dob" value={details.dob} onChange={handleChange} />
                                                    <span className="wt-input-icon">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M18 3.75H16.75V3C16.75 2.586 16.414 2.25 16 2.25C15.586 2.25 15.25 2.586 15.25 3V3.75H8.75V3C8.75 2.586 8.414 2.25 8 2.25C7.586 2.25 7.25 2.586 7.25 3V3.75H6C3.582 3.75 2.25 5.082 2.25 7.5V18C2.25 20.418 3.582 21.75 6 21.75H18C20.418 21.75 21.75 20.418 21.75 18V7.5C21.75 5.082 20.418 3.75 18 3.75ZM6 5.25H7.25V6C7.25 6.414 7.586 6.75 8 6.75C8.414 6.75 8.75 6.414 8.75 6V5.25H15.25V6C15.25 6.414 15.586 6.75 16 6.75C16.414 6.75 16.75 6.414 16.75 6V5.25H18C19.577 5.25 20.25 5.923 20.25 7.5V8.25H3.75V7.5C3.75 5.923 4.423 5.25 6 5.25ZM18 20.25H6C4.423 20.25 3.75 19.577 3.75 18V9.75H20.25V18C20.25 19.577 19.577 20.25 18 20.25Z" fill="#667085" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col col-12 col-md-7">
                                                <label htmlFor="inputPassword" className="form-label">Nationality</label>
                                                <select className="form-control" id="countries" name="nationality" value={details.nationality} onChange={handleChange}>
                                                    <option value="GB" data-capital="London">USA</option>
                                                    <option value="FR" data-capital="Paris">Franch</option>
                                                    <option value="IT" data-capital="Rome">Italy</option>
                                                    <option value="CN" data-capital="Beijing">Chaina</option>
                                                    <option value="DE" data-capital="Berlin">Germani</option>
                                                    <option value="PL" data-capital="Warsaw">Poland</option>
                                                </select>
                                            </div> */}
                                            <div className="col col-12 col-md-7">
                                                <label htmlFor="inputPhoneNumber" className="form-label">{t('phone')}*</label>
                                                {/* <input type="text" className="form-control" id="inputPhoneNumber" placeholder="Enter Phone Number" value="+34 908 6745 6265" /> */}
                                                <PhoneInput
                                                    country={'us'}
                                                    value={details.phonenumber}
                                                    onChange={(val, countryData) => handlePhoneNumberChange(val, countryData)}
                                                />
                                            </div>
                                            <div className="col col-12 col-md-7">
                                                <div className="remeber-forgot-content">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="gridCheck" checked={details.terms} onChange={() => setDetails({ ...details, terms: !details.terms })} />
                                                        <label className="form-check-label" htmlFor="gridCheck">
                                                            {t('i_agree')} <a href="#">{t('terms_condition')}</a>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col col-12 col-md-7 mx-auto">
                                                <div className='row'>
                                                    <div className='col col-md-6'>
                                                        <button type="button" onClick={(e) => handleSubmit(e, 'customer')} disabled={disable || disable2} className="btn btn-success w-100">{disable ? `${t('processing')}...` : t('signup_customer')}</button>
                                                    </div>
                                                    <div className='col col-md-6'>
                                                        <button type="button" onClick={(e) => handleSubmit(e, 'host')} disabled={disable || disable2} className="btn w-100" style={{ borderColor: '#1AAA69', color: '#1AAA69' }}>{disable2 ? `${t('processing')}...` : t('signup_host')}</button>
                                                    </div>
                                                </div>
                                                <p className="wt-form-notes">{t('already_account')} <a href="/login">{t('signin')}</a></p>
                                                {/* <ul className="list-unstyled wt-social-login-list"> */}
                                                <div className='row'>
                                                    <div className="col col-md-6 wt-social-login-item">
                                                        <a onClick={logGoogleUser} className="wt-social-login-link" style={{ cursor: "pointer" }}>
                                                            <span className="wt-social-login-icon">
                                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.76756 10.2733L3.17581 12.4824L1.01299 12.5282C0.366629 11.3293 0 9.95763 0 8.50001C0 7.09051 0.342789 5.76132 0.950406 4.59094H0.950871L2.87639 4.94396L3.71988 6.85792C3.54334 7.3726 3.44712 7.9251 3.44712 8.50001C3.44718 9.12397 3.56021 9.72179 3.76756 10.2733Z" fill="#FBBB00" />
                                                                    <path d="M16.8516 6.91211C16.9492 7.42629 17.0001 7.95731 17.0001 8.50002C17.0001 9.10856 16.9362 9.70217 16.8143 10.2748C16.4005 12.2232 15.3193 13.9246 13.8215 15.1286L13.8211 15.1282L11.3958 15.0044L11.0525 12.8616C12.0464 12.2788 12.8231 11.3667 13.2322 10.2748H8.68701V6.91211H13.2985H16.8516Z" fill="#518EF8" />
                                                                    <path d="M13.8208 15.1281L13.8213 15.1286C12.3647 16.2994 10.5142 17 8.49994 17C5.26294 17 2.44861 15.1907 1.01294 12.5281L3.7675 10.2733C4.48532 12.1891 6.33337 13.5528 8.49994 13.5528C9.43119 13.5528 10.3036 13.3011 11.0523 12.8616L13.8208 15.1281Z" fill="#28B446" />
                                                                    <path d="M13.9256 1.95686L11.1719 4.21122C10.3971 3.72692 9.48125 3.44715 8.50003 3.44715C6.28442 3.44715 4.40181 4.87346 3.71995 6.85791L0.950904 4.59093H0.950439C2.36509 1.86346 5.21492 0 8.50003 0C10.5624 0 12.4535 0.734653 13.9256 1.95686Z" fill="#F14336" />
                                                                </svg>
                                                            </span>
                                                            <span>{t('customer')}</span>
                                                        </a>
                                                    </div>
                                                    <div className="col col-md-6 wt-social-login-item">
                                                        <a onClick={logGoogleUser2} className="wt-social-login-link" style={{ cursor: "pointer" }}>
                                                            <span className="wt-social-login-icon">
                                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.76756 10.2733L3.17581 12.4824L1.01299 12.5282C0.366629 11.3293 0 9.95763 0 8.50001C0 7.09051 0.342789 5.76132 0.950406 4.59094H0.950871L2.87639 4.94396L3.71988 6.85792C3.54334 7.3726 3.44712 7.9251 3.44712 8.50001C3.44718 9.12397 3.56021 9.72179 3.76756 10.2733Z" fill="#FBBB00" />
                                                                    <path d="M16.8516 6.91211C16.9492 7.42629 17.0001 7.95731 17.0001 8.50002C17.0001 9.10856 16.9362 9.70217 16.8143 10.2748C16.4005 12.2232 15.3193 13.9246 13.8215 15.1286L13.8211 15.1282L11.3958 15.0044L11.0525 12.8616C12.0464 12.2788 12.8231 11.3667 13.2322 10.2748H8.68701V6.91211H13.2985H16.8516Z" fill="#518EF8" />
                                                                    <path d="M13.8208 15.1281L13.8213 15.1286C12.3647 16.2994 10.5142 17 8.49994 17C5.26294 17 2.44861 15.1907 1.01294 12.5281L3.7675 10.2733C4.48532 12.1891 6.33337 13.5528 8.49994 13.5528C9.43119 13.5528 10.3036 13.3011 11.0523 12.8616L13.8208 15.1281Z" fill="#28B446" />
                                                                    <path d="M13.9256 1.95686L11.1719 4.21122C10.3971 3.72692 9.48125 3.44715 8.50003 3.44715C6.28442 3.44715 4.40181 4.87346 3.71995 6.85791L0.950904 4.59093H0.950439C2.36509 1.86346 5.21492 0 8.50003 0C10.5624 0 12.4535 0.734653 13.9256 1.95686Z" fill="#F14336" />
                                                                </svg>
                                                            </span>
                                                            <span>{t('host')}</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-6">
                            <ImageSlider />
                            {/* <div className="wt-login-img-slider">
                                <div className="wt-login-img-slider-item">
                                    <img src="images/login-img.jpeg" alt="Login" className="img-fluid w-100" />
                                </div>
                                <div className="wt-login-img-slider-item">
                                    <img src="images/login-img2.jpeg" alt="Login" className="img-fluid w-100" />
                                </div>
                                <div className="wt-login-img-slider-item">
                                    <img src="images/login-img.jpeg" alt="Login" className="img-fluid w-100" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Signup