import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

const GuestModal = ({ activeModal, setActiveModal, guest, handleApply }) => {
    const [datas, setDatas] = useState({
        guest: Number(guest.guests),
        child: Number(guest.child)
    });

    const handleInc = (type) => {
        setDatas({ ...datas, [type]: datas[type] + 1 })
    }

    const handleDec = (type) => {
        if (datas[type] !== 1) {
            setDatas({ ...datas, [type]: datas[type] - 1 })
        }
    }
    return (
        <Modal
            className="modal fade wt-confirm-modal"
            id="confirmGuestModal"
            show={activeModal}
            onHide={setActiveModal}
            backdrop="static"
            keyboard={false}
            size='lg'
        >
            <Modal.Header>
                <button type="button" class="btn-close" onClick={setActiveModal}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 1.25C6.072 1.25 1.25 6.073 1.25 12C1.25 17.927 6.072 22.75 12 22.75C17.928 22.75 22.75 17.927 22.75 12C22.75 6.073 17.928 1.25 12 1.25ZM12 21.25C6.899 21.25 2.75 17.101 2.75 12C2.75 6.899 6.899 2.75 12 2.75C17.101 2.75 21.25 6.899 21.25 12C21.25 17.101 17.101 21.25 12 21.25ZM15.53 9.53003L13.06 12L15.53 14.47C15.823 14.763 15.823 15.238 15.53 15.531C15.384 15.677 15.192 15.751 15 15.751C14.808 15.751 14.616 15.678 14.47 15.531L12 13.061L9.53 15.531C9.384 15.677 9.192 15.751 9 15.751C8.808 15.751 8.616 15.678 8.47 15.531C8.177 15.238 8.177 14.763 8.47 14.47L10.94 12L8.47 9.53003C8.177 9.23703 8.177 8.76199 8.47 8.46899C8.763 8.17599 9.23801 8.17599 9.53101 8.46899L12.001 10.939L14.471 8.46899C14.764 8.17599 15.239 8.17599 15.532 8.46899C15.823 8.76199 15.823 9.23803 15.53 9.53003Z"
                            fill="#25314C" />
                    </svg>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div class="wt-confirm-pay-modal-content">
                    <div class="wt-confirm-pay-guest-area">
                        <h3 class="wt-confirm-pay-modal-title">Guests</h3>
                        <div class="wt-confirm-guest-list">
                            <div class="wt-confirm-guest-item">
                                <div class="wt-confirm-guest-item__inner">
                                    <h3 class="wt-confirm-guest-title">Adults</h3>
                                    <p class="wt-confirm-guest-age">Age 13+</p>
                                </div>
                                <div class="wt-confirm-guest-item__inner">
                                    <div class="wt-guest-qty-content">
                                        <span class="wt-qty-btn wt-qty-minus" onClick={() => handleDec('guest')}>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23 16.75H9C8.586 16.75 8.25 16.414 8.25 16C8.25 15.586 8.586 15.25 9 15.25H23C23.414 15.25 23.75 15.586 23.75 16C23.75 16.414 23.414 16.75 23 16.75Z"
                                                    fill="#0E1826" />
                                                <rect x="0.5" y="0.5" width="31" height="31" rx="15.5"
                                                    stroke="#7B7B7B" />
                                            </svg>
                                        </span>
                                        <input type="text" class="wt-confirm-guest-qty" value={datas.guest} />
                                        <span class="wt-qty-btn wt-qty-plus" onClick={() => handleInc('guest')}>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.75 16C23.75 16.414 23.414 16.75 23 16.75H16.75V23C16.75 23.414 16.414 23.75 16 23.75C15.586 23.75 15.25 23.414 15.25 23V16.75H9C8.586 16.75 8.25 16.414 8.25 16C8.25 15.586 8.586 15.25 9 15.25H15.25V9C15.25 8.586 15.586 8.25 16 8.25C16.414 8.25 16.75 8.586 16.75 9V15.25H23C23.414 15.25 23.75 15.586 23.75 16Z"
                                                    fill="#0E1826" />
                                                <rect x="0.5" y="0.5" width="31" height="31" rx="15.5"
                                                    stroke="#7B7B7B" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="wt-confirm-guest-item">
                                <div class="wt-confirm-guest-item__inner">
                                    <h3 class="wt-confirm-guest-title">Children</h3>
                                    <p class="wt-confirm-guest-age">Ages 2–12</p>
                                </div>
                                <div class="wt-confirm-guest-item__inner">
                                    <div class="wt-guest-qty-content">
                                        <span class="wt-qty-btn wt-qty-minus" onClick={() => handleDec('child')}>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23 16.75H9C8.586 16.75 8.25 16.414 8.25 16C8.25 15.586 8.586 15.25 9 15.25H23C23.414 15.25 23.75 15.586 23.75 16C23.75 16.414 23.414 16.75 23 16.75Z"
                                                    fill="#0E1826" />
                                                <rect x="0.5" y="0.5" width="31" height="31" rx="15.5"
                                                    stroke="#7B7B7B" />
                                            </svg>
                                        </span>
                                        <input type="text" class="wt-confirm-guest-qty" value={datas.child} />
                                        <span class="wt-qty-btn wt-qty-plus" onClick={() => handleInc('child')}>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.75 16C23.75 16.414 23.414 16.75 23 16.75H16.75V23C16.75 23.414 16.414 23.75 16 23.75C15.586 23.75 15.25 23.414 15.25 23V16.75H9C8.586 16.75 8.25 16.414 8.25 16C8.25 15.586 8.586 15.25 9 15.25H15.25V9C15.25 8.586 15.586 8.25 16 8.25C16.414 8.25 16.75 8.586 16.75 9V15.25H23C23.414 15.25 23.75 15.586 23.75 16Z"
                                                    fill="#0E1826" />
                                                <rect x="0.5" y="0.5" width="31" height="31" rx="15.5"
                                                    stroke="#7B7B7B" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="wt-confirm-guest-item">
                                <div class="wt-confirm-guest-item__inner">
                                    <h3 class="wt-confirm-guest-title">Infants</h3>
                                    <p class="wt-confirm-guest-age">Under 2</p>
                                </div>
                                <div class="wt-confirm-guest-item__inner">
                                    <div class="wt-guest-qty-content">
                                        <span class="wt-qty-btn wt-qty-minus">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23 16.75H9C8.586 16.75 8.25 16.414 8.25 16C8.25 15.586 8.586 15.25 9 15.25H23C23.414 15.25 23.75 15.586 23.75 16C23.75 16.414 23.414 16.75 23 16.75Z"
                                                    fill="#0E1826" />
                                                <rect x="0.5" y="0.5" width="31" height="31" rx="15.5"
                                                    stroke="#7B7B7B" />
                                            </svg>
                                        </span>
                                        <input type="text" class="wt-confirm-guest-qty" value="5" />
                                        <span class="wt-qty-btn wt-qty-plus">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.75 16C23.75 16.414 23.414 16.75 23 16.75H16.75V23C16.75 23.414 16.414 23.75 16 23.75C15.586 23.75 15.25 23.414 15.25 23V16.75H9C8.586 16.75 8.25 16.414 8.25 16C8.25 15.586 8.586 15.25 9 15.25H15.25V9C15.25 8.586 15.586 8.25 16 8.25C16.414 8.25 16.75 8.586 16.75 9V15.25H23C23.414 15.25 23.75 15.586 23.75 16Z"
                                                    fill="#0E1826" />
                                                <rect x="0.5" y="0.5" width="31" height="31" rx="15.5"
                                                    stroke="#7B7B7B" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="wt-confirm-guest-item">
                                <div class="wt-confirm-guest-item__inner">
                                    <h3 class="wt-confirm-guest-title">Pets</h3>
                                </div>
                                <div class="wt-confirm-guest-item__inner">
                                    <div class="wt-guest-qty-content">
                                        <span class="wt-qty-btn wt-qty-minus">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23 16.75H9C8.586 16.75 8.25 16.414 8.25 16C8.25 15.586 8.586 15.25 9 15.25H23C23.414 15.25 23.75 15.586 23.75 16C23.75 16.414 23.414 16.75 23 16.75Z"
                                                    fill="#0E1826" />
                                                <rect x="0.5" y="0.5" width="31" height="31" rx="15.5"
                                                    stroke="#7B7B7B" />
                                            </svg>
                                        </span>
                                        <input type="text" class="wt-confirm-guest-qty" value="5" />
                                        <span class="wt-qty-btn wt-qty-plus">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M23.75 16C23.75 16.414 23.414 16.75 23 16.75H16.75V23C16.75 23.414 16.414 23.75 16 23.75C15.586 23.75 15.25 23.414 15.25 23V16.75H9C8.586 16.75 8.25 16.414 8.25 16C8.25 15.586 8.586 15.25 9 15.25H15.25V9C15.25 8.586 15.586 8.25 16 8.25C16.414 8.25 16.75 8.586 16.75 9V15.25H23C23.414 15.25 23.75 15.586 23.75 16Z"
                                                    fill="#0E1826" />
                                                <rect x="0.5" y="0.5" width="31" height="31" rx="15.5"
                                                    stroke="#7B7B7B" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div class="wt-confirm-pay-modal-btn">
                    <button ton type="button" class="btn btn-success" onClick={() => handleApply(datas)} >Apply</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default GuestModal