import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'

const ConfirmModal = ({ activeModal, setActiveModal }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <Modal
            className="modal fade wt-confirm-modal"
            id="confirmGuestModal"
            show={activeModal}
            onHide={setActiveModal}
            backdrop="static"
            keyboard={false}
            size='md'
        >
            <Modal.Body>
                <div class="wt-confirm-pay-modal-content">
                    <div class="wt-confirm-pay-guest-area">
                        <h3 class="wt-confirm-pay-modal-title">{t('confirmation')}</h3>

                        <div class="wt-confirm-pay-modal-title">
                            <img src='images/success-icon.svg' alt='sucess logo' />
                            <p>{t('reserved')}</p>
                        </div>
                    </div>
                </div>
                <div class="wt-confirm-pay-modal-btn">
                    <button ton type="button" class="btn btn-success" onClick={() => { navigate('/') }}>{t('back_home')}</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmModal
