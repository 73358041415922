import React, { useState, useEffect } from 'react'
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import axios from 'axios';
import { API_URL, STRIPE_KEY } from '../BaseURL';
import DateModal from './DateModal';
import GuestModal from './GuestModal';
import ConfirmModal from './ConfirmModal';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import AddCard from './AddCard';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Swal from "sweetalert2";

const Checkout = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const nav = useNavigate();
    const [disableDates, setDisableDates] = useState([]);
    const [details, setDetails] = useState({ ...location?.state?.state, guests: location?.state?.guests ?? 0, child: 0, card: '', selected_date: null });
    var monthName = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
    const stripe = loadStripe(STRIPE_KEY);
    const stripe2 = useStripe();
    const [addCard, setAddCard] = useState(false);
    const [temp, setTemp] = useState(location?.state?.state);
    function getDaysDifference(date1, date2) {
        // Convert the dates to milliseconds since epoch (Jan 1, 1970)
        const milliseconds1 = date1.getTime();
        const milliseconds2 = date2.getTime();

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = Math.abs(milliseconds1 - milliseconds2);

        // Convert the difference to days (1 day = 1000 * 60 * 60 * 24 milliseconds)
        const daysDifference = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

        return daysDifference;
    }
    const getAmt = () => {
        let amt = details?.post_type === 1 ? details?.price_per_room : details?.post_type === 2 ? details?.price_for_day : details?.price_per_hour;
        // let days = getDaysDifference(new Date(details?.start_date), new Date(details?.end_date)) + 1;
        let days = calculateTotalDays(details?.start_date, details?.check_in_time, details?.end_date, details?.check_out_time);
        return amt * days;

    };
    function calculateTotalDays(startDateStr, startTime, endDateStr, endTime) {
        const startDateTimeStr = `${startDateStr}T${startTime}`;
        const endDateTimeStr = `${endDateStr}T${endTime}`;

        // Convert the date-time strings to Date objects
        const startDateTime = new Date(startDateTimeStr);
        const endDateTime = new Date(endDateTimeStr);

        // Calculate the time difference in milliseconds
        const timeDifference = endDateTime - startDateTime;

        // Convert the time difference to hours
        const totalHours = timeDifference / (1000 * 3600);

        return totalHours.toFixed(2);
    }
    const getFinalAmt = () => {
        // let days = calculateTotalDays(details?.start_date, details?.end_date);
        let amt = getAmt();
        let commission = Number((amt * 10) / 100);
        let service = Number((amt * 16) / 100);
        return amt + commission + service;
    };
    const [billing, setBilling] = useState({
        // name: "",
        street: "",
        suite: "",
        city: "",
        state: "",
        zip: "",
        country: "Mexico"
    });
    const [disable, setDisable] = useState(false);
    const [dateModal, setDateModal] = useState(false);
    const [guestModal, setGuestModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [customerId, setCustomerId] = useState(null);
    useEffect(() => {
        getCards();
        getDisableDates();
        setDateModal(true);
    }, []);

    const getDisableDates = async () => {
        let dates = [];
        await getDocs(query(collection(db, 'my_booking'), where('post_id', '==', location?.state?.state?.id))).then(async (querysnapshot) => {
            await querysnapshot.forEach((doc) => {
                dates.push(doc.data().book_date);
            })
        });
        setDisableDates(dates);
    }

    function convertTimeToDate(date, timeString) {
        // console.log(date, timeString)
        const [hours, minutes] = timeString.split(':').map(Number);
        const time = new Date(date);
        time.setHours(hours, minutes, 0, 0); // Set hours and minutes, seconds and milliseconds to 0
        return time;
    }

    const getCards = async () => {
        setLoading(true);
        let customer_id = null;
        let user = await getDoc(doc(db, "user", localStorage.getItem('WEE_USER_ID')));
        if (user?.data()?.stripe_customer_id) {
            customer_id = user?.data()?.stripe_customer_id;
        } else {
            var bodyParameter = new URLSearchParams();
            bodyParameter.append('name', user?.data()?.first_name + " " + user?.data()?.last_name);
            bodyParameter.append('email', user?.data()?.email);
            await axios({
                method: "post",
                url: `${API_URL}create-stripe-customer`,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                data: bodyParameter
            }).then(async (response) => {
                if (response.data.success) {
                    customer_id = response.data.data.customer_id;
                    await updateDoc(doc(db, "user", localStorage.getItem('WEE_USER_ID')), { stripe_customer_id: customer_id })
                }
            });
        };
        setCustomerId(customer_id);
        await axios({
            method: "post",
            url: `${API_URL}get-cards`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                customerId: customer_id
            }
        }).then(async (response) => {
            if (response.data.success) {
                setCards(response.data.data);
            }
        }).catch((error) => {
            // console.log(error);
        })
        setLoading(false);
    }
    const handleChangeAdd = (e) => {
        setBilling({ ...billing, [e.target.name]: e.target.value?.trim()?.length > 0 ? e.target.value : "" })
    };

    const handleChangeDate = (val) => {
        setDateModal(false)
        setDetails({ ...details, start_date: val.start_date, end_date: val.end_date, check_in_time: val.check_in_time, check_out_time: val.check_out_time })
    }

    const handleApply = (val) => {
        setGuestModal(false)
        setDetails({ ...details, guests: val.guest, child: val.child })
    }

    const handleAdded = () => {
        setAddCard(false);
        toast.success("Card added", { style: { background: '#333', color: '#fff' } });
        getCards();
    }

    const validate = () => {
        let err = {};
        let regex = /^[a-zA-Z0-9, ]{1,100}$/;
        for (let key in billing) {
            if (!(billing[key]).trim()) {
                err[key + '_err'] = t('please_enter') + t(`${key}`).replace(/_/g, " ");
                toast.error(t('please_enter') + t(`${key}`).replace(/_/g, " "), { style: { background: '#333', color: '#fff' } })
            }

            if ((key === 'street' || key === 'suite') && (billing[key]).trim() && !regex.test((billing[key]).trim())) {
                err[key + '_err'] = t('please_enter_valid') + t(`${key}`).replace(/_/g, " ");
                toast.error(t('please_enter_valid') + t(`${key}`).replace(/_/g, " "), { style: { background: '#333', color: '#fff' } })
            }
        }
        // if (details?.guests === 0 && details?.child === 0) {
        //     err['guest_err'] = 'Please select number of guests';
        //     toast.error('Please select number of guests', { style: { background: '#333', color: '#fff' } })
        // }
        if (!details?.card) {
            err['card_err'] = t('please_select_card');
            toast.error(t('please_select_card'), { style: { background: '#333', color: '#fff' } })
        }

        if (!details?.start_date || !details?.end_date) {
            err['date_err'] = t('please_select_date');
            toast.error(t('please_select_date'), { style: { background: '#333', color: '#fff' } })
        }
        // console.log(err);
        return (Object.keys(err).length > 0) ? false : true;
    }

    const handlePay = async (e) => {
        e.preventDefault();
        if (validate()) {
            setDisable(true);
            // const { error, paymentMethod } = await stripe.createPaymentMethod({
            //     type: 'card',
            //     card: elements.getElement(CardNumberElement),
            // });
            // console.log(paymentMethod);
            let amount = getFinalAmt();
            // if (!error) {
            try {
                localStorage.setItem('paymentDetails', JSON.stringify(details));
                localStorage.setItem('temp', JSON.stringify(temp));
                var bodyParameter = new URLSearchParams();
                bodyParameter.append('amount', amount);
                bodyParameter.append('paymentMethodId', details?.card);
                bodyParameter.append('customerId', customerId);
                await axios({
                    method: "post",
                    url: `${API_URL}process-payment`,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    data: bodyParameter
                }).then(async (response) => {
                    if (response?.data?.data?.client_secret) {
                        const ele = response?.data?.data?.client_secret;
                        if (response?.data?.data?.status == "succeeded") {
                            nav('/confirm', { state: { details } });
                        }
                        const result = await stripe2.confirmPayment({
                            clientSecret: ele,
                            confirmParams: {
                                // return_url: `http://localhost:3000/confirm`,
                                return_url: `https://relta.mx/confirm`,
                            },
                        });
                        if (result.error == null) {
                            nav('/confirm', { state: { details } });
                        }
                        else {
                            console.log(result.error)
                            setDisable(false)
                            toast.error(result.error.message, { style: { background: '#333', color: '#fff' } });
                        }
                    }
                    else {
                        setDisable(false)
                        console.log("++++++++++++++++++++++++++++++++++++>", response)
                        toast.error(response?.data?.message, { style: { background: '#333', color: '#fff' } });
                    }
                    // setConfirmModal(true);
                    // toast.success('Booked Successfully', { style: { background: '#333', color: '#fff' } });
                    // setTimeout(() => {
                    //     nav('/my-trips');
                    //     window.location.reload();
                    // }, 1500)
                });
            }
            catch (error) {
                setDisable(false)
                toast.error(error?.response?.data?.message, { style: { background: '#333', color: '#fff' } });
            }
            // } else {
            //     toast.error(error?.message, { style: { background: '#333', color: '#fff' } })
            //     setDisable(false)
            //     console.log("else", error.message);
            // }
        }
    }

    function tConvert(time) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }

    const handleconfirm = (amount, commissionFee, serviceCharge) => {
        const total = amount + serviceCharge + commissionFee;
        if (validate()) {

            Swal.fire({
                title: 'Confirm Payment',
                html: `
                <div>
                    <p>Amount: MXN $${amount.toFixed(2)}</p>
                    <p>Commission Fee (10%): MXN $${commissionFee.toFixed(2)}</p>
                    <p>Service Fee (16%): MXN $${serviceCharge.toFixed(2)}</p>
                    <p>Total: MXN $${total.toFixed(2)}</p>
                </div>
            `,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    handlePay(window.event);
                    Swal.close();
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.close();
                }
            });
        }
    };

    return (
        <>
            <Toaster />
            <DateModal
                activeModal={dateModal}
                setActiveModal={() => setDateModal(false)}
                dates={details}
                handleChangeDate={handleChangeDate}
                temp={temp}
            />
            <GuestModal
                activeModal={guestModal}
                setActiveModal={() => setGuestModal(false)}
                guest={details}
                handleApply={handleApply}
            />
            <ConfirmModal
                activeModal={confirmModal}
                setActiveModal={() => setConfirmModal(false)}
            />
            <Elements stripe={stripe}>
                <AddCard
                    activeModal={addCard}
                    setActiveModal={() => setAddCard(false)}
                    customerId={customerId}
                    handleAdded={handleAdded}
                />
            </Elements>
            <div className='wt-site'>
                <Header />
                <main className="wt-site-content">

                    { /* Confirm pay Section Start */}
                    <section className="wt-confirm-section">
                        <div className="container">
                            <div className="wt-confirm-header">
                                <a onClick={() => window.history.back()} className="wt-back-link">
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.0003 20.5003C14.7443 20.5003 14.4883 20.4023 14.2933 20.2073L7.29325 13.2073C6.90225 12.8163 6.90225 12.1842 7.29325 11.7933L14.2933 4.79325C14.6842 4.40225 15.3163 4.40225 15.7073 4.79325C16.0983 5.18425 16.0983 5.81631 15.7073 6.20731L9.41434 12.5003L15.7073 18.7933C16.0983 19.1842 16.0983 19.8163 15.7073 20.2073C15.5123 20.4023 15.2563 20.5003 15.0003 20.5003Z" fill="#0E1826" />
                                    </svg>
                                </a>
                                <h1 className="wt-section-title">{t('confirm_pay')}</h1>
                            </div>
                            <div className="row">
                                <div className="col col-12 col-md-12 col-lg-6">
                                    <div className="wt-confirm-pay-area">
                                        <div className="wt-confirm-order-detail">
                                            <h3 className="wt-confirm-order-title">{t('your_order')}</h3>
                                            <div className="wt-confirm-order-item">
                                                <div className="wt-confirm-order-item__inner">
                                                    <label className="wt-confirm-order-label">{t('dates')}</label>

                                                    <p className="wt-confirm-order-text">{(monthName[new Date(details?.start_date).getMonth()]).slice(0, 3)} {new Date(details?.start_date).getDate()} - {(monthName[new Date(details?.end_date).getMonth()]).slice(0, 3)} {new Date(details?.end_date).getDate()}</p>
                                                    <p className='wt-confirm-order-text'>{tConvert(details.check_in_time)} - {tConvert(details.check_out_time)}</p>
                                                    {/* <div className='select-date'>
                                                        <label className="form-label">
                                                            {t('select_date')}
                                                        </label><br />
                                                        <DatePicker showIcon minDate={details?.start_date} maxDate={details?.end_date} className='form-control' excludeDates={disableDates} selected={details?.selected_date} onChange={(date) => { setDetails({ ...details, selected_date: date }); }} dateFormat="yyyy-MM-dd" /> */}
                                                    {/* <input type="date" className="form-control" min={details?.start_date} max={details?.end_date} /> */}
                                                    {/* </div> */}
                                                </div>
                                                <a style={{ cursor: 'pointer' }} onClick={() => setDateModal(true)} className="wt-confirm-order-edit">{t('edit')}</a>
                                            </div>
                                            {/* <div className="wt-confirm-order-item">
                                                <div className="wt-confirm-order-item__inner">
                                                    <label className="wt-confirm-order-label">Guests</label>
                                                    <p className="wt-confirm-order-text">{Number(details?.guests) + Number(details?.child)} guests</p>
                                                </div>
                                                <a style={{ cursor: 'pointer' }} onClick={() => setGuestModal(true)} className="wt-confirm-order-edit">Edit</a>
                                            </div> */}
                                        </div>
                                        <div className="wt-pay-with-content">
                                            <div className="wt-pay-with-head">
                                                <h3 className="wt-pay-with-title">{t('pay_with_card')}</h3>
                                                <ul className="wt-pay-with-list list-unstyled">
                                                    <li className="wt-pay-with-item">
                                                        <img src="images/visa.png" alt="Visa" className="img-fluid" />
                                                    </li>
                                                    <li className="wt-pay-with-item">
                                                        <img src="images/master-card.png" alt="master-card" className="img-fluid" />
                                                    </li>
                                                    <li className="wt-pay-with-item">
                                                        <img src="images/paypal.png" alt="paypal" className="img-fluid" />
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="wt-pay-with-form">
                                                {/* <div className="wt-pay-with-form-item wt-pay-with-card-detail">
                                                    <div className="col col-12 col-md-12">
                                                        <div className="wt-pay-with-form-input">
                                                            <input type='text' className='form-control' placeholder="Enter full name" name='name' value={billing?.name} onChange={handleChangeAdd} />
                                                        </div>
                                                    </div>
                                                    <div className="row g-0">
                                                        <div className="col col-12 col-md-12">
                                                            <div className="wt-pay-with-form-input">
                                                                <CardNumberElement
                                                                    id="cc-number"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col col-6 col-md-6">
                                                            <div className="wt-pay-with-form-input">
                                                                <CardExpiryElement
                                                                    id="expiry"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col col-6 col-md-6">
                                                            <div className="wt-pay-with-form-input">
                                                                <CardCvcElement
                                                                    id="cvc"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className='wt-pay-with-form-item wt-pay-with-card-detail'>
                                                    {loading && <p>Loading Cards...</p>}
                                                    {cards && cards?.map((el) =>
                                                        // <p>****{el?.last4}</p>
                                                        <>
                                                            <input type='radio' name='cardSelection' value={el?.id} onChange={(e) => setDetails({ ...details, card: e.target.value })} style={{ margin: '10px' }} />**** {el?.last4}
                                                            <br />
                                                        </>
                                                    )}<br />
                                                    <button type="button" style={{ marginTop: "15px" }} className='btn btn-dark btn-primary' onClick={() => setAddCard(true)}>+ {t('add_card')}</button>
                                                </div>
                                                <div className="wt-pay-with-form-item wt-billing-address">
                                                    <div className="wt-pay-with-head">
                                                        <h3 className="wt-pay-with-title">{t('billing_address')}</h3>
                                                    </div>
                                                    <div className="wt-billing-form">
                                                        <div className="wt-pay-with-form-item wt-billing-detail">
                                                            <div className="row g-0">
                                                                <div className="col col-12 col-md-12">
                                                                    <div className="wt-pay-with-form-input">
                                                                        <input type="text" className="form-control" placeholder={t('street_address')} name='street' onChange={handleChangeAdd} value={billing?.street} />
                                                                    </div>
                                                                </div>
                                                                <div className="col col-12 col-md-12">
                                                                    <div className="wt-pay-with-form-input">
                                                                        <input type="text" className="form-control" placeholder={t('apt')} name='suite' onChange={handleChangeAdd} value={billing?.suite} />
                                                                    </div>
                                                                </div>
                                                                <div className="col col-12 col-md-12">
                                                                    <div className="wt-pay-with-form-input">
                                                                        <input type="text" className="form-control" placeholder={t('city')} name='city' onChange={handleChangeAdd} value={billing?.city} />
                                                                    </div>
                                                                </div>
                                                                <div className="col col-6 col-md-6">
                                                                    <div className="wt-pay-with-form-input">
                                                                        <input type="text" className="form-control" placeholder={t('state')} name='state' onChange={handleChangeAdd} value={billing?.state} />
                                                                    </div>
                                                                </div>
                                                                <div className="col col-6 col-md-6">
                                                                    <div className="wt-pay-with-form-input">
                                                                        <input type="text" className="form-control" placeholder={t('zip')} name='zip' onChange={handleChangeAdd} value={billing?.zip} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="wt-pay-with-form-item wt-billing-country">
                                                                <label className="form-label">{t('country')}</label>
                                                                <select className="form-select" name='country' disabled onChange={handleChangeAdd} value={billing?.country}>
                                                                    <option value="England">England</option>
                                                                    <option value="Mexico">Mexico</option>
                                                                    <option value="India">India</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="wt-policy-content">
                                            <h3 className="wt-policy-title">{t('cancellation_policy')}</h3>
                                            <p className="wt-policy-description">Lorem ipsum dolor sit amet, consectetur adipiscing
                                                elit, sed do eiusmod tempor
                                                incididunt ut labore </p>
                                        </div>
                                        <div className="wt-policy-content border-0 wt-rule-content">
                                            <h3 className="wt-policy-title">{t('rules')}</h3>
                                            <p className="wt-policy-description">Lorem ipsum dolor sit amet, consectetur adipiscing
                                                elit, sed do eiusmod tempor incididunt ut labore </p>
                                        </div> */}
                                        <div className="wt-confirm-pay-btn">
                                            <button type="button" className="btn btn-success" onClick={() => { handleconfirm(getAmt(), Number((getAmt() * 10) / 100), Number((getAmt() * 16) / 100)) }} disabled={disable}>{disable ? `${t('processing')}...` : t('confirm_pay')}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-12 col-lg-6">
                                    <div className="wt-order-detail">
                                        <div className="wt-order-card">
                                            <div className="wt-order-card-img">
                                                <img src={details?.images} alt="confirm-img" className="img-fluid" />
                                            </div>
                                            <div className="wt-order-card-body">
                                                <div className="wt-order-card-detail">
                                                    <label className="wt-order-card-label">{details?.description?.length > 30 ? details?.description?.substring(0, 30) + '...' : details?.description}</label>
                                                    <h3 className="wt-order-card-title">{details?.title}</h3>
                                                </div>
                                                <div className="wt-order-card-footer">
                                                    <p className="wt-order-card-review">
                                                        <span /> {details?.rating}
                                                        <span className="wt-order-card-review-icon">
                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.04167 0.958008L8.5 5.54134H13.0833L9.33333 8.45801L10.5833 13.0413L7.04167 10.1247L3.5 13.0413L4.75 8.45801L1 5.54134H5.58333L7.04167 0.958008Z" fill="#FCD34D" stroke="#F59E0B" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                        <span>({details?.reviews} {t('reviews')})</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wt-order-price-detail">
                                            <h3 className="wt-order-price-title">{t('price_details')}</h3>
                                            <div className="wt-order-price-item">
                                                <div className="row gap-2">
                                                    <div className="col col-12 d-flex justify-content-between">
                                                        <label className="wt-order-price-label"><span className='ms-0'>MXN ${details?.post_type === 1 ? details?.price_per_room : details?.post_type === 2 ? details?.price_for_day : details?.price_per_hour}</span>/{t('per_night')}
                                                            {details?.post_type === 3 ? <span className="wt-order-price-unit">x {calculateTotalDays(details.start_date, details?.check_in_time, details.end_date, details.check_out_time)} {t('nights')}</span> : ''}
                                                        </label>
                                                        <p className="wt-order-price-amount">MXN ${getAmt()}</p>
                                                    </div>
                                                    <div className="col col-12 d-flex justify-content-between">
                                                        <label className="wt-order-price-label">Commission Fee (10%)</label>
                                                        <p className="wt-order-price-amount">+ MXN ${Number((getAmt() * 10) / 100)}</p>
                                                    </div>
                                                    <div className="col col-12 d-flex justify-content-between">
                                                        <label className="wt-order-price-label">Service Fee (16%)</label><br />
                                                        <p className="wt-order-price-amount">+ MXN ${Number((getAmt() * 16) / 100)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="wt-order-price-item">
                                            </div>
                                            <div className="wt-order-price-item">
                                            </div> */}
                                            <div className="wt-order-price-total">
                                                <label className="wt-order-price-total-label">Total </label>
                                                <p className="wt-order-price-amount">MXN ${getFinalAmt()}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    { /* Confirm pay Section Start */}

                </main>
                <Footer />
            </div >
        </>
    )
}

export default Checkout