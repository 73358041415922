import { collection, getDocs, query, updateDoc, where, doc } from 'firebase/firestore';
import React, { useState } from 'react'
import { auth, db } from '../FirebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ImageSlider from './ImageSlider';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [disable, setDisable] = useState(false);
    const navigate = useNavigate();
    const validate = () => {
        let err = {};
        if (!email) {
            err['email_err'] = t('enter_email');
            toast.error(t('enter_email'), { style: { background: '#333', color: '#fff' } })
        } else if (typeof email !== "undefined") {
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                err['email_err'] = t('valid_email');
                toast.error(t('valid_email'), { style: { background: '#333', color: '#fff' } })
            }
        }
        return (Object.keys(err).length > 0) ? false : true;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setDisable(true);
            await getDocs(query(collection(db, 'user'), where('email', '==', email))).then((querysnapshot) => {
                if (querysnapshot.docs.length > 0) {
                    if (querysnapshot.docs[0].data().login_type === 1) {
                        sendPasswordResetEmail(auth, email).then((res) => {
                            setDisable(false);
                            toast.success(t('reset_link'), { style: { background: '#333', color: '#fff' } });
                            setTimeout(() => {
                                navigate('/login');
                                window.location.reload();
                            }, 1000);
                        });
                    } else {
                        toast.error(t('used_email'), { style: { background: '#333', color: '#fff' } });
                        setDisable(false);
                    }
                } else {
                    toast.error(t('email_not_exists'), { style: { background: '#333', color: '#fff' } });
                    setDisable(false);
                }
            });
        }
    }
    return (
        <>
            <Toaster />
            <div className='wt-site'>
                <section className="wt-login-section wt-forgot-section">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-6">
                            <div className="wt-login-section-area">
                                <div className="wt-login-logo">
                                    <a href="/" className="wt-login-logo-link">
                                        <img src="images/logo-dark-new.png" alt="Logo" className="img-fluid" />
                                    </a>
                                </div>
                                <div className="wt-login-content">
                                    <div className="wt-back-login-content">
                                        <a href="/login" className="wt-back-login-link">
                                            <span className="wt-back-login-icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM16 12.75H9.81104L11.531 14.47C11.824 14.763 11.824 15.238 11.531 15.531C11.385 15.677 11.193 15.751 11.001 15.751C10.809 15.751 10.6169 15.678 10.4709 15.531L7.47095 12.531C7.40195 12.462 7.34708 12.3791 7.30908 12.2871C7.23308 12.1041 7.23308 11.8971 7.30908 11.7141C7.34708 11.6221 7.40195 11.539 7.47095 11.47L10.4709 8.46997C10.7639 8.17697 11.239 8.17697 11.532 8.46997C11.825 8.76297 11.825 9.23801 11.532 9.53101L9.81201 11.251H16C16.414 11.251 16.75 11.587 16.75 12.001C16.75 12.415 16.414 12.75 16 12.75Z" fill="#1AAA69" />
                                                </svg>
                                            </span>
                                            <span>{t('back_login')}</span>
                                        </a>
                                    </div>
                                    <div className="wt-login-heading">
                                        <h1 className="wt-section-title">{t('forgot_password')}</h1>
                                        <p className="wt-section-description">{t('forgot_password_text')}</p>
                                    </div>
                                    <div className="wt-login-form-area">
                                        <form className="row" onSubmit={handleSubmit}>
                                            <div className="col col-12 col-md-12">
                                                <label htmlFor="inputEmail" className="form-label">{t('email')}</label>
                                                <input type="email" className="form-control" id="inputEmail" placeholder={t('email')} value={email} onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                            <div className="col col-12">
                                                <button type="submit" className="btn btn-success w-100" disabled={disable}>{disable ? `${t('processing')}..` : t('send_link')}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-6">
                            <ImageSlider />
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default ForgotPassword