import React, { useEffect, useState } from 'react'
import Header from '../layouts/Header'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Notifications = () => {
    useEffect(() => {
        getList();
    }, []);
    const { t } = useTranslation();
    const [list, setList] = useState([]);
    function formatDateRange(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Get day, month, and year components
        const startDay = start.getDate();
        const startMonth = start.toLocaleString('default', { month: 'short' });
        const startYear = start.getFullYear();

        const endDay = end.getDate();
        const endMonth = end.toLocaleString('default', { month: 'short' });
        const endYear = end.getFullYear();

        // If start and end dates are in the same month and year
        if (startMonth === endMonth && startYear === endYear) {
            return `${startDay} - ${endDay} ${startMonth} ${startYear}`;
        } else {
            // If start and end dates are in different months or years
            return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
        }
    }

    function formatDate(date) {
        let dt = new Date(date);
        const day = dt.getDate();
        const month = dt.toLocaleString('default', { month: 'short' });
        const year = dt.getFullYear();
        return `${day} ${month} ${year}`
    }

    const getList = async () => {
        await getDocs(query(collection(db, 'notification'), where(localStorage.getItem('WEE_USER_TYPE') === "CUSTOMER" ? 'comstomer_id' : 'host_id', '==', localStorage.getItem('WEE_USER_ID'), orderBy('created_at', 'asc'))))
            .then((querysnapshot) => {
                let arr = [];
                querysnapshot.forEach((doc) => {
                    // console.log(doc.id)
                    // if(localStorage.getItem('WEE_USER_TYPE') === "CUSTOMER"){
                    arr.push({
                        title: localStorage.getItem('WEE_USER_TYPE') === "CUSTOMER" ?

                            t('sent_booking_notification', { name: doc.data().user_name, title: doc.data().post_name, date1: formatDate(doc.data().availability_start_date),date2: formatDate(doc.data().availability_end_date) }) :
                            // t('sent_booking_notification', { name: doc.data().user_name, title: doc.data().post_name, date: formatDateRange(doc.data().availability_start_date.split('T')[0], doc.data().availability_end_date.split('T')[0]) }) :
                            // `You sent Booking request for ${doc.data().post_name} at ${formatDateRange(doc.data().availability_start_date.split('T')[0], doc.data().availability_end_date.split('T')[0])} to ${doc.data().host_name}` :
                            t('got_booking_notification', { name: doc.data().user_name, title: doc.data().post_name, date1: formatDate(doc.data().availability_start_date),date2: formatDate(doc.data().availability_end_date)}),
                        // t('got_booking_notification', { name: doc.data().user_name, title: doc.data().post_name, date: formatDateRange(doc.data().availability_start_date.split('T')[0], doc.data().availability_end_date.split('T')[0]) }),
                        // `${doc.data().user_name} sent Booking request for ${doc.data().post_name} at ${formatDateRange(doc.data().availability_start_date.split('T')[0], doc.data().availability_end_date.split('T')[0])} to you`,
                        date: moment(doc.data().created_date?.split('T')[0]).format('MMM DD, YYYY'),
                        image: localStorage.getItem('WEE_USER_TYPE') === "CUSTOMER" ? doc?.data()?.user_profile : doc?.data()?.host_profile,
                        created_at: doc.data().created_date?.split('T')[0]
                    })
                    // }
                    // else{
                    //     arr.push({
                    //         title: `${doc.data().user_name} sent Booking request for ${doc.data().post_name} at ${formatDateRange(doc.data().availability_start_date.split('T')[0], doc.data().availability_end_date.split('T')[0])} to you`,
                    //         date: moment(doc.data().created_date?.split('T')[0]).format('MMM DD, YYYY'),
                    //         image: doc.data().host_profile,
                    //         created_at: doc.data().created_date?.split('T')[0]
                    //     })
                    // }
                });
                setList(arr?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
            })

    }
    return (
        <div className='wt-site'>
            <Header />
            <main className="wt-site-content">
                { /* Landing Section Start */}
                <section className="wt-notification-section">
                    <div className="wt-notification-header">
                        <div className="container">
                            <h1 className="wt-section-title">{t('notification')}</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="wt-notifications-content">
                            <div className="wt-notifications-list">
                                {list?.length > 0 ? list?.map((el) =>
                                    <div className="wt-notifications-item wt-notifications-active">
                                        <div className="wt-notifications-profile">
                                            <img src={el?.image ? el?.image : 'images/no-user.png'} alt="notification-img" className="img-fluid" />
                                        </div>
                                        <div className="wt-notifications-detail">
                                            <p className="wt-notifications-text">{el?.title}</p>
                                            <p className="wt-notifications-date">{el?.date}</p>
                                        </div>
                                    </div>) :
                                    <h4>{t('no_notification_found')}</h4>}
                            </div>
                        </div>
                    </div>
                </section>
                { /* Landing Section Start */}

            </main>
        </div>
    )
}

export default Notifications