import React from 'react'
import { useTranslation } from 'react-i18next';

const NotAMember = () => {
    const { t } = useTranslation();
    return (
        !localStorage.getItem('WEE_USER_ID') &&
        <section className="wt-not-member-section">
            <div className="container">
                <div className="wt-not-member-content">
                    <div className="row">
                        <div className="col col-12 col-md-6 col-lg-6">
                            <div className="wt-not-member-area">
                                <h2 className="wt-section-title">{t('not_member')}</h2>
                                <p className="wt-section-description">{t('not_member_text')}</p>
                            </div>
                        </div>
                        <div className="col col-12 col-md-6 col-lg-6">
                            <div className="wt-not-member-btn">
                                <a href="/login" className="btn btn-outline-success">
                                    <span>{t('login')}</span>
                                    <span className="wt-btn-icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.0003 5V15C20.0003 15.552 19.5533 16 19.0003 16C18.4473 16 18.0003 15.552 18.0003 15V7.41406L6.70731 18.707C6.51231 18.902 6.25628 19 6.00028 19C5.74428 19 5.48825 18.902 5.29325 18.707C4.90225 18.316 4.90225 17.684 5.29325 17.293L16.5862 6H9.00028C8.44728 6 8.00028 5.552 8.00028 5C8.00028 4.448 8.44728 4 9.00028 4H19.0003C19.1303 4 19.2604 4.0269 19.3824 4.0769C19.6274 4.1779 19.8224 4.37292 19.9234 4.61792C19.9744 4.73992 20.0003 4.87 20.0003 5Z" fill="#1AAA69" />
                                        </svg>
                                    </span>
                                </a>
                                <a href="/signup" className="btn btn-success">
                                    <span>{t('signup')}</span>
                                    <span className="wt-btn-icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.0003 5V15C20.0003 15.552 19.5533 16 19.0003 16C18.4473 16 18.0003 15.552 18.0003 15V7.41406L6.70731 18.707C6.51231 18.902 6.25628 19 6.00028 19C5.74428 19 5.48825 18.902 5.29325 18.707C4.90225 18.316 4.90225 17.684 5.29325 17.293L16.5862 6H9.00028C8.44728 6 8.00028 5.552 8.00028 5C8.00028 4.448 8.44728 4 9.00028 4H19.0003C19.1303 4 19.2604 4.0269 19.3824 4.0769C19.6274 4.1779 19.8224 4.37292 19.9234 4.61792C19.9744 4.73992 20.0003 4.87 20.0003 5Z" fill="#F5F5F5" />
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NotAMember