import React from 'react'
import Slider from 'react-slick'

const ImageSlider = () => {
    const setting = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
    }
    return (
        <Slider {...setting} className="wt-login-img-slider">
            <div className="wt-login-img-slider-item">
                <img src="images/login-img.jpeg" alt="Login" className="img-fluid w-100" />
            </div>
            <div className="wt-login-img-slider-item">
                <img src="images/login-img2.jpeg" alt="Login" className="img-fluid w-100" />
            </div>
            <div className="wt-login-img-slider-item">
                <img src="images/login-img.jpeg" alt="Login" className="img-fluid w-100" />
            </div>
        </Slider>
    )
}

export default ImageSlider