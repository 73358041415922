import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./components/lang/en.json";
import es from "./components/lang/es.json";
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: en
            },
            es: {
                translations: es
            },
        },
        lng: localStorage.getItem("WEE_USER_LAN") ? localStorage.getItem("WEE_USER_LAN").toLowerCase() : "es",
        fallbackLng: "es",
        debug: true,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
