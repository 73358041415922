import React, { useEffect } from 'react'
import { WEB_URL } from '../BaseURL'
import { useParams } from 'react-router-dom'
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../FirebaseConfig';

const StripeSetup = () => {
    const { id } = useParams();
    useEffect(() => {
        updateStripeStatus();
    }, [])
    const updateStripeStatus = async () => {
        await updateDoc(doc(db, 'user', id), {
            stripe_account_completed: true
        })
    }
    return (
        <div className='stripe-container'>
            <img src="/images/logo-dark-new2.png" />
            <div className='stripe-label'>
                <img src="/images/check-circle-icon.svg" />
                <span >Stripe Setup Completed</span>
            </div>
        </div>
    )
}

export default StripeSetup