import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { db } from '../FirebaseConfig';
import { Timestamp, collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { set } from 'firebase/database';

const DateModal = ({ activeModal, setActiveModal, dates, handleChangeDate, temp, flag }) => {
    const { t } = useTranslation();
    const [date, setDate] = useState({});
    const navigate = useNavigate();
    let handleChange;
    const [availableSlots, setAvailableSlots] = useState([]);
    const [availableSlots2, setAvailableSlots2] = useState([]);

    function convertTimeToDate(date, timeString) {
        const [hours, minutes] = timeString.split(':').map(Number);
        const time = new Date(date);
        time.setHours(hours, minutes, 0, 0);
        return time;
    }

    const getBookingsForPost = async (postId) => {
        try {
            const bookings = [];
            await getDocs(query(collection(db, 'my_booking'), where('post_id', '==', postId))).then(async (querysnapshot) => {
                await querysnapshot.forEach((doc) => {
                    bookings.push(doc.data());
                })
            });
            return bookings;
        } catch (error) {
            console.log(error)
            return [];
        }
    };

    const calculateAvailableSlotsForDay = async (selectedDate) => {
        const bookedDates = await getBookingsForPost(temp.id);
        const startOfDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0);
        const endOfDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 23, 59, 59);
        const blockStart = new Date(moment(Number(temp?.block_start_date?.seconds) * 1000).format());
        const blockEnd = new Date(moment(Number(temp?.block_end_date?.seconds) * 1000).format());
        let availablStart;
        let availablEnd;
        if (temp?.availability_start_date && temp?.availability_end_date) {
            availablStart = convertTimeToDate(moment(Number(temp?.availability_start_date?.seconds) * 1000).format(), temp?.check_in_time);
            availablEnd = convertTimeToDate(moment(Number(temp?.availability_end_date?.seconds) * 1000).format(), temp?.check_out_time);
        }
        let availableSlots = [{ start: startOfDay, end: endOfDay }];

        // First, handle booked times
        bookedDates.forEach((booking) => {
            const bookedStart = convertTimeToDate(booking.check_in_date.toDate(), booking?.check_in_time);
            const bookedEnd = convertTimeToDate(booking.check_out_date.toDate(), booking?.check_out_time);

            let newAvailableSlots = [];

            availableSlots.forEach((slot) => {
                const slotStart = slot.start;
                const slotEnd = slot.end;

                if (slotEnd <= bookedStart || slotStart >= bookedEnd) {
                    newAvailableSlots.push({ start: slotStart, end: slotEnd });
                } else {
                    if (slotStart < bookedStart) {
                        newAvailableSlots.push({ start: slotStart, end: bookedStart });
                    }
                    if (slotEnd > bookedEnd) {
                        newAvailableSlots.push({ start: bookedEnd, end: slotEnd });
                    }
                }
            });

            availableSlots = newAvailableSlots;
        });

        // Then, handle block times
        let newAvailableSlots = [];
        if (temp?.block_start_date && temp?.block_end_date) {
            availableSlots.forEach((slot) => {
                const slotStart = slot.start;
                const slotEnd = slot.end;

                if (slotEnd <= blockStart || slotStart >= blockEnd) {
                    newAvailableSlots.push({ start: slotStart, end: slotEnd });
                } else {
                    if (slotStart < blockStart) {
                        newAvailableSlots.push({ start: slotStart, end: blockStart });
                    }
                    if (slotEnd > blockEnd) {
                        newAvailableSlots.push({ start: blockEnd, end: slotEnd });
                    }
                }
            });
            availableSlots = newAvailableSlots;
        }


        // Finally, handle availability hours
        newAvailableSlots = [];

        availableSlots.forEach((slot) => {
            const slotStart = slot.start;
            const slotEnd = slot.end;

            if (slotEnd <= availablStart || slotStart >= availablEnd) {
                // Slot is completely outside the available hours
            } else {
                if (slotStart < availablStart && slotEnd > availablStart) {
                    // Adjust the start of the slot to the availability start
                    newAvailableSlots.push({ start: availablStart, end: slotEnd });
                } else if (slotStart < availablEnd && slotEnd > availablEnd) {
                    // Adjust the end of the slot to the availability end
                    newAvailableSlots.push({ start: slotStart, end: availablEnd });
                } else if (slotStart >= availablStart && slotEnd <= availablEnd) {
                    // Slot is completely within the available hours
                    newAvailableSlots.push({ start: slotStart, end: slotEnd });
                }
            }
        });

        availableSlots = newAvailableSlots;

        newAvailableSlots = [];

        availableSlots.forEach((slot) => {
            const slotStart = slot.start;
            const slotEnd = slot.end;

            if ((Math.abs(slotEnd - slotStart) / (1000 * 60)) > 58) {
                if (slotEnd > new Date()) {
                    const updatedSlotStart = slotStart < new Date() ? new Date() : slotStart;
                    newAvailableSlots.push({ start: updatedSlotStart, end: slotEnd });
                }
            }
        });

        availableSlots = newAvailableSlots;

        availableSlots = availableSlots.filter(slot => slot.start.getTime() !== slot.end.getTime());
        availableSlots.sort((a, b) => a.start - b.start);

        if (flag === "host") {
            setAvailableSlots2(availableSlots);
        } else {
            setAvailableSlots(availableSlots);
        }

        // setAvailableSlots(availableSlots);

    };


    const highlightDates = {
        red: [],
        orange: [],
    };

    const init = async () => {
        if (temp) {
            const bookings1 = await getBookingsForPost(temp.id);
            const block_start = temp?.block_start_date ? moment(Number(temp?.block_start_date?.seconds) * 1000).format() : null;
            const block_end = temp?.block_end_date ? moment(Number(temp?.block_end_date?.seconds) * 1000).format() : null;
            const availablStart = convertTimeToDate(moment(Number(temp?.availability_start_date?.seconds) * 1000).format(), temp?.check_in_time);
            const availablEnd = convertTimeToDate(moment(Number(temp?.availability_end_date?.seconds) * 1000).format(), temp?.check_out_time);

            let bookingHours = {};
            let last = new Date(block_end);
            let first = new Date(block_start);

            // Handling block hours
            if (block_start && block_end) {
                let currentDate1 = new Date(block_start);
                while (currentDate1 <= last) {
                    let startHour = currentDate1.getDate() === first.getDate() ? first.getHours() : 0;
                    let endHour = currentDate1.getDate() === last.getDate() ? last.getHours() : 24;

                    if (!bookingHours[currentDate1]) {
                        bookingHours[currentDate1] = [];
                    }

                    for (let hour = startHour; hour < endHour; hour++) {
                        bookingHours[currentDate1].push(hour);
                    }

                    currentDate1.setDate(currentDate1.getDate() + 1);
                    currentDate1 = new Date(currentDate1.getFullYear(), currentDate1.getMonth(), currentDate1.getDate()); // Ensure it's only the date part
                }
            }

            // Handling bookings
            bookings1.length > 0 && bookings1.forEach((booking) => {
                const checkInDate = convertTimeToDate(booking.check_in_date.toDate(), booking?.check_in_time);
                const checkOutDate = convertTimeToDate(booking.check_out_date.toDate(), booking?.check_out_time);

                const firstDay = new Date(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate());
                const lastDay = new Date(checkOutDate.getFullYear(), checkOutDate.getMonth(), checkOutDate.getDate());
                let currentDate = new Date(firstDay);

                while (currentDate <= lastDay) {
                    let startHour = currentDate.getDate() === checkInDate.getDate() ? checkInDate.getHours() : 0;
                    let endHour = currentDate.getDate() === checkOutDate.getDate() ? checkOutDate.getHours() : 24;

                    if (!bookingHours[currentDate]) {
                        bookingHours[currentDate] = [];
                    }

                    for (let hour = startHour; hour < endHour; hour++) {
                        bookingHours[currentDate].push(hour);
                    }

                    currentDate.setDate(currentDate.getDate() + 1);
                    currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()); // Ensure it's only the date part
                }
            });

            // Handling the availability start and end dates
            const availabilityStartDate = new Date(availablStart.getFullYear(), availablStart.getMonth(), availablStart.getDate());
            const availabilityEndDate = new Date(availablEnd.getFullYear(), availablEnd.getMonth(), availablEnd.getDate());

            for (let date in bookingHours) {
                const hours = bookingHours[date];
                hours.sort();

                const parsedDate = new Date(date);

                if (parsedDate.getTime() === availabilityStartDate.getTime()) {
                    const startHour = availablStart.getHours();
                    const isFullyBooked = hours.filter(hour => hour >= startHour).length === (24 - startHour);
                    if (isFullyBooked && parsedDate >= new Date()) {
                        highlightDates.red.push(parsedDate);
                        continue; // No need to check further conditions for this date
                    }
                }

                if (parsedDate.getTime() === availabilityEndDate.getTime()) {
                    const endHour = availablEnd.getHours();
                    const isFullyBooked = hours.filter(hour => hour < endHour).length === endHour;
                    if (isFullyBooked && parsedDate >= new Date()) {
                        highlightDates.red.push(parsedDate);
                        continue; // No need to check further conditions for this date
                    }
                }

                if (hours.length >= 24 && parsedDate >= new Date()) {
                    highlightDates.red.push(parsedDate);
                } else if (hours.length > 0 && parsedDate >= new Date()) {
                    highlightDates.orange.push(parsedDate);
                }
            }
        }
    };


    // const init = async () => {
    //     const bookings1 = await getBookingsForPost(temp.id);
    //     const block_start = temp?.block_start_date ? moment(Number(temp?.block_start_date?.seconds) * 1000).format() : null;
    //     const block_end = temp?.block_end_date ? moment(Number(temp?.block_end_date?.seconds) * 1000).format() : null;
    //     const availablStart = convertTimeToDate(moment(Number(temp?.availability_start_date?.seconds) * 1000).format(), temp?.check_in_time);
    //     const availablEnd = convertTimeToDate(moment(Number(temp?.availability_end_date?.seconds) * 1000).format(), temp?.check_out_time);

    //     let bookingHours = {};
    //     let last = new Date(block_end)
    //     let first = new Date(block_start)
    //     if (block_start && block_end) {
    //         let currentDate1 = new Date(block_start);
    //         while (currentDate1 <= last) {
    //             let startHour = currentDate1.getDate() === first.getDate() ? first.getHours() : 0;
    //             let endHour = currentDate1.getDate() === last.getDate() ? last.getHours() : 24;

    //             if (!bookingHours[currentDate1]) {
    //                 bookingHours[currentDate1] = [];
    //             }

    //             for (let hour = startHour; hour < endHour; hour++) {
    //                 bookingHours[currentDate1].push(hour);
    //             }

    //             currentDate1.setDate(currentDate1.getDate() + 1);
    //             currentDate1 = new Date(currentDate1.getFullYear(), currentDate1.getMonth(), currentDate1.getDate()); // Ensure it's only the date part
    //         }
    //     }


    //     bookings1.length > 0 && bookings1.forEach((booking) => {
    //         const checkInDate = convertTimeToDate(booking.check_in_date.toDate(), booking?.check_in_time);
    //         const checkOutDate = convertTimeToDate(booking.check_out_date.toDate(), booking?.check_out_time);

    //         const firstDay = new Date(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate());
    //         const lastDay = new Date(checkOutDate.getFullYear(), checkOutDate.getMonth(), checkOutDate.getDate());
    //         let currentDate = new Date(firstDay);

    //         while (currentDate <= lastDay) {
    //             let startHour = currentDate.getDate() === checkInDate.getDate() ? checkInDate.getHours() : 0;
    //             let endHour = currentDate.getDate() === checkOutDate.getDate() ? checkOutDate.getHours() : 24;

    //             if (!bookingHours[currentDate]) {
    //                 bookingHours[currentDate] = [];
    //             }

    //             for (let hour = startHour; hour < endHour; hour++) {
    //                 bookingHours[currentDate].push(hour);
    //             }

    //             currentDate.setDate(currentDate.getDate() + 1);
    //             currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()); // Ensure it's only the date part
    //         }
    //     });

    //     for (let date in bookingHours) {
    //         const hours = bookingHours[date];
    //         hours.sort();

    //         const parsedDate = new Date(date);
    //         if (hours.length >= 24 && parsedDate >= new Date()) {
    //             highlightDates.red.push(parsedDate);
    //         } else if (hours.length > 0 && parsedDate >= new Date()) {
    //             highlightDates.orange.push(parsedDate);
    //         }
    //     }
    // };


    init();
    // console.log(highlightDates)
    useEffect(() => {
        if (date.start_date && date.end_date) {
            calculateAvailableSlotsForDay(new Date(date.end_date));
            // console.log(availableSlots)
        }
    }, [date?.start_date, date?.end_date]);

    if (flag === "host") {
        useEffect(() => {
            console.log("hi")
            if (date?.block_start_date && date?.block_end_date) {
                calculateAvailableSlotsForDay(new Date(date?.block_end_date));
            }
        }, [date?.block_start_date, date?.block_end_date]);
    }

    const highlightWithDot = (date) => {
        if (highlightDates.red.some((highlightDate) => isSameDay(date, highlightDate))) {
            return "highlight-red";
        }
        if (highlightDates.orange.some((highlightDate) => isSameDay(date, highlightDate))) {
            return "highlight-blue";
        }
        return undefined;
    };

    const isSameDay = (d1, d2) =>
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear();

    if (flag === "host") {

        useEffect(() => {
            if (temp?.block_start_date) {
                const date1 = temp?.block_start_date.toDate();
                const hours1 = date1.getHours().toString().padStart(2, '0');
                const minutes1 = date1.getMinutes().toString().padStart(2, '0');
                const date2 = temp?.block_end_date.toDate();
                const hours2 = date2.getHours().toString().padStart(2, '0');
                const minutes2 = date2.getMinutes().toString().padStart(2, '0');
                setDate({
                    block_start_date: moment(Number(temp?.block_start_date?.seconds) * 1000).format("YYYY-MM-DD"),
                    block_end_date: moment(Number(temp?.block_end_date?.seconds) * 1000).format("YYYY-MM-DD"),
                    block_start_time: hours1 + ':' + minutes1,
                    block_end_time: hours2 + ':' + minutes2
                });
            }
            else {
                setDate({
                    block_start_date: new Date().toISOString().split('T')[0],
                    block_end_date: new Date().toISOString().split('T')[0],
                    block_start_time: "00:00",
                    block_end_time: "00:00"
                });
            }
        }, [activeModal]);

        handleChange = async () => {

            function convertTimeToDate(date, timeString) {
                const [hours, minutes] = timeString.split(':').map(Number);
                const time = new Date(date);
                time.setHours(hours, minutes, 0, 0); // Set hours and minutes, seconds and milliseconds to 0
                return time;
            }
            const timeRangeStart = convertTimeToDate(date?.block_start_date, date?.block_start_time);
            const timeRangeEnd = convertTimeToDate(date?.block_end_date, date?.block_end_time);
            let isAvailable;
            try {
                const bookings = await getBookingsForPost(temp.id);
                isAvailable = bookings.length > 0 ? bookings.every(booking => {
                    const bookingStart = convertTimeToDate(booking.check_in_date.toDate(), booking?.check_in_time);
                    const bookingEnd = convertTimeToDate(booking.check_out_date.toDate(), booking?.check_out_time);
                    const isOverlapping =
                        (timeRangeStart <= bookingEnd && timeRangeEnd >= bookingStart) ||
                        (timeRangeStart <= bookingEnd && timeRangeStart >= bookingStart) ||
                        (timeRangeEnd <= bookingEnd && timeRangeEnd >= bookingStart);

                    return !isOverlapping;
                }) : true;

            } catch (error) {
                console.log(error)
            }


            const check = timeRangeStart >= timeRangeEnd;
            const check2 = (timeRangeStart >= new Date() && timeRangeEnd >= new Date());
            const timeDiffInMinutes = Math.abs(timeRangeEnd - timeRangeStart) / (1000 * 60);
            if (check) {
                toast.error(`${t('invalid_dates')}`, { style: { background: '#333', color: '#fff' } })
            }
            else if (!check2) {
                toast.error(`${t('invalid_dates')}`, { style: { background: '#333', color: '#fff' } })
            }
            else if (timeDiffInMinutes < 58) {
                toast.error(`${t('minimum_time')}`, { style: { background: '#333', color: '#fff' } })
            }
            else if (!isAvailable) {
                toast.error(t('yatch_is_booked'), { style: { background: '#333', color: '#fff' } });
            }
            else {
                try {
                    await updateDoc(doc(db, 'post', temp?.id), {
                        block_start_date: Timestamp.fromDate(new Date(timeRangeStart)),
                        block_end_date: Timestamp.fromDate(new Date(timeRangeEnd)),
                    });
                    toast.success(t('yacht_blocked'), { style: { background: '#333', color: '#fff' } });
                    setActiveModal();
                } catch (error) {
                    console.log(error)
                }
            }
        }
    } else {

        const handleDate = (val) => {
            setDate({
                start_date: new Date(val[0]['$d']).toLocaleDateString(),
                end_date: val[1] === null ? new Date(val[0]['$d']).toLocaleDateString() : new Date(val[1]['$d']).toLocaleDateString()
            })
        }

        function isTimeRangeWithin(timeRangeStart, timeRangeEnd, targetStart, targetEnd) {
            if (timeRangeStart >= targetStart && timeRangeEnd <= targetEnd) {
                return true;
            } else {
                return false;
            }
        }

        function tConvert(time) {
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) {
                time = time.slice(1);
                time[5] = +time[0] < 12 ? ' AM' : ' PM';
                time[0] = +time[0] % 12 || 12;
            }
            return time.join('');
        }

        function isDateRangeWithin(dateRangeStart, dateRangeEnd, targetStart, targetEnd) {
            // Check for completely inside
            if (dateRangeStart >= targetStart && dateRangeEnd <= targetEnd) {
                return true;
            } else {
                return false;
            }
        }



        if (temp?.block_start_date && temp?.block_end_date) {
            handleChange = async () => {
                let target = {
                    start: moment(Number(temp?.availability_start_date?.seconds) * 1000).format("YYYY-MM-DD"),
                    end: moment(Number(temp?.availability_end_date?.seconds) * 1000).format("YYYY-MM-DD"),
                    start_time: temp?.check_in_time,
                    end_time: temp?.check_out_time
                }

                const date1 = moment(Number(temp?.block_start_date?.seconds) * 1000).format();
                const timePart = date1.split('T')[1];
                const [hours, minutes] = timePart.split(':');
                const formattedTime = `${hours}:${minutes}`;
                const date2 = moment(Number(temp?.block_end_date?.seconds) * 1000).format();
                const timePart1 = date2.split('T')[1];
                const [hours1, minutes1] = timePart1.split(':');
                const formattedTime1 = `${hours1}:${minutes1}`;

                let block = {
                    start: moment(Number(temp?.block_start_date?.seconds) * 1000).format("YYYY-MM-DD"),
                    end: moment(Number(temp?.block_end_date?.seconds) * 1000).format("YYYY-MM-DD"),
                    start_time: formattedTime,
                    end_time: formattedTime1
                }

                const dateRangeStart = new Date(date?.start_date);
                const dateRangeEnd = new Date(date?.end_date);
                const targetStart = new Date(target?.start);
                const targetEnd = new Date(target?.end);

                const blockStart = convertTimeToDate(block?.start, block?.start_time);
                const blockEnd = convertTimeToDate(block?.end, block?.end_time);

                const timeRangeStart = convertTimeToDate(date?.start_date, date?.check_in_time);
                const timeRangeEnd = convertTimeToDate(date?.end_date, date?.check_out_time);
                const targetStartTime = convertTimeToDate(target?.start, target?.start_time);
                const targetEndTime = convertTimeToDate(target?.end, target?.end_time);

                const isWithin = isDateRangeWithin(dateRangeStart, dateRangeEnd, targetStart, targetEnd);
                const isTimeWithin = isTimeRangeWithin(timeRangeStart, timeRangeEnd, targetStartTime, targetEndTime);
                const check = timeRangeStart >= timeRangeEnd;
                const timeDiffInMinutes = Math.abs(timeRangeEnd - timeRangeStart) / (1000 * 60);
                const isOverlapping2 =
                    (timeRangeStart <= blockEnd && timeRangeEnd >= blockStart) ||
                    (timeRangeStart <= blockEnd && timeRangeStart >= blockStart) ||
                    (timeRangeEnd <= blockEnd && timeRangeEnd >= blockStart);
                const check2 = (timeRangeStart >= new Date() && timeRangeEnd >= new Date());
                // console.log(isOverlapping2)
                if (check) {
                    toast.error(`${t('invalid_dates')}`, { style: { background: '#333', color: '#fff' } })
                }
                else if (!check2) {
                    toast.error(`${t('invalid_dates')}`, { style: { background: '#333', color: '#fff' } })
                }
                else if (timeDiffInMinutes < 58) {
                    toast.error(`${t('minimum_time')}`, { style: { background: '#333', color: '#fff' } })
                }
                else if (isOverlapping2) {
                    toast.error(`${t('blocked')}`, { style: { background: '#333', color: '#fff' } })
                }
                else if (!(isWithin && isTimeWithin)) {
                    // console.log("test1")
                    toast.error(`${t('invalid_times')} ${new Date(Number(temp?.availability_start_date?.seconds) * 1000).toDateString()} ${tConvert(temp?.check_in_time)} to ${new Date(Number(temp?.availability_end_date?.seconds) * 1000).toDateString()} ${tConvert(temp?.check_out_time)}`, { style: { background: '#333', color: '#fff' } })
                }
                else {
                    // console.log("test2")
                    try {
                        const bookings = await getBookingsForPost(temp.id);
                        // console.log(bookings[0].check_out_time)
                        // console.log(bookings)
                        const isAvailable = bookings.length > 0 ? bookings.every(booking => {
                            const bookingStart = convertTimeToDate(booking.check_in_date.toDate(), booking?.check_in_time);
                            const bookingEnd = convertTimeToDate(booking.check_out_date.toDate(), booking?.check_out_time);
                            const isOverlapping =
                                (timeRangeStart <= bookingEnd && timeRangeEnd >= bookingStart) ||
                                (timeRangeStart <= bookingEnd && timeRangeStart >= bookingStart) ||
                                (timeRangeEnd <= bookingEnd && timeRangeEnd >= bookingStart);

                            return !isOverlapping;
                        }) : true;
                        // console.log(isAvailable)

                        if (isAvailable) {
                            handleChangeDate(date);
                        } else {
                            toast.error(t('date_or_time_unavailable'), { style: { background: '#333', color: '#fff' } });
                        }

                    } catch (error) {
                        console.log(error)
                    }
                }
            }
        }
        else {
            handleChange = async () => {

                let target = {
                    start: moment(Number(temp?.availability_start_date?.seconds) * 1000).format("YYYY-MM-DD"),
                    end: moment(Number(temp?.availability_end_date?.seconds) * 1000).format("YYYY-MM-DD"),
                    start_time: temp?.check_in_time,
                    end_time: temp?.check_out_time
                }

                const dateRangeStart = new Date(date?.start_date);
                const dateRangeEnd = new Date(date?.end_date);
                const targetStart = new Date(target?.start);
                const targetEnd = new Date(target?.end);


                const timeRangeStart = convertTimeToDate(date?.start_date, date?.check_in_time);
                const timeRangeEnd = convertTimeToDate(date?.end_date, date?.check_out_time);
                const targetStartTime = convertTimeToDate(target?.start, target?.start_time);
                const targetEndTime = convertTimeToDate(target?.end, target?.end_time);

                const isWithin = isDateRangeWithin(dateRangeStart, dateRangeEnd, targetStart, targetEnd);
                const isTimeWithin = isTimeRangeWithin(timeRangeStart, timeRangeEnd, targetStartTime, targetEndTime);
                const timeDiffInMinutes = Math.abs(timeRangeEnd - timeRangeStart) / (1000 * 60);
                const check2 = (timeRangeStart >= new Date() && timeRangeEnd >= new Date());
                // console.log(isTimeWithin)
                const check = timeRangeStart >= timeRangeEnd;
                if (check) {
                    toast.error(`${t('invalid_dates')}`, { style: { background: '#333', color: '#fff' } })
                }
                else if (!check2) {
                    toast.error(`${t('invalid_dates')}`, { style: { background: '#333', color: '#fff' } })
                }
                else if (timeDiffInMinutes < 58) {
                    toast.error(`${t('minimum_time')}`, { style: { background: '#333', color: '#fff' } })
                }
                else if (!(isWithin && isTimeWithin)) {
                    // console.log("test1")
                    toast.error(`${t('invalid_times')} ${new Date(Number(temp?.availability_start_date?.seconds) * 1000).toDateString()} ${tConvert(temp?.check_in_time)} to ${new Date(Number(temp?.availability_end_date?.seconds) * 1000).toDateString()} ${tConvert(temp?.check_out_time)}`, { style: { background: '#333', color: '#fff' } })
                }
                else {
                    // console.log("test2")
                    try {
                        const bookings = await getBookingsForPost(temp.id);
                        // console.log(bookings[0].check_out_time)
                        const isAvailable = bookings.length > 0 ? bookings.every(booking => {
                            const bookingStart = convertTimeToDate(booking.check_in_date.toDate(), booking?.check_in_time);
                            const bookingEnd = convertTimeToDate(booking.check_out_date.toDate(), booking?.check_out_time);
                            const isOverlapping =
                                (timeRangeStart <= bookingEnd && timeRangeEnd >= bookingStart) ||
                                (timeRangeStart <= bookingEnd && timeRangeStart >= bookingStart) ||
                                (timeRangeEnd <= bookingEnd && timeRangeEnd >= bookingStart);

                            return !isOverlapping;
                        }) : true;
                        // console.log(isAvailable)

                        if (isAvailable) {
                            handleChangeDate(date);
                        } else {
                            toast.error(t('date_or_time_unavailable'), { style: { background: '#333', color: '#fff' } });
                        }

                    } catch (error) {
                        console.log(error)
                    }
                }
            }
        }

        useEffect(() => {
            setDate({
                start_date: (new Date(dates.start_date) < new Date()) ? moment(new Date()).format("YYYY-MM-DD") : dates.start_date,
                end_date: dates.end_date,
                check_in_time: dates?.check_in_time ? dates?.check_in_time : "00:00",
                check_out_time: dates?.check_out_time ? dates?.check_out_time : "00:00"
            });
        }, [activeModal]);

    }

    return (
        <Modal
            className="modal fade wt-confirm-modal"
            id="confirmGuestModal"
            show={activeModal}
            onHide={setActiveModal}
            backdrop="static"
            keyboard={false}
            size='lg'
        >
            <Modal.Header>
                {flag === "host" ?
                    (<>
                        <h3 style={{ margin: 'auto', width: '100%', marginLeft: '40%', marginTop: "1%" }}>{t('block_yacht')}</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={setActiveModal}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 1.25C6.072 1.25 1.25 6.073 1.25 12C1.25 17.927 6.072 22.75 12 22.75C17.928 22.75 22.75 17.927 22.75 12C22.75 6.073 17.928 1.25 12 1.25ZM12 21.25C6.899 21.25 2.75 17.101 2.75 12C2.75 6.899 6.899 2.75 12 2.75C17.101 2.75 21.25 6.899 21.25 12C21.25 17.101 17.101 21.25 12 21.25ZM15.53 9.53003L13.06 12L15.53 14.47C15.823 14.763 15.823 15.238 15.53 15.531C15.384 15.677 15.192 15.751 15 15.751C14.808 15.751 14.616 15.678 14.47 15.531L12 13.061L9.53 15.531C9.384 15.677 9.192 15.751 9 15.751C8.808 15.751 8.616 15.678 8.47 15.531C8.177 15.238 8.177 14.763 8.47 14.47L10.94 12L8.47 9.53003C8.177 9.23703 8.177 8.76199 8.47 8.46899C8.763 8.17599 9.23801 8.17599 9.53101 8.46899L12.001 10.939L14.471 8.46899C14.764 8.17599 15.239 8.17599 15.532 8.46899C15.823 8.76199 15.823 9.23803 15.53 9.53003Z"
                                    fill="#25314C" />
                            </svg>
                        </button>
                    </>) : (
                        <>
                            <h3 style={{ margin: 'auto', width: '100%', marginLeft: '35%', marginTop: "1%" }}>{t('edit_yacht')}</h3>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { navigate('/accomodation-detail', { state: temp }); }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12 1.25C6.072 1.25 1.25 6.073 1.25 12C1.25 17.927 6.072 22.75 12 22.75C17.928 22.75 22.75 17.927 22.75 12C22.75 6.073 17.928 1.25 12 1.25ZM12 21.25C6.899 21.25 2.75 17.101 2.75 12C2.75 6.899 6.899 2.75 12 2.75C17.101 2.75 21.25 6.899 21.25 12C21.25 17.101 17.101 21.25 12 21.25ZM15.53 9.53003L13.06 12L15.53 14.47C15.823 14.763 15.823 15.238 15.53 15.531C15.384 15.677 15.192 15.751 15 15.751C14.808 15.751 14.616 15.678 14.47 15.531L12 13.061L9.53 15.531C9.384 15.677 9.192 15.751 9 15.751C8.808 15.751 8.616 15.678 8.47 15.531C8.177 15.238 8.177 14.763 8.47 14.47L10.94 12L8.47 9.53003C8.177 9.23703 8.177 8.76199 8.47 8.46899C8.763 8.17599 9.23801 8.17599 9.53101 8.46899L12.001 10.939L14.471 8.46899C14.764 8.17599 15.239 8.17599 15.532 8.46899C15.823 8.76199 15.823 9.23803 15.53 9.53003Z"
                                        fill="#25314C" />
                                </svg>
                            </button>
                        </>
                    )}
            </Modal.Header>
            <Modal.Body>
                <div class="wt-date-content-area">
                    <div class="wt-date-content-head d-flex flex-column" style={{ justifyContent: 'center' }}>
                        <div className='d-flex flex-row'>
                            <div class="wt-date-content-head__inner">
                                <h5 class="wt-date-content-head-title">{flag === "host" ? t('start_date') : t('check_in_date')}</h5>
                                {/* <p class="wt-date-content-head-date wt-check-in-date">{moment(date?.start_date).format('DD/MM/YYYY')}</p> */}
                                {flag === "host" ? (
                                    <DatePicker
                                        className='form-control temp'
                                        dateFormat='dd-MM-yyyy'
                                        selected={new Date(date?.block_start_date) >= new Date() ? new Date(date?.block_start_date) : new Date()}
                                        minDate={new Date().toISOString().split('T')[0]}
                                        // maxDate={temp.end_date ? new Date(temp.end_date).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
                                        dayClassName={(date) => highlightWithDot(date)}
                                        excludeDates={highlightDates.red}
                                        showIcon
                                        onChange={(d) => {
                                            if (d) {
                                                const formattedDate = d.toISOString().split('T')[0];
                                                setDate({ ...date, block_start_date: formattedDate });
                                            }
                                        }}
                                        value={new Date(date?.block_start_date) >= new Date() ? moment(date?.block_start_date).format('DD/MM/YYYY') : moment(new Date()).format('DD/MM/YYYY')} />
                                ) :
                                    <DatePicker
                                        className='form-control temp'
                                        dateFormat='dd-MM-yyyy'
                                        selected={date?.start_date ? new Date(date?.start_date) : new Date()}
                                        minDate={new Date().toISOString().split('T')[0]}
                                        maxDate={temp.end_date ? new Date(temp.end_date).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
                                        dayClassName={(date) => highlightWithDot(date)}
                                        excludeDates={highlightDates.red}
                                        showIcon
                                        onChange={(d) => {
                                            if (d) {
                                                const formattedDate = d.toISOString().split('T')[0];
                                                setDate({ ...date, start_date: formattedDate });
                                            }
                                        }}
                                        value={moment(date?.start_date).format('DD/MM/YYYY')} />}
                            </div>
                            <div class="wt-date-content-icon" style={{ marginTop: '40px' }}>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 12.5H19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M12 5.5L19 12.5L12 19.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </div>
                            <div class="wt-date-content-head__inner">
                                <h5 class="wt-date-content-head-title">{flag === "host" ? t('end_date') : t('check_out_date')}</h5>
                                {flag === "host" ? (
                                    <DatePicker
                                        className='form-control temp'
                                        dateFormat='dd-MM-yyyy'
                                        selected={new Date(date?.block_end_date) >= new Date() ? new Date(date?.block_end_date) : new Date()}
                                        minDate={new Date().toISOString().split('T')[0]}
                                        dayClassName={(date) => highlightWithDot(date)}
                                        excludeDates={highlightDates.red}
                                        showIcon
                                        onChange={(d) => {
                                            if (d) {
                                                const formattedDate = d.toISOString().split('T')[0];
                                                setDate({ ...date, block_end_date: formattedDate });
                                            }
                                        }}
                                        value={moment(date?.block_end_date).format('DD/MM/YYYY')} />
                                ) :
                                    <DatePicker
                                        className='form-control temp'
                                        dateFormat='dd-MM-yyyy'
                                        selected={date?.end_date ? new Date(date?.end_date) : new Date()}
                                        minDate={new Date().toISOString().split('T')[0]}
                                        maxDate={temp.end_date ? new Date(temp.end_date).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
                                        dayClassName={(date) => highlightWithDot(date)}
                                        excludeDates={highlightDates.red}
                                        showIcon
                                        onChange={(d) => {
                                            if (d) {
                                                const formattedDate = d.toISOString().split('T')[0];
                                                setDate({ ...date, end_date: formattedDate });
                                            }
                                        }}
                                        value={moment(date?.end_date).format('DD/MM/YYYY')} />}
                            </div>
                        </div>

                        <div className='d-flex flex-row mt-5'>
                            <div class="wt-date-content-head__inner">
                                <h5 class="wt-date-content-head-title">{flag === "host" ? t('start_time') : t('check_in_time')}</h5>
                                {flag === "host" ?
                                    <input type='time' className='form-control' onChange={(e) => setDate({ ...date, block_start_time: e.target.value })} value={date?.block_start_time} /> :
                                    <input type='time' className='form-control' onChange={(e) => setDate({ ...date, check_in_time: e.target.value })} value={date?.check_in_time} />}
                            </div>
                            <div class="wt-date-content-icon" style={{ marginTop: '40px' }}>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 12.5H19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M12 5.5L19 12.5L12 19.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </div>
                            <div class="wt-date-content-head__inner">
                                <h5 class="wt-date-content-head-title">{flag === "host" ? t('end_time') : t('check_out_time')}</h5>
                                {flag === "host" ?
                                    <input type='time' className='form-control' onChange={(e) => setDate({ ...date, block_end_time: e.target.value })} value={date?.block_end_time} />
                                    :
                                    <input type='time' className='form-control' onChange={(e) => setDate({ ...date, check_out_time: e.target.value })} value={date?.check_out_time} />}
                            </div>
                        </div>

                        {flag === "host" ?
                            (date.block_start_date && date.block_end_date) ?
                                <div className='d-flex flex-column mt-5'>
                                    {availableSlots2.length > 0 ? <h5 style={{ alignSelf: 'center' }}>Available slots to block {moment(date?.block_end_date).format('DD/MM/YYYY')}</h5> :
                                        <h5 style={{ alignSelf: 'center' }}>Available slots to block</h5>}
                                    <div className='d-flex flex-column'>
                                        {availableSlots2.length > 0 ? availableSlots2.map((slot, index) => {
                                            const startTime = moment(slot.start).format('hh:mm A') === '12:00 AM'
                                                ? moment(slot.start)
                                                : moment(slot.start).add(1, 'minute');
                                            const endTime = moment(slot.end).format('hh:mm A') === '11:59 PM'
                                                ? moment(slot.end)
                                                : moment(slot.end).subtract(1, 'minute');
                                            return (
                                                <>
                                                    <p key={index} style={{ marginBottom: "-2px", alignSelf: 'center' }}>{startTime.format('hh:mm A')} - {endTime.format('hh:mm A')}</p>
                                                </>
                                            )
                                        }) : <p style={{ alignSelf: 'center' }}>No slots are available</p>}
                                    </div>
                                </div> : null :
                            (date.start_date && date.end_date) ?
                                <div className='d-flex flex-column mt-5'>
                                    {availableSlots.length > 0 ? <h5 style={{ alignSelf: 'center' }}>Available slots for {moment(date?.end_date).format('DD/MM/YYYY')}</h5> :
                                        <h5 style={{ alignSelf: 'center' }}>Available slots</h5>}
                                    <div className='d-flex flex-column'>
                                        {availableSlots.length > 0 ? availableSlots.map((slot, index) => {
                                            const startTime = moment(slot.start).format('hh:mm A') === '12:00 AM'
                                                ? moment(slot.start)
                                                : moment(slot.start).add(1, 'minute');
                                            const endTime = moment(slot.end).format('hh:mm A') === '11:59 PM'
                                                ? moment(slot.end)
                                                : moment(slot.end).subtract(1, 'minute');
                                            return (
                                                <>
                                                    <p key={index} style={{ marginBottom: "-2px", alignSelf: 'center' }}>{startTime.format('hh:mm A')} - {endTime.format('hh:mm A')}</p>
                                                </>
                                            )
                                        }) : <p style={{ alignSelf: 'center' }}>No slots are available</p>}
                                    </div>
                                </div> : null}

                    </div>
                    <div class="wt-date-content-body" style={{ display: 'flex', justifyContent: 'center', alignItems: "center", gap: "20px", margin: "25px 0" }}>
                    </div>
                    <div className="wt-confirm-pay-btn" style={{ textAlign: 'center' }}>
                        <button type="button" className="btn btn-success" onClick={handleChange} >{flag === "host" ? t('block_yacht') : t('update_dates')}</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DateModal