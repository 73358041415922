import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import toast from 'react-hot-toast';
import { db } from '../FirebaseConfig';

const WithdrawModal = ({ activeModal, setActiveModal, profile }) => {
    const [details, setDetails] = useState({
        price: '',
        bank_name: '',
        account_no: '',
        ifsc_code: ''
    });

    const handleChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value });
    }
    const balance = Number(profile?.my_earnings) - (Number(profile?.total_payout) || 0);

    function validateBankAccountNumber(accountNumber) {
        const regex = /^\d{10,18}$/;
        return regex.test(accountNumber);
    }

    function validateIFSCCode(ifscCode) {
        const regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        return regex.test(ifscCode);
    }

    function validPrice(price) {
        const regex = /^[0-9]*$/;
        return regex.test(price);
    }

    const validation = () => {
        let err = {};
        for (let key in details) {
            // if (!details[key]) {
            //     err[key + '_err'] = "Please enter " + key.replace(/_/g, " ");
            //     toast.error('Please enter ' + key.replace(/_/g, " "), { style: { background: '#333', color: '#fff' } });
            // }
            if (key === "price" && details?.price > balance && !validPrice(details?.price)) {
                err['price_err'] = "Please enter valid amount";
                toast.error('Please enter valid amount', { style: { background: '#333', color: '#fff' } });
            }
            // if (key === "account_no" && !validateBankAccountNumber(details?.account_no)) {
            //     err['account_no_err'] = "Please enter valid account number";
            //     toast.error('Please enter valid account number";', { style: { background: '#333', color: '#fff' } });
            // }
            // if (key === "ifsc_code" && !validateIFSCCode(details?.ifsc_code)) {
            //     err['ifsc_code_err'] = "Please enter valid ifsc code";
            //     toast.error('Please enter valid ifsc code', { style: { background: '#333', color: '#fff' } });
            // }
        }
        return Object.keys(err).length == 0 ? true : false;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validation()) {
            // console.log(details)
            await addDoc(collection(db, 'payouts'), {
                user_id: localStorage.getItem('WEE_USER_ID'),
                user_name: profile.first_name + " " + profile.last_name,
                withdraw_amount: details?.price,
                created_date: new Date().toISOString(),
                updated_data: new Date().toISOString(),
                // account_no: details?.account_no,
                // bank_name: details?.bank_name,
                // ifsc_code: details?.ifsc_code
            }).then(async (docRef) => {
                // console.log(docRef.id);
                await updateDoc(doc(db, 'payouts', docRef.id), {
                    id: docRef.id,
                });

                let earned = profile?.my_earnings;
                earned -= details?.price;
                await updateDoc(doc(db, "user", localStorage.getItem('WEE_USER_ID')), { my_earnings: earned });
                // await updateDoc(doc(db, 'user', localStorage.getItem('WEE_USER_ID')), {
                //     total_payout: Number(profile?.total_payout ?? 0) + Number(price)
                // })
            })
            toast.success('Payout request sent successfully !!', { style: { background: '#333', color: '#fff' } });
            setActiveModal();
        }
    }
    return (
        <Modal
            className="modal fade wt-confirm-modal"
            id="confirmGuestModal"
            show={activeModal}
            onHide={setActiveModal}
            keyboard={false}
            size='md'
        >
            <Modal.Header>
                <h4>Withdraw Amount</h4>
                <span>Balance available :  <strong>{profile?.my_earnings}</strong></span>
            </Modal.Header>
            <Modal.Body>
                <form className='row' onSubmit={handleSubmit}>
                    <div className='col-12'>
                        <label className='form-label'>Withdraw Amount</label>
                        <input class="form-control" type='number' placeholder='Enter Amount' onChange={handleChange} name='price' />
                    </div>
                    {/* <div className='col-md-6'>
                        <label className='form-label'>Bank Name</label>
                        <input class="form-control" type='text' placeholder='Enter Bank Name' onChange={handleChange} name='bank_name' />
                    </div>
                    <div className='col-md-6'>
                        <label className='form-label'>Account No.</label>
                        <input class="form-control" type='number' placeholder='Enter Account No.' onChange={handleChange} name='account_no' />
                    </div>
                    <div className='col-md-6'>
                        <label className='form-label'>IFSC Code</label>
                        <input class="form-control" type='text' placeholder='Enter IFSC Code' onChange={handleChange} name='ifsc_code' />
                    </div> */}
                    <div className='col-md-6 my-2'>
                        <button type='submit' class="btn btn-success">Request Amount</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default WithdrawModal