import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Rating } from 'react-simple-star-rating';
import Select from "react-select";

const FeedbackModal = ({ activeModal, setActiveModal, Feedback, setFeedback, handleSave, selected }) => {
    const optionsArray1 = [
        { label: "Excellent", value: "Excellent" },
        { label: "Good", value: "Good" }
    ];

    // const [rating,setRating] = useState(0);
    // const ratingChanged = (newRating) => {
    //     setRating(newRating);
    // };

    // const handleChange = async () => {
    //     setFeedback({
    //         ...Feedback,
    //         rating:rating,
    //         feedback:''
    //     });
    // }


    return (
        <Modal
            className="modal fade wt-confirm-modal"
            id="confirmGuestModal"
            show={activeModal}
            onHide={setActiveModal}
            // backdrop="static"
            keyboard={false}
            size='md'
        >
            {/* <Modal.Header>
                <h3 className='wt-confirm-pay-modal-title'>Please rate your experience</h3>
                <button type="button" class="btn-close" onClick={setActiveModal}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 1.25C6.072 1.25 1.25 6.073 1.25 12C1.25 17.927 6.072 22.75 12 22.75C17.928 22.75 22.75 17.927 22.75 12C22.75 6.073 17.928 1.25 12 1.25ZM12 21.25C6.899 21.25 2.75 17.101 2.75 12C2.75 6.899 6.899 2.75 12 2.75C17.101 2.75 21.25 6.899 21.25 12C21.25 17.101 17.101 21.25 12 21.25ZM15.53 9.53003L13.06 12L15.53 14.47C15.823 14.763 15.823 15.238 15.53 15.531C15.384 15.677 15.192 15.751 15 15.751C14.808 15.751 14.616 15.678 14.47 15.531L12 13.061L9.53 15.531C9.384 15.677 9.192 15.751 9 15.751C8.808 15.751 8.616 15.678 8.47 15.531C8.177 15.238 8.177 14.763 8.47 14.47L10.94 12L8.47 9.53003C8.177 9.23703 8.177 8.76199 8.47 8.46899C8.763 8.17599 9.23801 8.17599 9.53101 8.46899L12.001 10.939L14.471 8.46899C14.764 8.17599 15.239 8.17599 15.532 8.46899C15.823 8.76199 15.823 9.23803 15.53 9.53003Z"
                            fill="#25314C" />
                    </svg>
                </button>
            </Modal.Header> */}
            <Modal.Body>
                <div class="wt-confirm-pay-modal-content">
                    <div class="wt-confirm-pay-guest-area">
                        <div className='d-flex justify-content-between w-100 align-items-start'>
                            <h5 className='wt-confirm-pay-modal-title'>Please rate your experience</h5>
                            <button type="button" class="btn-close" onClick={setActiveModal}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12 1.25C6.072 1.25 1.25 6.073 1.25 12C1.25 17.927 6.072 22.75 12 22.75C17.928 22.75 22.75 17.927 22.75 12C22.75 6.073 17.928 1.25 12 1.25ZM12 21.25C6.899 21.25 2.75 17.101 2.75 12C2.75 6.899 6.899 2.75 12 2.75C17.101 2.75 21.25 6.899 21.25 12C21.25 17.101 17.101 21.25 12 21.25ZM15.53 9.53003L13.06 12L15.53 14.47C15.823 14.763 15.823 15.238 15.53 15.531C15.384 15.677 15.192 15.751 15 15.751C14.808 15.751 14.616 15.678 14.47 15.531L12 13.061L9.53 15.531C9.384 15.677 9.192 15.751 9 15.751C8.808 15.751 8.616 15.678 8.47 15.531C8.177 15.238 8.177 14.763 8.47 14.47L10.94 12L8.47 9.53003C8.177 9.23703 8.177 8.76199 8.47 8.46899C8.763 8.17599 9.23801 8.17599 9.53101 8.46899L12.001 10.939L14.471 8.46899C14.764 8.17599 15.239 8.17599 15.532 8.46899C15.823 8.76199 15.823 9.23803 15.53 9.53003Z"
                                        fill="#25314C" />
                                </svg>
                                {/* <img src='images/Button-close-X.svg' alt='close' /> */}
                            </button>

                        </div>
                        <p className='text-center'>Your feedback matters</p>
                        <div className='d-flex justify-content-center w-100'>
                            {/* <Select
                            value={selected?.feedback?.about ? selected?.feedback?.about : Feedback?.about}
                            onChange={(val) => setFeedback({ about: val })}
                            options={optionsArray1}
                            styles={{ control: (baseStyles) => ({ ...baseStyles,margin:"0 30px" ,borderRadius: "8px", height: "45px", borderColor: "#D5DAE1",width:'80%',marginBottom:'15px'}) }}
                            isMulti={false}
                            isSearchable={false}
                            isDisabled={selected?.feedback?.rating > 0}
                        /> */}
                            <select className='form-control' style={{ margin: '20px 40px', borderColor: 'black' }} disabled={selected?.feedback?.rating > 0}
                                onChange={(e) => setFeedback({ about: e.target.value })} value={selected?.feedback?.about ? selected?.feedback?.about : Feedback?.about}>
                                <option value="">Select your experience</option>
                                <option value="Excellent">Excellent</option>
                                <option value="Good">Good</option>
                            </select>
                        </div>
                        <div className='d-flex justify-content-center pb-3' style={{ marginTop: '-10px' }}>
                            <Rating
                                // count={5}
                                initialValue={selected?.feedback?.rating > 0 ?
                                    selected?.feedback?.rating : Feedback?.rating}
                                onClick={(val) => setFeedback({ rating: val })}
                                allowFraction={true}
                                // size={50}
                                // emptyIcon={<i className="far fa-star"></i>}
                                // fullIcon={<i className="fa fa-star"></i>}
                                // activeColor="#ffd700"
                                readonly={selected?.feedback?.rating > 0}
                            />
                        </div>
                        <div className='d-flex justify-content-center w-100'>
                            <textarea resize='none' rows={4} placeholder='What was your experience like?' value={selected?.feedback?.text?.trim()?.length > 0 ? selected?.feedback?.text : Feedback?.text} readonly={selected?.feedback?.rating > 0} onChange={(e) => setFeedback({ text: (e.target.value).trim() && e.target.value.length < 150 ? e.target.value : "" })} style={{ padding: "10px", width: '80%', borderRadius: '10px', background: '#F6F6F6' }} />
                        </div>
                    </div>
                </div>
                {selected?.feedback?.rating === 0 && localStorage.getItem('WEE_USER_TYPE') === "CUSTOMER" && <div class="wt-confirm-pay-modal-btn">
                    <button ton type="button" class="btn btn-success" onClick={handleSave}>Submit</button>
                </div>}
            </Modal.Body>
        </Modal>
    )
}

export default FeedbackModal
